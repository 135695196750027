import { Injectable } from '@angular/core';
import {
  getDownloadURL,
  ref,
  Storage,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { from, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private storage: Storage) {}

  /**
   * Uploads a file to Firebase Storage with progress tracking
   * @param file The file to upload
   * @param path The storage path
   * @param progressCallback Optional callback for progress updates (0-100)
   * @returns Observable with the download URL
   */
  uploadFile(
    file: File,
    path: string,
    progressCallback?: (progress: number) => void
  ): Observable<string> {
    const storageRef = ref(this.storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Observable(subscriber => {
      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // Call the progress callback if provided
          if (progressCallback) {
            progressCallback(Math.round(progress));
          }

          console.log('Upload is ' + progress + '% done');
        },
        error => {
          subscriber.error(error);
        },
        () => {
          from(getDownloadURL(uploadTask.snapshot.ref)).subscribe(
            downloadURL => {
              subscriber.next(downloadURL);
              subscriber.complete();
            },
            error => subscriber.error(error)
          );
        }
      );
    });
  }

  /**
   * Uploads an image to Firebase Storage with progress tracking
   * @param file The image file to upload
   * @param folder The folder path
   * @param progressCallback Optional callback for progress updates (0-100)
   * @returns Observable with the download URL
   */
  uploadImage(
    file: File,
    folder: string = '/',
    progressCallback?: (progress: number) => void
  ): Observable<string> {
    const fileName = `${folder}/${uuidv4()}`;
    return this.uploadFile(file, fileName, progressCallback);
  }
}
