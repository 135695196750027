<ul class="p-0">
  <li
    *ngFor="let class of classes"
    class="fc-event flex items-center justify-between rounded-lg my-2 text-white px-2 hover:drop-shadow-lg hover:my-5 hover:scale-105 transition-all duration-300"
    [attr.data-title]="class.title"
    [attr.data-color]="class.color"
    [attr.data-duration]="class.duration"
    [attr.data-event]="class | json"
    [style.backgroundColor]="class.color"
    [style.cursor]="draggable ? 'move' : 'default'">
    <div class="flex justify-start items-center w-full">
      <div>
        <span
          nz-icon
          nzType="more"
          class="text-white text-2xl font-extrabold"
          nzTheme="outline"></span>
      </div>
      <div class="flex flex-col my-2 justify-evenly">
        <span>{{ class.title }} - Duración: {{ class.duration }}</span>
        <span><span class="font-bold">Instructor:</span> {{ class.instructor }}</span>
      </div>
    </div>
    <div class="flex justify-center items-center gap-2">
      <a>
        <i
          (click)="editClass(class)"
          class="cursor-pointer"
          nz-icon
          class="text-lg"
          nzType="edit"></i>
      </a>

      <a>
        <i
          (click)="deleteClass(class)"
          class="cursor-pointer"
          nz-icon
          class="text-lg"
          nzType="delete"></i>
      </a>
    </div>
  </li>
</ul>
