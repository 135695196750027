import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { parse, formatDistanceToNow, format } from 'date-fns';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SpacesService } from 'src/app/@core/services/spaces.service';
import { SchedulerComponent } from '../appointments/components/scheduler/scheduler.component';
import { ScheduleMakerComponent } from '../appointments/components/spaces/components/schedule-maker/schedule-maker.component';
import { SpacesConfigComponent } from '../appointments/components/spaces/components/spaces-config/spaces-config.component';
import { AdsConfigFormComponent } from './components/ads-config-form/ads-config-form.component';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss'],
})
export class AdsComponent {
  allSpaces$ = this.spacesService.getAllSpaces();

  identifySpace(index, item) {
    return item[index];
  }

  constructor(
    private drawer: NzDrawerService,
    private formBuilder: FormBuilder,
    private spacesService: SpacesService,
    private modalService: NzModalService
  ) {}

  handleAds(space) {
    this.drawer.create<AdsConfigFormComponent, { data: any; option: string }>({
      nzTitle: 'Configuración de Publicidad',
      nzContent: AdsConfigFormComponent,
      nzWidth: '100%',
      nzContentParams: {
        data: { data: space, option: null },
      },
    });
  }

  handleClearAds(space) {
    this.spacesService.clearSpaceAds(space.spaceID);
  }
}
