import { Component } from '@angular/core';

interface InscriptionForm {
  cid: number;
  createdAt: { __time__: string };
  discipline: {
    gender: string;
    id: string;
    inscriptionID: string;
    name: string;
  };
  email: string;
  fullName: string;
  id: string;
  inscriptionID: string;
  phoneNumber: {
    countryCode: string;
    dialCode: string;
    e164Number: string;
    internationalNumber: string;
    nationalNumber: string;
    number: string;
  };
  shareNumber: number;
  status: string;
  type: string;
}

@Component({
  selector: 'app-single-inscription-form',
  templateUrl: './single-inscription-form.component.html',
  styleUrls: ['./single-inscription-form.component.css'],
})
export class SingleInscriptionFormComponent {
  //@Input() data: InscriptionForm;

  data = {
    cid: 30604530,
    createdAt: '2025-02-15T14:10:11.496Z',
    discipline: {
      gender: 'M',
      id: '8J6q5QsFLSoGTfoR3BHx',
      inscriptionID: 'nuARWugEK7FZguVsCLuY',
      name: 'tennis',
    },
    inscription: {
      name: 'FEDECIV 2026',
    },
    disciplineID: '8J6q5QsFLSoGTfoR3BHx',
    disciplines: ['8J6q5QsFLSoGTfoR3BHx'],
    email: 'tommysantana5@gmail.com',
    fullName: 'SANTANA MORENA, TOMAS',
    id: 'Y0RyU2NGRWXw6zPT5nBl',
    inscriptionID: 'nuARWugEK7FZguVsCLuY',
    phoneNumber: {
      countryCode: 'VE',
      dialCode: '+58',
      e164Number: '+584246207462',
      internationalNumber: '+58 424-6207462',
      nationalNumber: '0424-6207462',
      number: '424-6207462',
    },
    shareHolderId: 'ektXUYvF6Qgldy4JDFTzPVVy9re2',
    shareNumber: 10,
    status: 'pending',
    type: 'socio',
  };

  get formattedDate() {
    return new Date(this.data?.createdAt).toLocaleDateString('es-VE', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  get disciplineName() {
    return this.data?.discipline.name.toUpperCase();
  }
}
