import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgxMasonryModule } from 'ngx-masonry';
import { AlbumPhotosUploadModule } from '../album-photos-upload/album-photos-upload.module';
import { AlbumContentComponent } from './album-content.component';
import { ImageDetailModalModule } from './image-detail-modal/image-detail-modal.module';
import { TopTenModalModule } from './top-ten-modal/top-ten-modal.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxMasonryModule,
    NzIconModule,
    NzButtonModule,
    NzSpinModule,
    NzToolTipModule,
    NzCardModule,
    NzDropDownModule,
    NzGridModule,
    NzMessageModule,
    NzImageModule,
    NzModalModule,
    ImageDetailModalModule,
    NzSelectModule,
    AlbumPhotosUploadModule,
    TopTenModalModule,
  ],
  declarations: [AlbumContentComponent],
  exports: [AlbumContentComponent],
})
export class AlbumContentModule {}
