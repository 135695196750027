import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {
  Discipline,
  Inscription,
  InscriptionParticipant,
} from '../models/inscription.interface';

@Injectable({
  providedIn: 'root',
})
export class InscriptionsService {
  constructor(
    private firestore: AngularFirestore,
    private notif: NzNotificationService,
    private aff: AngularFireFunctions
  ) {}

  getInscriptions(): Observable<Inscription[]> {
    return this.firestore
      .collection<Inscription>('inscriptions', ref =>
        ref.orderBy('createdAt', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as Inscription;
            const id = a.payload.doc.id;
            return { ...data, id };
          })
        ),
        map(inscriptions =>
          inscriptions.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
        )
      );
  }
  createInscription(inscription: Inscription) {
    return this.aff
      .httpsCallable('createInscriptions')(inscription)
      .pipe(
        map(response => {
          this.notif.success(
            'Inscripción creada',
            'La inscripción se ha creado exitosamente'
          );
          return response;
        }),
        catchError(error => {
          console.error('Error al crear la inscripción:', error);
          let errorMessage = 'Ha ocurrido un error al crear la inscripción';

          if (error.code === 'already-exists') {
            errorMessage = 'Ya existe una inscripción con estos datos';
          } else if (error.code === 'invalid-argument') {
            errorMessage = 'Los datos proporcionados no son válidos';
          }

          this.notif.error('Error', errorMessage);
          throw error;
        })
      );
  }

  updateInscription(id: string, inscription: Inscription) {
    if (!id) {
      this.notif.error('Error', 'El ID de la inscripción es requerido');
      return of(null);
    }

    return this.aff
      .httpsCallable('updateInscriptions')({
        inscriptionID: id,
        updateData: inscription,
      })
      .pipe(
        map(response => {
          this.notif.success(
            'Inscripción actualizada',
            'La inscripción se ha actualizado exitosamente'
          );
          return response;
        }),
        catchError(error => {
          console.error('Error al actualizar la inscripción:', error);
          let errorMessage =
            'Ha ocurrido un error al actualizar la inscripción';

          if (error.code === 'not-found') {
            errorMessage = 'No se encontró la inscripción especificada';
          } else if (error.code === 'invalid-argument') {
            errorMessage = 'Los datos proporcionados no son válidos';
          }

          this.notif.error('Error', errorMessage);
          throw error;
        })
      );
  }

  getDisciplinesByInscription(inscriptionID: string): Observable<Discipline[]> {
    console.log(inscriptionID, 'getDisciplinesByInscription--------------');
    if (inscriptionID) {
      return this.firestore
        .collection(`inscriptions/${inscriptionID}/disciplines`)
        .snapshotChanges()
        .pipe(
          map(actions =>
            actions.map(a => {
              const data = a.payload.doc.data() as Discipline;
              const id = a.payload.doc.id;
              return { ...data, id };
            })
          ),
          map(disciplines =>
            disciplines.sort((a, b) => a.name.localeCompare(b.name))
          ),
          tap(disciplines =>
            console.log(disciplines, 'getDisciplinesByInscription')
          )
        );
    }
  }

  getDiscipline(
    inscriptionID: string,
    disciplineID: string
  ): Observable<Discipline> {
    return this.firestore
      .doc<Discipline>(
        `inscriptions/${inscriptionID}/disciplines/${disciplineID}`
      )
      .valueChanges();
  }

  getParticipantsByDiscipline(
    inscriptionID: string,
    disciplineID: string
  ): Observable<InscriptionParticipant[]> {
    return this.firestore
      .collection(`inscriptions/${inscriptionID}/participants`, ref =>
        ref.where('disciplineID', '==', disciplineID)
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as InscriptionParticipant;
            const id = a.payload.doc.id;
            return { ...data, id };
          })
        )
      );
  }

  getAllParticipants(
    inscriptionID: string
  ): Observable<InscriptionParticipant[]> {
    return this.firestore
      .collection(`inscriptions/${inscriptionID}/participants`)
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as InscriptionParticipant;
            const id = a.payload.doc.id;
            return { ...data, id };
          })
        )
      );
  }

  createDiscipline(discipline: Discipline) {
    return this.aff
      .httpsCallable('createDiscipline')(discipline)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  updateDiscipline(id: string, discipline: Discipline) {
    console.log(id, discipline, 'updateDiscipline');
    if (!id) {
      this.notif.error('Error', 'El ID de la disciplina es requerido');
      return of(null);
    }
    console.log(id, discipline);

    const payload = {
      discipline,
      inscriptionID: discipline.inscriptionID,
      disciplineID: discipline.id,
    };
    console.log(payload, 'payload');
    return this.aff
      .httpsCallable('updateDiscipline')(payload)
      .pipe(
        map(response => {
          this.notif.success(
            'Disciplina actualizada',
            'La disciplina se ha actualizado exitosamente'
          );
          return response;
        }),
        catchError(error => {
          console.error('Error al actualizar la disciplina:', error);
          let errorMessage = 'Ha ocurrido un error al actualizar la disciplina';

          if (error.code === 'not-found') {
            errorMessage = 'No se encontró la disciplina especificada';
          } else if (error.code === 'invalid-argument') {
            errorMessage = 'Los datos proporcionados no son válidos';
          }

          this.notif.error('Error', errorMessage);
          throw error;
        })
      );
  }

  addParticipant(
    participant: InscriptionParticipant,
    config: {
      inscriptionID: string;
      disciplineID: string;
    }
  ) {
    console.log({ participant, config });
    return this.aff
      .httpsCallable('addParticipant')({
        participant,
        config,
      })
      .pipe(
        map(response => response),
        catchError(error => {
          console.error('Error al agregar el participante:', error);

          this.notif.error('Error', error.message);
          return of(null);
        })
      );
  }

  deleteDiscipline(disciplineID: string, inscriptionID: string) {
    return this.aff
      .httpsCallable('deleteDiscipline')({ disciplineID, inscriptionID })
      .pipe(
        map(response => {
          this.notif.success(
            'Disciplina eliminada',
            'La disciplina se ha eliminado exitosamente'
          );
          return response;
        })
      );
  }

  deleteParticipant(
    participantID: string,
    config: {
      inscriptionID: string;
      disciplineID: string;
    }
  ) {
    console.log({ participantID, config });
    return this.aff
      .httpsCallable('deleteParticipant')({ participantID, config })
      .pipe(
        map(response => {
          this.notif.success(
            'Participante eliminado',
            'El participante se ha eliminado exitosamente'
          );
          return response;
        })
      );
  }

  getInscriptionById(inscriptionID: string): Observable<Inscription> {
    return this.firestore
      .doc<Inscription>(`inscriptions/${inscriptionID}`)
      .valueChanges();
  }

  deleteInscription(inscriptionID: string) {
    return this.firestore
      .doc(`inscriptions/${inscriptionID}`)
      .delete()
      .then(() => {
        this.notif.success(
          'Inscripción eliminada',
          'La inscripción se ha eliminado exitosamente'
        );
      });
  }

  disciplineReport(config: { inscriptionID: string; disciplineID: string }) {
    return this.aff
      .httpsCallable('disciplineReport')(config)
      .pipe(map(response => response));
  }

  inscriptionReport(config: { inscriptionID: string }) {
    return this.aff
      .httpsCallable('inscriptionReport')(config)
      .pipe(map(response => response));
  }

  async updateParticipant(inscriptionID: string, participant: any) {
    console.log(inscriptionID, participant, 'updateParticipant');
    return await this.firestore
      .collection('inscriptions')
      .doc(inscriptionID)
      .collection('participants')
      .doc(participant.id)
      .set(participant, { merge: true });
  }

  checkShareHolderInscription({
    inscriptionID,
    shareHolderID,
  }: {
    inscriptionID: string;
    shareHolderID: string;
  }): Observable<any> {
    return this.firestore
      .collection('inscriptions')
      .doc(inscriptionID)
      .collection('participants', ref =>
        ref
          .where('shareHolderID', '==', shareHolderID)
          .where('type', '==', 'socio')
      )
      .valueChanges();
  }
}
