<ng-container
  *ngIf="{ class: class$ | async, attendees: attendees$ | async } as state">
  <div class="p-4">
    <div>
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <img src="/assets/img/cdi-logo.png" width="80" alt="" />
          <div class="flex flex-col">
            <h2 class="m-0">Reporte de Reservas</h2>
            <span class="me-2">Clase: {{ state.class?.title }}</span>
            <span class="me-2">
              Fecha: {{ state.class?.start | date: 'dd/MM/yyyy' }}</span
            >
            <span class="me-2"
              >Hora: {{ state.class?.start | date: 'HH:mma' }}</span
            >
          </div>
        </div>
        Generado el {{ genTime | date: "dd/MM/yyyy 'a las ' hh:mm a" }}
      </div>
      <h4 class="font-semibold text-center">Listado de Asistentes</h4>
    </div>

    <nz-table [nzData]="state?.attendees">
      <thead>
        <tr>
          <th nzWidth="45">No.</th>
          <th nzWidth="45">Nombre</th>

          <th nzWidth="10">Acciones</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let attendee of state.attendees; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ attendee?.user?.fname }}</td>
          <td>
            <button
              nz-button
              nzDanger
              [disabled]="attendee?.notShow"
              (click)="markAbsent(state.class?.id, attendee?.user?.userID)">
              Marcar Ausente
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-container>
