<!-- Album Card -->
<div class="group relative album-card">
  <!-- Album Preview with Stacked Effect -->
  <div class="relative">
    <!-- Stacked Images Effect -->
    <div
      class="absolute -right-2 -bottom-2 w-full h-full bg-gray-100 rounded-lg"></div>
    <div
      class="absolute -right-1 -bottom-1 w-full h-full bg-gray-50 rounded-lg"></div>

    <!-- Main Image Container -->
    <div class="relative bg-gray-200 aspect-square rounded-lg overflow-hidden">
      <img
        [src]="album.coverImage"
        alt="Album cover"
        class="w-full h-full object-contain" />

      <!-- Photo Count Badge -->
      <div
        class="absolute top-2 right-2 bg-black/70 text-white px-2 py-1 rounded-full text-sm">
        {{ album.photos || 0 }} fotos
      </div>

      <!-- Hover Actions -->
      <div class="album-hover-overlay">
        <button
          (click)="onViewAlbum(album)"
          class="action-button view"
          nz-tooltip
          nzTooltipTitle="Ver fotos">
          <span nz-icon nzType="eye" nzTheme="outline" class="text-lg"></span>
        </button>
        <button
          class="action-button edit"
          (click)="onEditAlbum(album)"
          nz-tooltip
          nzTooltipTitle="Editar álbum">
          <span nz-icon nzType="edit" nzTheme="outline" class="text-lg"></span>
        </button>
        <button
          class="action-button delete"
          nz-tooltip
          nzTooltipTitle="Eliminar álbum"
          (click)="onDeleteAlbum(album)">
          <span
            nz-icon
            nzType="delete"
            nzTheme="outline"
            class="text-lg"></span>
        </button>
        <!-- <button
          class="action-button stats"
          nz-tooltip
          nzTooltipTitle="Ver estadísticas">
          <span
            nz-icon
            nzType="bar-chart"
            nzTheme="outline"
            class="text-lg"></span>
        </button> -->
      </div>
    </div>
  </div>

  <!-- Album Title -->
  <div class="mt-3">
    <h3 class="font-normal text-base text-gray-800">{{ album.title }}</h3>
    <p class="text-sm text-gray-500">
      Creado el {{ album.createdAt.seconds * 1000 | date: 'dd MMM yyyy' }}
    </p>
  </div>
</div>
