import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { User } from '../models/user.model';

export interface Album {
  id?: string;
  title: string;
  description: string;
  coverImage: string;
  photos: number;
  allowSubmit: boolean;
  allowVoting: boolean;
  createdAt: any;
  terms: string;
}

export interface Photo {
  id?: string;
  url: string;
  createdAt: any;
  description: string;
  votes: number;
  likes: number;
  approved: boolean;
  category?: string;
  title: string;
  submittedBy?: User;
  link?: string;
}

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  constructor(
    private aff: AngularFireFunctions,
    private afs: AngularFirestore,
    private nzNotifications: NzNotificationService
  ) {}

  getAlbums() {
    return this.afs
      .collection('albums', ref => ref.orderBy('createdAt', 'desc'))
      .valueChanges() as Observable<Album[]>;
  }

  createAlbum(album: Album) {
    const createAlbumRef = this.aff.httpsCallable('createAlbum');
    return createAlbumRef(album).pipe(
      finalize(() => {
        this.nzNotifications.success(
          'Accion exitosa',
          'Álbum creado exitosamente'
        );
      }),
      catchError(error => {
        this.nzNotifications.error('Accion fallida', 'Error al crear el álbum');
        console.error('Error creating album:', error);
        throw error;
      })
    );
  }

  updateAlbum(id: string, album: Album) {
    const updateAlbumRef = this.aff.httpsCallable('updateAlbum');
    return updateAlbumRef({ id, album }).pipe(
      finalize(() => {
        this.nzNotifications.success(
          'Accion exitosa',
          'Álbum actualizado exitosamente'
        );
      }),
      catchError(error => {
        console.error('Error updating album:', error);
        throw error;
      })
    );
  }

  getAlbumPhotos(id: string) {
    console.log('id', id);
    return this.afs
      .collection('albums')
      .doc(id)
      .collection('photos')
      .valueChanges() as Observable<Photo[]>;
  }

  addPhotosToAlbum(albumId: string, photos: Photo[]) {
    const addPhotosToAlbumRef = this.aff.httpsCallable('createPhotos');
    return addPhotosToAlbumRef({ albumId, photos }).pipe(
      finalize(() => {
        this.nzNotifications.success(
          'Accion exitosa',
          'Fotos agregadas al álbum exitosamente'
        );
      }),
      catchError(error => {
        this.nzNotifications.error(
          'Accion fallida',
          'Error al agregar las fotos al álbum'
        );
        console.error('Error adding photos to album:', error);
        throw error;
      })
    );
  }

  deletePhoto(albumId: string, photoId: string) {
    const deletePhotoRef = this.aff.httpsCallable('deletePhoto');
    return deletePhotoRef({ albumId, photoId }).pipe(
      finalize(() => {
        this.nzNotifications.success(
          'Accion exitosa',
          'Foto eliminada exitosamente'
        );
      }),
      catchError(error => {
        this.nzNotifications.error(
          'Accion fallida',
          'Error al eliminar la foto'
        );
        console.error('Error deleting photo:', error);
        throw error;
      })
    );
  }

  deleteAlbum(albumId: string) {
    const deleteAlbumRef = this.aff.httpsCallable('deleteAlbum');
    return deleteAlbumRef({ id: albumId }).pipe(
      finalize(() => {
        this.nzNotifications.success(
          'Accion exitosa',
          'Álbum eliminado exitosamente'
        );
      }),
      catchError(error => {
        this.nzNotifications.error(
          'Accion fallida',
          'Error al eliminar el álbum'
        );
        console.error('Error deleting album:', error);
        throw error;
      })
    );
  }

  updatePhoto(albumId: string, photoId: string, photo: Photo) {
    const updatePhotoRef = this.aff.httpsCallable('updatePhoto');
    return updatePhotoRef({ albumId, photoId, ...photo }).pipe(
      finalize(() => {
        this.nzNotifications.success(
          'Accion exitosa',
          'Foto actualizada exitosamente'
        );
      }),
      catchError(error => {
        this.nzNotifications.error(
          'Accion fallida',
          'Error al actualizar la foto'
        );
        console.error('Error updating photo:', error);
        throw error;
      })
    );
  }
}
