<ng-container
  *ngIf="{
    classes: availableClasses$ | async,
    activeClasses: activeClasses$ | async,
    gymSettings: gymSettings$ | async
  } as state">
  <div nz-row [nzGutter]="16">
    <div nz-col [nzSpan]="6">
      <nz-card
        nzTitle="Clases Disponibles"
        [nzExtra]="gymClassesHeader"
        style="position: sticky; top: 20px">
        <app-gym-classes
          [classes]="state.classes"
          [draggable]="true"
          (classClick)="editClass($event)"></app-gym-classes>
      </nz-card>
    </div>
    <div nz-col [nzSpan]="18">
      <nz-card>
        <full-calendar
          #calendar
          [options]="calendarOptions"
          [events]="state.activeClasses">
          <ng-template #eventContent let-arg>
            <div [style.backgroundColor]="arg.event.backgroundColor">
              <b>{{ arg.event.title }}</b>
              <p class="text-sm m-0">
                <i nz-icon nzType="user" nzTheme="outline"></i>:
                {{ arg.event.extendedProps?.capacity }} /
                {{ arg.event.extendedProps?.attendees?.length }}
              </p>
              <p class="text-sm m-0">
                <i nz-icon nzType="clock-circle" nzTheme="outline"></i>:
                {{ arg.event.extendedProps?.status }}
              </p>
              <p class="text-sm m-0">
                <i nz-icon nzType="user" nzTheme="outline"></i>:
                {{ arg.event.extendedProps?.instructor }}
              </p>
            </div>
          </ng-template>
        </full-calendar>
      </nz-card>
    </div>
  </div>

  <ng-template #gymClassesHeader>
   <div class="flex justify-between gap-x-2">
    <button nz-button nzType="primary" (click)="openCreateClassForm()">
      Crear Clase
    </button>
      <button
        [nzShape]="'circle'"
        nz-button
        nzType="primary"
        (click)="openSettings()">
        <span nz-icon nzType="setting"></span>
      </button>
    </div>
  </ng-template>
</ng-container>
