import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import {
  NzUploadChangeParam,
  NzUploadFile,
  NzUploadXHRArgs,
} from 'ng-zorro-antd/upload';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Inscription } from 'src/app/@core/models/inscription.interface';
import { InscriptionsService } from 'src/app/@core/services/inscriptions.service';
import { UploadService } from 'src/app/@core/services/upload.service';

@Component({
  selector: 'app-inscription-form',
  templateUrl: './inscription-form.component.html',
  styleUrls: ['./inscription-form.component.css'],
})
export class InscriptionFormComponent implements OnInit {
  form: FormGroup;
  imageUrl: string | null = null;
  uploadLoading = false;
  saving$ = new BehaviorSubject<boolean>(false);
  currentDate = new Date();

  constructor(
    private fb: FormBuilder,
    private inscriptionService: InscriptionsService,
    private uploadservice: UploadService,
    private message: NzMessageService,
    private modalRef: NzModalRef,
    @Inject(NZ_MODAL_DATA)
    public data: {
      inscription: any;
      option: string;
    }
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      price: [0, [Validators.required, Validators.min(0)]],
      disciplines: this.fb.array([]),
      capacity: [100, [Validators.required, Validators.min(1)]],
      requireScreenshot: [false],
      publishDate: [new Date(), [Validators.required]],
      deadlineDate: [new Date(), [Validators.required]],
      photoUrl: [''],
    });
  }

  ngOnInit(): void {
    this.addDiscipline();
    if (this.data.option === 'edit') {
      if (this.data.inscription.photoUrl) {
        this.imageUrl = this.data.inscription.photoUrl;
      }
      const inscriptionData = { ...this.data.inscription };

      if (inscriptionData.publishDate) {
        inscriptionData.publishDate = inscriptionData.publishDate.toDate
          ? inscriptionData.publishDate.toDate()
          : new Date(inscriptionData.publishDate);
      }

      if (inscriptionData.deadlineDate) {
        inscriptionData.deadlineDate = inscriptionData.deadlineDate.toDate
          ? inscriptionData.deadlineDate.toDate()
          : new Date(inscriptionData.deadlineDate);
      }

      if (inscriptionData.disciplines) {
        this.disciplines.clear();
        inscriptionData.disciplines.forEach((discipline: string) => {
          this.disciplines.push(this.fb.control(discipline));
        });
      }

      this.form.patchValue(inscriptionData);
    }
  }

  get disciplines(): FormArray {
    return this.form.get('disciplines') as FormArray;
  }

  formatPrice(value: number): string {
    return `$ ${value}`;
  }

  parsePrice(value: string): number {
    return parseFloat(value.replace('$ ', ''));
  }
  addDiscipline(): void {
    this.disciplines.push(this.fb.control(''));
  }

  removeDiscipline(index: number): void {
    this.disciplines.removeAt(index);
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    const isImage = file.type?.startsWith('image/');
    if (!isImage) {
      this.message.error('Solo puedes subir archivos de imagen!');
      return false;
    }
    const isLt2M = (file.size || 0) / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.message.error('La imagen debe ser menor a 2MB!');
      return false;
    }
    return true;
  };

  customUploadReq = (item: NzUploadXHRArgs): Subscription => {
    const file = item.file as unknown as File;
    this.uploadLoading = true;

    return this.uploadservice.uploadImage(file, 'inscriptions').subscribe({
      next: url => {
        this.uploadLoading = false;
        if (item.onSuccess) {
          item.onSuccess(url, item.file, {});
        }
      },
      error: err => {
        this.uploadLoading = false;
        this.message.error('Error al subir la imagen');
        if (item.onError) {
          item.onError(err, item.file);
        }
      },
    });
  };

  handleChange = (info: NzUploadChangeParam): void => {
    if (info.type === 'success') {
      const imageUrl = info.file.response;
      this.updateImagePreview(imageUrl);
    }
  };

  private updateImagePreview(imageUrl: string): void {
    this.imageUrl = imageUrl;
    this.form.patchValue({ photoUrl: imageUrl });
  }

  onSubmit() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }

    this.saving$.next(true);

    const formValue = this.form.value;
    const inscription: Inscription = {
      ...formValue,
      publishDate: formValue.publishDate
        ? new Date(formValue.publishDate)
        : new Date(),
      deadlineDate: formValue.deadlineDate
        ? new Date(formValue.deadlineDate)
        : null,
    };

    if (this.data.option === 'edit') {
      this.inscriptionService
        .updateInscription(this.data.inscription.id, inscription)
        .subscribe({
          next: () => {
            this.modalRef.close(true);
          },
          error: error => {
            console.error('Error updating inscription:', error);
            this.saving$.next(false);
          },
          complete: () => {
            this.saving$.next(false);
          },
        });
    } else {
      this.inscriptionService.createInscription(inscription).subscribe({
        next: () => {
          this.modalRef.close(true);
        },
        error: error => {
          console.error('Error creating inscription:', error);
          this.saving$.next(false);
        },
        complete: () => {
          this.saving$.next(false);
        },
      });
    }
  }

  onCancel(): void {
    this.modalRef.close();
  }
}
