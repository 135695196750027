<ng-container *ngIf="{ isLoading: isLoading$ | async } as state">
  <div class="p-6">
    <form [formGroup]="albumForm" class="space-y-6">
      <!-- Título -->
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700"
          >Título del Álbum</label
        >
        <input
          nz-input
          formControlName="title"
          placeholder="Ej: Evento de Navidad 2024"
          class="w-full" />
        <div
          *ngIf="
            albumForm.get('title')?.touched && albumForm.get('title')?.invalid
          "
          class="text-red-500 text-xs mt-1">
          El título es requerido
        </div>
      </div>

      <!-- Descripción -->
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700"
          >Descripción</label
        >
        <textarea
          nz-input
          formControlName="description"
          placeholder="Describe el contenido del álbum"
          [nzAutosize]="{ minRows: 3, maxRows: 5 }"
          class="w-full"></textarea>
        <div
          *ngIf="
            albumForm.get('description')?.touched &&
            albumForm.get('description')?.invalid
          "
          class="text-red-500 text-xs mt-1">
          La descripción es requerida
        </div>
      </div>

      <!-- Categorías -->
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700"
          >Categorías</label
        >
        <nz-select
          formControlName="categories"
          nzMode="tags"
          nzPlaceHolder="Selecciona o escribe categorías"
          [nzTokenSeparators]="[',']"
          class="w-full"
          (nzOnSearch)="addCategory($event)">
          <nz-option
            *ngFor="let category of suggestedCategories"
            [nzLabel]="category"
            [nzValue]="category">
          </nz-option>
        </nz-select>
        <p class="text-xs text-gray-500">
          Puedes seleccionar categorías existentes o crear nuevas escribiendo y
          presionando Enter. Las categorías nuevas se agregarán automáticamente
          después de una breve pausa.
        </p>
      </div>

      <!-- Imagen de Portada -->
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700"
          >Imagen de Portada</label
        >
        <div
          class="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center hover:border-blue-500 transition-colors cursor-pointer"
          (click)="triggerFileInput()">
          <input
            type="file"
            #fileInput
            (change)="onFileSelected($event)"
            accept="image/*"
            class="hidden"
            formControlName="coverImage" />
          <div *ngIf="!previewImage" class="space-y-2">
            <span nz-icon nzType="upload" class="text-3xl text-gray-400"></span>
            <p class="text-gray-500">Haz clic o arrastra una imagen aquí</p>
            <p class="text-xs text-gray-400">PNG, JPG hasta 5MB</p>
          </div>
          <div *ngIf="previewImage" class="relative">
            <img [src]="previewImage" class="max-h-40 mx-auto rounded-lg" />
            <button
              class="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
              (click)="removeImage($event)">
              <span nz-icon nzType="delete"></span>
            </button>
          </div>
        </div>
        <div
          *ngIf="
            albumForm.get('coverImage')?.touched &&
            albumForm.get('coverImage')?.invalid
          "
          class="text-red-500 text-xs mt-1">
          La imagen de portada es requerida
        </div>
      </div>

      <!-- Opciones Adicionales -->
      <div class="space-y-4">
        <div class="flex items-center justify-between">
          <div>
            <label class="text-sm font-medium text-gray-700"
              >Permitir envío de fotos</label
            >
            <p class="text-xs text-gray-500">
              Los miembros podrán enviar fotos al álbum
            </p>
          </div>
          <nz-switch formControlName="allowSubmit"></nz-switch>
        </div>

        @if (albumForm.get('allowSubmit')?.value) {
          <div class="flex flex-col gap-2">
            <div class="flex items-center justify-between">
              <div>
                <label class="text-sm font-medium text-gray-700"
                  >Requerir términos y condiciones</label
                >
                <p class="text-xs text-gray-500">
                  Los miembros deberán aceptar los términos y condiciones antes
                  de enviar fotos
                </p>
              </div>
              <nz-switch formControlName="terms"></nz-switch>
            </div>
            <div class="space-y-2">
              <label class="block text-sm font-medium text-gray-700"
                >Términos y condiciones</label
              >
              <textarea
                nz-input
                formControlName="terms"
                placeholder="Términos y condiciones"
                [nzAutosize]="{ minRows: 3, maxRows: 5 }"
                class="w-full"></textarea>
              <div
                *ngIf="
                  albumForm.get('terms')?.touched &&
                  albumForm.get('terms')?.invalid
                "
                class="text-red-500 text-xs mt-1">
                Los términos y condiciones son requeridos
              </div>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div>
              <label class="text-sm font-medium text-gray-700"
                >Mostrar propietario</label
              >
              <p class="text-xs text-gray-500">
                Mostrará o no el nombre del propietario de la foto en la parte
                superior de la foto
              </p>
            </div>
            <nz-switch formControlName="displayOwner"></nz-switch>
          </div>
        }


        <div class="flex items-center justify-between">
          <div>
            <label class="text-sm font-medium text-gray-700"
              >Permitir likes</label
            >
            <p class="text-xs text-gray-500">
              Los miembros podrán dar like a las fotos
            </p>
          </div>
          <nz-switch formControlName="allowVoting"></nz-switch>
        </div>
      </div>

      <!-- Botones de Acción -->
      <div class="flex justify-end gap-3 pt-4">
        <button nz-button nzType="default" (click)="onCancel()">
          Cancelar
        </button>
        <button
          nz-button
          nzType="primary"
          [disabled]="albumForm.invalid"
          (click)="onSubmit()">
          {{
            isEditMode
              ? state.isLoading
                ? 'Actualizando...'
                : 'Actualizar'
              : state.isLoading
                ? 'Creando...'
                : 'Crear'
          }}
          Álbum
        </button>
      </div>
    </form>
  </div>
</ng-container>
