<div class="top-ten-container">
  <div class="top-ten-header">
    <h2>Top 10 Fotos Más Votadas</h2>
    <p>Las fotos más populares de este álbum</p>
  </div>

  <div class="top-ten-grid">
    @for (photo of data.photos; track photo.id; let i = $index) {
      <div class="top-photo-card">
        <div class="rank-badge">{{ i + 1 }}</div>
        <div class="photo-container">
          <img
            [src]="photo.url"
            [alt]="photo.title"
            class="top-photo-image"
            (click)="showPhotoDetail(photo)" />

          <div class="photo-info">
            <h3 class="photo-title">{{ photo.title.split('.')[0] }}</h3>
            <div class="photo-stats">
              <div class="votes">
                <span nz-icon nzType="heart" nzTheme="fill"></span>
                <span>{{ photo.likes }}</span>
              </div>
              <div class="date">
                <span nz-icon nzType="calendar" nzTheme="outline"></span>
                <span>{{
                  photo.createdAt?.seconds * 1000 | date: 'shortDate'
                }}</span>
              </div>
            </div>
            <p class="submitter" *ngIf="photo.submittedBy">
              Por {{ photo.submittedBy.fname }}
            </p>
          </div>
        </div>
      </div>
    }
  </div>
</div>
