import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoolTableHandlerComponent } from './components/pool-table-handler/pool-table-handler.component';
import { poolRoutingModule } from './pool-routing.module';
import { PoolTableHandlerModule } from './components/pool-table-handler/pool-table-handler.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NzUploadModule } from 'ng-zorro-antd/upload';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    poolRoutingModule,
    PoolTableHandlerModule,
    NzUploadModule,
    FullCalendarModule,
  ],
})
export class PoolModule {}
