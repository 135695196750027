import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { PoolTableHandlerComponent } from './pool-table-handler.component';
import { ngZorroModules } from 'src/app/ngzorro.index';
import { TableFormComponent } from './table-form/table-form.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [PoolTableHandlerComponent, TableFormComponent],
  imports: [
    CommonModule,
    ...ngZorroModules,
    FormsModule,
    NzFormModule,
    ReactiveFormsModule,
    NzUploadModule,
    NzButtonModule,
    NzInputNumberModule,
    AgGridModule,
  ],
})
export class PoolTableHandlerModule {}
