<ng-container
  *ngIf="{
    albumData: albumData$ | async,
    albumId: albumId$ | async,
    photos: photos$ | async,
    isApprovingPhoto: isApprovingPhoto$ | async,
    topTenPhotos: topTenPhotos$ | async,
    isDeletingPhoto: isDeletingPhoto$ | async
  } as state">
  <div class="album-content-container bg-gray-50 min-h-screen">
    <!-- Header Section with Album Info and Actions -->
    <div class="header-section bg-white shadow-sm rounded-lg mb-6 p-6">
      <div class="album-info mb-4" *ngIf="state.albumData">
        <h1 class="text-2xl font-bold text-gray-800 mb-2">
          {{ state.albumData.title }}
        </h1>
        <p class="text-gray-600" *ngIf="state.albumData.description">
          {{ state.albumData.description }}
        </p>
      </div>

      <!-- Filters and Actions -->
      <div
        class="header-actions flex flex-col md:flex-row justify-between gap-4">
        <div
          class="filters-container flex flex-col sm:flex-row items-start sm:items-end gap-4 flex-grow">
          <!-- Sort Dropdown -->
          <div class="sort-container min-w-[180px]">
            <label class="block text-sm font-medium text-gray-700 mb-1"
              >Ordenar por</label
            >
            <nz-select
              [ngModel]="currentSort$ | async"
              (ngModelChange)="onSortChange($event)"
              class="w-full">
              @for (option of sortOptions; track option.value) {
                <nz-option
                  [nzValue]="option.value"
                  [nzLabel]="option.label"></nz-option>
              }
            </nz-select>
          </div>

          <!-- Category Filter -->
          <div class="category-container min-w-[180px]">
            <label class="block text-sm font-medium text-gray-700 mb-1"
              >Filtrar por categoría</label
            >
            <nz-select
              [ngModel]="selectedCategory$ | async"
              (ngModelChange)="onCategoryChange($event)"
              class="w-full">
              @for (category of categories$ | async; track category) {
                <nz-option
                  [nzValue]="category"
                  [nzLabel]="
                    category === 'all' ? 'Todas las categorías' : category
                  "></nz-option>
              }
            </nz-select>
          </div>
        </div>

        <div class="action-buttons flex flex-wrap gap-2 mt-2 md:mt-0">
          <button
            *ngIf="state.albumData?.allowVoting"
            nz-button
            nzType="default"
            (click)="showTopTen(state.albumData, state.topTenPhotos)"
            class="top-ten-button flex items-center">
            <span nz-icon nzType="trophy" class="mr-1"></span>
            Ver Top 10
          </button>
          <button
            nz-button
            nzType="primary"
            (click)="onAddPhotos(state.albumData)"
            class="flex items-center">
            <span nz-icon nzType="upload" class="mr-1"></span>
            Agregar Fotos
          </button>
          <button nz-button nzType="primary" nzDanger class="flex items-center">
            <span nz-icon nzType="delete" class="mr-1"></span>
            Eliminar Álbum
          </button>
        </div>
      </div>
    </div>

    <!-- Content Area -->
    <div class="content-area px-4 pb-8">
      <!-- Photos Grid -->
      <div class="photos-grid">
        @for (photo of state.photos; track photo.id) {
          <div
            class="photo-card bg-white rounded-lg shadow-sm overflow-hidden transition-all hover:shadow-md">
            <!-- Photo Container -->
            <div
              class="photo-container relative"
              [ngClass]="{ 'photo-card-approved': photo.approved }">
              <!-- Photo Header with Title, Submitter and Date -->
              <div class="photo-header p-3 bg-white border-b border-gray-100">
                <!-- Approval Badge -->
                <div
                  *ngIf="photo.approved"
                  class="approval-badge absolute top-2 right-2 z-10 bg-green-100 text-green-800 text-xs font-medium px-2 py-1 rounded-full flex items-center">
                  <span nz-icon nzType="check-circle" class="mr-1"></span>
                  Aprobada
                </div>

                <div
                  class="photo-meta flex flex-wrap items-center gap-x-4 gap-y-1">
                  <!-- Submitted By Information -->
                  <p
                    class="photo-submitter text-xs text-gray-600 flex items-center"
                    *ngIf="photo.submittedBy">
                    <span
                      nz-icon
                      nzType="user"
                      class="mr-1 text-blue-500"></span>
                    <span
                      >Por <strong>{{ photo.submittedBy.fname }}</strong></span
                    >
                  </p>

                  <!-- Date -->
                  <p class="photo-date text-xs text-gray-500 flex items-center">
                    <span nz-icon nzType="calendar" class="mr-1"></span>
                    {{ relativeTime(photo.createdAt?.seconds * 1000) }}
                  </p>
                </div>
              </div>

              <!-- Image with lightbox -->
              <div
                class="image-wrapper cursor-pointer"
                (click)="showLightbox(photo); $event.stopPropagation()">
                <img
                  [src]="photo?.url"
                  [alt]="photo?.title | titlecase"
                  class="photo-image object-contain w-full aspect-square" />
                <div class="lightbox-indicator">
                  <span nz-icon nzType="fullscreen" nzTheme="outline"></span>
                </div>
              </div>
            </div>

            <!-- Photo Details -->
            <div class="photo-details p-4">
              <!-- Categories Tags -->
              <div
                class="photo-categories mb-3 flex flex-wrap gap-1"
                *ngIf="photo.category && photo.category.length > 0">
                <span
                  *ngFor="let category of photo.category"
                  class="category-tag">
                  adasd
                  {{ category }}
                </span>
              </div>

              <!-- Actions Footer -->
              <h3
                class="photo-title text-lg font-medium text-gray-800 mb-1 truncate">
                {{ photo.title.split('.')[0] }}
              </h3>
              <div
                class="photo-actions-container flex justify-between items-center pt-2 border-t border-gray-100">
                <div class="photo-votes flex items-center">
                  <span
                    nz-icon
                    nzType="heart"
                    [nzTheme]="photo.likes > 0 ? 'fill' : 'outline'"
                    class="text-red-500 mr-1"></span>
                  <span class="votes-count text-sm">{{ photo.likes }}</span>
                </div>

                <!-- Action Buttons -->
                <div class="action-buttons flex gap-2">
                  <button
                    nz-button
                    nzType="default"
                    nzShape="circle"
                    nzSize="small"
                    nz-tooltip
                    nzTooltipTitle="Ver detalles"
                    (click)="showPreview(photo, state.albumData)">
                    <span nz-icon nzType="eye"></span>
                  </button>

                  <button
                    nz-button
                    nzType="default"
                    nzShape="circle"
                    nzSize="small"
                    nz-tooltip
                    nzTooltipTitle="Descargar"
                    (click)="downloadPhoto(photo)">
                    <span nz-icon nzType="download"></span>
                  </button>

                  <!-- Dropdown Menu Trigger -->
                  <a
                    nz-button
                    nzType="default"
                    nzShape="circle"
                    nzSize="small"
                    nz-dropdown
                    [nzDropdownMenu]="menu"
                    nzTrigger="click"
                    nzPlacement="bottomRight">
                    <span nz-icon nzType="more" nzTheme="outline"></span>
                  </a>

                  <!-- Dropdown Menu -->
                  <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu class="py-1">
                      <li
                        nz-menu-item
                        class="approve-action"
                        (click)="approvePhoto(photo, state.albumData)"
                        *ngIf="!photo.approved">
                        @if (state.isApprovingPhoto) {
                          <span nz-icon nzType="loading"></span>
                          <span>Procesando...</span>
                        } @else {
                          <span
                            nz-icon
                            nzType="check-circle"
                            class="text-green-600"></span>
                          <span>Aprobar</span>
                        }
                      </li>
                      <li nz-menu-divider *ngIf="!photo.approved"></li>
                      <li
                        nz-menu-item
                        class="delete-action"
                        (click)="deletePhoto(photo, state.albumData)">
                        <span
                          nz-icon
                          [nzType]="
                            state.isDeletingPhoto ? 'loading' : 'delete'
                          "
                          class="text-red-600"></span>
                        <span>Eliminar</span>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </div>
              </div>
            </div>
          </div>
        }
      </div>

      <!-- Empty State -->
      <div
        class="empty-state bg-white rounded-lg shadow-sm p-8 flex flex-col items-center justify-center text-center"
        *ngIf="state.photos?.length === 0">
        <span
          nz-icon
          nzType="picture"
          class="empty-state-icon text-5xl text-gray-300 mb-4"></span>
        <p class="empty-state-text text-gray-600 mb-4">
          No hay fotos en este álbum
        </p>
        <button
          nz-button
          nzType="primary"
          class="empty-state-button"
          (click)="onAddPhotos(state.albumData)">
          <span nz-icon nzType="upload" class="mr-1"></span>
          Agregar Fotos
        </button>
      </div>
    </div>
  </div>
</ng-container>
