<ng-container>
  <div class="flex justify-between items-center p-3">
    <h1 class="text-4xl font-bold m-0">Listado de Mesas</h1>
    <span class="text-secondary"
      >Aqui podras ver, crear y editar a las Mesas</span
    >
  </div>
  <nz-card class="w-full h-[100px] mb-3 flex justify-end items-center">
    <div nz-row>
      <div nz-col nzSpan="24">
        <button nz-button nzType="primary" (click)="openTableMakerDrawer()">
          Crear Mesas
        </button>
      </div>
    </div>
  </nz-card>

  <div nz-row class="pt-3">
    <div nz-col nzSpan="24">
      <nz-card class="h-full"> </nz-card>
    </div>
  </div>
</ng-container>

<!-- ?? no se. -->
