import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of, switchMap } from 'rxjs';
import { Employee } from '../models/employee.model';
import { EmployeeService } from './employee.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser$: Observable<Employee>;
  accessToken$: Observable<String>;

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private employeeService: EmployeeService,
    private afs: AngularFirestore,
    private notificationService: NzNotificationService
  ) {
    this.currentUser$ = this.auth.authState.pipe(
      switchMap(employee => {
        if (employee) {
          return this.afs
            .doc<Employee>(`employees/${employee.uid}`)
            .valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  login({ email, password }): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.auth
        .signInWithEmailAndPassword(email, password)
        .then(async x => {
          const user = await this.employeeService.getEmployeeByIDL(x.user.uid);
          console.log(user, 'user');
          if (['inactive', 'deleted'].includes(user.status)) {
            reject(false);
            this.auth.signOut();
            this.notificationService.error(
              'Ups!...',
              `tu cuentes esta ${user.status}, no puedes iniciar sesión`,
              { nzPlacement: 'bottomRight' }
            );
          } else {
            this.currentUser$;
            resolve(true);
          }
        })
        .catch(error => {
          console.log(error, 'error');
          reject(error);
        });
    });
  }

  register(email: string, password: string) {
    //cambiar por el post del backend
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  async logOut() {
    await this.auth
      .signOut()
      .then(() => {
        this.currentUser$ = null;
        localStorage.removeItem('token');
      })
      .then(() => {
        this.router.navigate(['/login']).then(() => window.location.reload());
      });
  }

  everyone(employee: Employee): boolean {
    const allowed = ['admin', 'editor', 'subscriber'];
    return this.checkAuthorization(employee, allowed);
  }

  canEdit(employee: Employee): boolean {
    const allowed = ['admin', 'editor'];
    return this.checkAuthorization(employee, allowed);
  }

  onlyAdmin(employee: Employee): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(employee, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(
    employee: Employee,
    allowedRoles: string[]
  ): boolean {
    if (!employee) return false;
    for (const role of allowedRoles) {
      if (employee.authorization[role]) {
        return true;
      }
    }
    return false;
  }

  passRecovery(email) {
    return this.auth.sendPasswordResetEmail(email);
  }
}
