import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs';
import { GymService } from 'src/app/@core/services/gym.service';

@Component({
  selector: 'app-gym-class-atendees',
  templateUrl: './gym-class-atendees.component.html',
  styleUrl: './gym-class-atendees.component.scss',
})
export class GymClassAtendeesComponent {
  genTime = new Date();

  constructor(
    private activatedRoute: ActivatedRoute,
    private gymService: GymService
  ) {}

  class$ = this.activatedRoute.params.pipe(
    switchMap(params => this.gymService.getClass(params.id))
  );

  attendees$ = this.activatedRoute.params.pipe(
    switchMap(params => this.gymService.getClassAttendees(params.id)),
    tap(console.log)
  );

  markAbsent(classId: string, userId: string) {
    return this.gymService.markAbsent(classId, userId);
  }
}
