import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePopUpComponent } from './create-pop-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzCardModule,
    NzButtonModule,
    NzGridModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    NzSwitchModule,
    NzMessageModule,
    NzInputNumberModule,
    NzSpinModule,
  ],
  declarations: [CreatePopUpComponent],
})
export class CreatePopUpModule {}
