<ng-container
  *ngIf="{
    config: config$ | async,
    participants: participants$ | async,
    discipline: getDiscipline$ | async,
    isLoading: isLoading$ | async
  } as state">
  <div>
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="disciplineHeader">
        <ng-template #disciplineHeader>
          <div class="flex items-center justify-between w-full">
            <div class="flex gap-2">
              <div class="flex flex-col me-3 leading-tight">
                <span>
                  {{
                    state.discipline?.isLoading
                      ? 'Cargando...'
                      : (state.discipline?.data?.name | uppercase)
                  }}
                  -
                  <span>
                    @switch (state.discipline?.data?.gender) {
                      @case ('M') {
                        MASCULINO
                      }
                      @case ('F') {
                        FEMENINO
                      }
                      @case ('MF') {
                        MASCULINO/FEMENINO
                      }
                      @default {
                        Cargando...
                      }
                    }
                  </span>
                  ({{
                    state.discipline?.isLoading
                      ? 'Cargando...'
                      : state.participants?.length
                  }})
                </span>
                <span class="text-xs text-gray-500">
                  {{ state.discipline?.data?.coach }}
                </span>
              </div>
            </div>

            <div
              class="flex items-center gap-2"
              (click)="$event.stopPropagation()">
              <button
                nz-button
                nzType="default"
                nzSize="small"
                (click)="openManualInscription(state.config)"
                nz-tooltip
                nzTooltipTitle="Inscribir participante">
                <span nz-icon nzType="plus"></span>
              </button>
              <nz-divider [nzType]="'vertical'"></nz-divider>
              <!-- <button
                nz-button
                nzType="default"
                nzSize="small"
                (click)="generateCertificates('')"
                nz-tooltip
                nzTooltipTitle="Generar certificados">
                <span nz-icon nzType="file-text"></span>
              </button> -->
              <button
                nz-button
                nzType="default"
                nzSize="small"
                [disabled]="state.participants?.length === 0"
                (click)="generateReport(state.config)"
                nz-tooltip
                [nzTooltipTitle]="
                  state.isLoading ? 'Generando reporte...' : 'Generar reporte'
                ">
                <span
                  nz-icon
                  [nzType]="state.isLoading ? 'loading' : 'file-text'"></span>
              </button>
              <nz-divider [nzType]="'vertical'"></nz-divider>
              <button
                nz-button
                nzType="default"
                nzSize="small"
                (click)="openDisciplineForm(state.discipline.data)"
                nz-tooltip
                nzTooltipTitle="Editar disciplina">
                <span nz-icon nzType="edit"></span>
              </button>
              <nz-divider [nzType]="'vertical'"></nz-divider>
              <button
                nz-button
                nzType="default"
                nzSize="small"
                (click)="deleteDiscipline(state.config)"
                nz-tooltip
                nzDanger
                nzTooltipTitle="Eliminar disciplina">
                <span nz-icon nzType="delete"></span>
              </button>
            </div>
          </div>
        </ng-template>

        <!-- Lista de participantes por disciplina -->
        <nz-table
          #basicTable
          [nzData]="state.participants"
          [nzPageSize]="20"
          [nzShowPagination]="true">
          <thead>
            <tr>
              <th
                *ngFor="let column of listOfColumns"
                [nzSortOrder]="column.sortOrder"
                [nzSortFn]="column.sortFn"
                [nzSortDirections]="column.sortDirections"
                [nzFilterMultiple]="column.filterMultiple"
                [nzFilters]="column.listOfFilter"
                [nzFilterFn]="column.filterFn"
                [nzCustomFilter]="
                  column.name === 'Nombre' || column.name === 'Email'
                ">
                {{ column.name }}
                <ng-container
                  *ngIf="column.name === 'Nombre' || column.name === 'Email'">
                  <nz-filter-trigger
                    [(nzVisible)]="column.filterVisible"
                    [nzActive]="column.searchText?.length > 0"
                    [nzDropdownMenu]="filterMenu"
                    (nzVisibleChange)="setActiveColumn(column)">
                    <span nz-icon nzType="search"></span>
                  </nz-filter-trigger>
                </ng-container>
              </th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let participant of basicTable.data">
              <td>
                <div class="flex gap-2">
                  @if (participant.fromApp) {
                    <nz-tag [nzColor]="'blue'">
                      <span nz-icon nzType="mobile"></span>
                    </nz-tag>
                  }
                  @switch (participant.status) {
                    @case ('pending') {
                      <nz-tag [nzColor]="'blue'">
                        <span nz-icon nzType="clock-circle"></span>
                      </nz-tag>
                    }
                    @case ('approved') {
                      <nz-tag [nzColor]="'green'">
                        <span nz-icon nzType="check"></span>
                      </nz-tag>
                    }
                    @case ('rejected') {
                      <nz-tag [nzColor]="'red'">
                        <span nz-icon nzType="close"></span>
                      </nz-tag>
                    }
                    @default {
                      <nz-tag [nzColor]="'default'">
                        <span nz-icon nzType="clock-circle"></span>
                      </nz-tag>
                    }
                  }
                </div>
              </td>
              <td
                class="name-cell"
                nz-tooltip
                [nzTooltipTitle]="participant.fullName">
                {{ participant.fullName }}
              </td>
              <td>
                <div class="flex flex-col justify-start leading-tight gap-2">
                  <nz-tag
                    class="w-fit"
                    [nzColor]="
                      participant.type === 'socio' ? 'blue' : 'orange'
                    ">
                    {{ participant.type | uppercase }}
                  </nz-tag>
                  {{ participant.shareNumber }}
                </div>
              </td>
              <td>{{ participant.age }}</td>
              <td
                class="email-cell"
                nz-tooltip
                [nzTooltipTitle]="participant.email">
                {{ participant.email }}
              </td>
              <td>{{ participant.phoneNumber?.nationalNumber || '-' }}</td>
              <td>
                <div class="flex gap-2">
                  <button
                    nz-button
                    nzShape="circle"
                    (click)="
                      editParticipantInscription(participant, state.config)
                    ">
                    <span nz-icon nzType="edit"></span>
                  </button>
                  <button
                    nz-button
                    nzType="link"
                    nzShape="circle"
                    nzDanger
                    nz-popconfirm
                    nzPopconfirmTitle="¿Está seguro de eliminar este participante?"
                    (nzOnConfirm)="
                      removeParticipant(participant, state.config)
                    ">
                    <span nz-icon nzType="delete"></span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </nz-table>
      </nz-collapse-panel>
    </nz-collapse>
  </div>

  <!-- Menús de filtro -->
  <nz-dropdown-menu #filterMenu="nzDropdownMenu">
    <div class="ant-table-filter-dropdown">
      <div class="search-box">
        <input
          type="text"
          nz-input
          placeholder="Buscar..."
          [(ngModel)]="searchValue"
          (input)="search($event, activeColumn)" />
        <button
          nz-button
          nzSize="small"
          nzType="primary"
          (click)="search($event, activeColumn)"
          class="search-button">
          Buscar
        </button>
        <button nz-button nzSize="small" (click)="reset(activeColumn)">
          Reset
        </button>
      </div>
    </div>
  </nz-dropdown-menu>
</ng-container>
