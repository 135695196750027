<form nz-form [formGroup]="form" nzLayout="vertical">
    <nz-form-item>
        <nz-form-label>
            <span>Estado del Gym</span>
        </nz-form-label>
        <nz-form-control>
            <nz-switch formControlName="isActive"></nz-switch>
        </nz-form-control>

    </nz-form-item>
    <div nz-row [nzGutter]="10">
        <div nz-col nzSpan="12">
            <nz-form-item>
                <nz-form-label>
                    <span>Plazo para cancelar: (en horas)</span>
                </nz-form-label>
                <nz-form-control>
                    <nz-input-number [nzMin]="0" formControlName="cancelDeadline"></nz-input-number>
                </nz-form-control>

            </nz-form-item>
        </div>
        <div nz-col nzSpan="12">

            <nz-form-item>
                <nz-form-label>
                    <span>Tiempo de penalización (en días)</span>
                </nz-form-label>
                <nz-form-control>
                    <nz-input-number [nzMin]="0" formControlName="penalizationDays"></nz-input-number>
                </nz-form-control>

            </nz-form-item>
        </div>
    </div>

    <div formGroupName="config">
        <div formGroupName="businessHours">
            <nz-form-item class="mb-2">
                <nz-form-label [nzSpan]="24">Dias disponibles:</nz-form-label>
                <nz-form-control>
                    <nz-select nzShowSearch nzAllowClear nzMode="multiple"
                        nzPlaceHolder="Seleccione los Dias permitidos" formControlName="daysOfWeek">
                        <nz-option *ngFor="let day of daysOfWeek" [nzLabel]="day.label" [nzValue]="day.key"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <div nz-row [nzGutter]="10">
                <div nz-col nzSpan="12">
                    <nz-form-item class="mb-2">
                        <nz-form-label [nzSpan]="24">Hora de inicio:</nz-form-label>
                        <nz-form-control>
                            <input nz-input #hourInput type="time" formControlName="startTime" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col nzSpan="12">
                    <nz-form-item class="mb-2">
                        <nz-form-label [nzSpan]="24">Hora de cierre:</nz-form-label>
                        <nz-form-control>
                            <input #hourInput2 nz-input type="time" formControlName="endTime" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>
    </div>
    <button nz-button nzType="primary" (click)="saveSettings()">Guardar</button>
</form>