import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
  standalone: true,
})
export class TruncatePipe implements PipeTransform {
  /**
   * Truncates a string to the specified length and adds ellipsis
   * @param value The string to truncate
   * @param limit The maximum length (default: 25)
   * @param ellipsis The ellipsis string (default: '...')
   * @returns The truncated string
   */
  transform(value: string, limit = 25, ellipsis = '...'): string {
    if (!value) {
      return '';
    }

    if (value.length <= limit) {
      return value;
    }

    return value.slice(0, limit) + ellipsis;
  }
}
