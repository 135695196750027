import {
  Component,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  NgZone,
} from '@angular/core';

import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Draggable } from '@fullcalendar/interaction';
import { GymService } from 'src/app/@core/services/gym.service';
import { ClassFormComponent } from '../class-form/class-form.component';

@Component({
  selector: 'app-gym-classes',
  templateUrl: './gym-classes.component.html',
  styleUrls: ['./gym-classes.component.scss'],
})
export class GymClassesComponent implements AfterViewInit, OnChanges {
  @Input() classes: any[];
  @Input() draggable: boolean = false;

  constructor(
    private ngZone: NgZone,
    private gymService: GymService,
    private drawerService: NzDrawerService,
    private modalService: NzModalService
  ) {}

  ngAfterViewInit() {
    if (this.draggable) {
      this.makeClassesDraggable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['classes'] && !changes['classes'].firstChange) {
      // Asegúrate de que el DOM se actualice antes de hacer los elementos arrastrables
      setTimeout(() => {
        this.makeClassesDraggable();
      }, 0);
    }
  }

  editClass(classData: any) {
    return this.drawerService.create<
      ClassFormComponent,
      { classData: any; from: string }
    >({
      nzContent: ClassFormComponent,
      nzContentParams: { classData, from: 'gym-classes' },
    });
  }

  async deleteClass(classData: any) {
    return this.modalService.confirm({
      nzTitle: '¿Estás seguro de querer eliminar esta clase?',
      nzContent: 'Esta acción no se puede deshacer',
      nzOnOk: () => {
        return this.gymService.deleteAvailableClass(classData.id);
      },
    });
  }

  makeClassesDraggable() {
    this.ngZone.runOutsideAngular(() => {
      const draggableElems = document.querySelectorAll('.fc-event');
      draggableElems.forEach(elem => {
        if (!(elem as any).fcDraggable) {
          new Draggable(elem as HTMLElement, {
            eventData: function (eventEl) {
              const title = eventEl.getAttribute('data-title');
              const color = eventEl.getAttribute('data-color');
              const duration = eventEl.getAttribute('data-duration');
              const eventData = JSON.parse(
                eventEl.getAttribute('data-event') || '{}'
              );
              return {
                extendedProps: eventData,
                title: title,
                color: color,
                duration: duration,
              };
            },
          });
          (elem as any).fcDraggable = true;
        }
      });
    });
  }
}
