<ng-container *ngIf="{ resources: resources$ | async } as state">
  <div *ngIf="config">
    <full-calendar [options]="config">
      <ng-template let-args>
        <div class="w-full">
          <p>{{ args | json }}</p>
        </div>
      </ng-template>
    </full-calendar>
  </div>
</ng-container>
