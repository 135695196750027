import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { GymComponent } from './gym.component';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { FullCalendarModule } from '@fullcalendar/angular';
import { GymRoutingModule } from './gym-routing.module';
import { GymClassesComponent } from './gym-classes/gym-classes.component';
import { ClassFormComponent } from './class-form/class-form.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { GymSettingsComponent } from './gym-settings/gym-settings.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

@NgModule({
  declarations: [GymComponent, GymClassesComponent, ClassFormComponent, GymSettingsComponent],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    NzSwitchModule,
    ReactiveFormsModule,
    NzSelectModule,
    GymRoutingModule,
    NzInputNumberModule ,
    NzListModule,
    NzCardModule,
    NzButtonModule,
    NzGridModule,
    NzRadioModule,
    NzDrawerModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    FullCalendarModule,
    NgxMaskDirective,
    NzFormModule,
    NzIconModule,
    NgxMaskPipe,
  ],
  providers: [],
})
export class GymModule {}
