import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { ShareHolderPickerModule } from 'src/app/@core/components/share-holder-picker/share-holder-picker.module';
import { ImageDetailModalComponent } from './image-detail-modal.component';

@NgModule({
  declarations: [ImageDetailModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NzButtonModule,
    NzCheckboxModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    ShareHolderPickerModule,
  ],
  exports: [ImageDetailModalComponent],
})
export class ImageDetailModalModule {}
