import { Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { combineLatest } from 'rxjs';
import { PoolService } from 'src/app/@core/services/pool.service';
import { TableFormComponent } from './components/pool-table-handler/table-form/table-form.component';

@Component({
  selector: 'app-pool',
  templateUrl: './pool.component.html',
  styleUrl: './pool.component.scss',
})
export class PoolComponent implements OnInit {
  resources$ = this.poolService.getPoolResources();
  poolConfig$ = this.poolService.getPoolConfig();
  reservations$ = this.poolService.getPoolReservations();
  config: CalendarOptions;
  constructor(
    private poolService: PoolService,
    private drawerService: NzDrawerService
  ) {}

  ngOnInit(): void {
    combineLatest([
      this.resources$,
      this.poolConfig$,
      this.reservations$,
    ]).subscribe(([resources, poolConfig, reservations]) => {
      console.log(reservations);
      return (this.config = {
        initialView: 'resourceTimelineDay',
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        plugins: [resourceTimelinePlugin, interactionPlugin],
        allDaySlot: false,
        eventMinHeight: 100,
        locale: 'es-VE',
        slotMinTime: poolConfig.startDayAt,
        slotMaxTime: poolConfig.endDayAt,
        slotMinWidth: 100,
        slotDuration: poolConfig.slotDuration,
        slotLabelFormat: [
          {
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: true,
            meridiem: 'short',
          },
        ],
        resourceAreaHeaderContent: 'Mesas',

        slotLabelContent: arg => {
          const start = arg.date.toLocaleTimeString('es-VE', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          });
          const end = new Date(
            arg.date.getTime() + 4 * 60 * 60 * 1000
          ).toLocaleTimeString('es-VE', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          });
          return `de ${start} a ${end}`;
        },
        customButtons: {
          createTable: {
            text: 'Crear Mesa',
            click: (args): void => {
              this.drawerService.create<TableFormComponent, { data: any }>({
                nzTitle: 'Crear mesa',
                nzContent: TableFormComponent,
                nzContentParams: {
                  data: { option: 'create' },
                },
              });
            },
          },
        },
        resourceLabelDidMount: arg => {
          let resourceLabel = arg.el;
          let resourceId = arg.resource.id;
          let resourceTitle = arg.resource.title;
          resourceLabel.classList.add('pointer');
          // Add click event listener to resource label
          resourceLabel.addEventListener('click', () => {
            this.drawerService.create<TableFormComponent, { data: any }>({
              nzTitle: `Editar ${resourceTitle}`,
              nzContent: TableFormComponent,
              nzContentParams: {
                data: { table: arg.resource },
              },
            });
            // You can replace this alert with any action you want to perform when a resource label is clicked
          });
        },
        headerToolbar: {
          right: 'createTable today prev,next',
        },
        resources: resources,
        events: reservations,
      });
    });
  }
}
