import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './auth/home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { MembersComponent } from './pages/members/members.component';
import { ShareListComponent } from './pages/shares/share-list/share-list.component';
import {
  AngularFireAuthGuard,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { EmployeesComponent } from './pages/employees/employees.component';
import { AdminGuard } from './auth/admin.guard';
import { AuthGuard } from './auth/auth.guard';
import { ReceptionComponent } from './pages/reception/reception.component';
import { AppointmentsComponent } from './pages/appointments/appointments.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { EventsComponent } from './pages/events/events.component';
import { SalonHandlerComponent } from './pages/events/components/salonHandler/salon-handler.component';
import { EventHandlerComponent } from './pages/events/components/eventHandler/event-handler.component';
import { PdfcontainerComponent } from './pdf/pdfcontainer.component';
import { PdfcontainerRoutingModule } from './pdf/pdfcontainer-routing.module';
import { AdsComponent } from './pages/ads/ads.component';
import { SchedulerComponent } from './pages/appointments/components/scheduler/scheduler.component';
import { PopUpComponent } from './pages/pop-up/pop-up.component';
import { GymComponent } from './pages/gym/gym.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['/login']);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Inicio',
      AuthGuardPipe: redirectUnauthorizedToLogin,
    },
  },

  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
  },
  {
    path: 'pdf',
    component: PdfcontainerComponent,
    data: { title: 'asdasd' },
    loadChildren: () =>
      import('./pdf/pdfcontainer.module').then(m => m.PdfcontainerModule),
  },

  {
    path: 'shares',
    component: ShareListComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Acciones',
    },
    loadChildren: () =>
      import('./pages/shares/share-list/share-list.module').then(
        m => m.ShareListModule
      ),
  },

  {
    path: 'members',
    component: MembersComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Miembros',
    },
    loadChildren: () =>
      import('./pages/members/members.module').then(m => m.MembersModule),
  },
  {
    path: 'gym',
    component: GymComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Gimnasio',
    },
    loadChildren: () =>
      import('./pages/gym/gym-routing.module').then(m => m.GymRoutingModule),
  },
  {
    path: 'employees',
    component: EmployeesComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Empleados',
    },
    loadChildren: () =>
      import('./pages/members/members.module').then(m => m.MembersModule),
  },

  {
    path: 'reception',
    component: ReceptionComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Recepción',
    },
    loadChildren: () =>
      import('./pages/reception/reception.module').then(m => m.ReceptionModule),
  },
  {
    path: 'appointments',
    component: AppointmentsComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Agenda',
    },
    loadChildren: () =>
      import('./pages/appointments/appointments.module').then(
        m => m.AppointmentsModule
      ),
  },
  {
    path: 'appointments/scheduler/:id',
    component: SchedulerComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Agenda',
    },
  },

  {
    path: 'ads',
    component: AdsComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Publicidad',
    },
    loadChildren: () => import('./pages/ads/ads.module').then(m => m.AdsModule),
  },
  {
    path: 'pool',
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Mesas',
    },
    loadChildren: () =>
      import('./pages/pool/pool.module').then(m => m.PoolModule),
  },
  {
    path: 'popup',
    component: PopUpComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Pop-Up',
    },
    loadChildren: () =>
      import('./pages/pop-up/pop-up.module').then(m => m.PopUpModule),
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Configuración',
    },
    loadChildren: () =>
      import('./pages/settings/settings.module').then(m => m.SettingsModule),
  },
  {
    path: 'events',
    component: EventsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Eventos',
    },
    loadChildren: () =>
      import('./pages/events/events.module').then(m => m.EventsModule),
  },
  {
    path: 'events/handle-salons',
    component: SalonHandlerComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Espacios',
    },
  },

  {
    path: 'events/handle-events',
    component: EventHandlerComponent,
    canActivate: [AuthGuard],
    data: {
      AuthGuardPipe: redirectUnauthorizedToLogin,
      title: 'Eventos',
    },
  },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [
    PdfcontainerRoutingModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
