import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Attendance } from '../models/attendance.model';

import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
  addHours,
  startOfToday,
  startOfYesterday
} from 'date-fns';
import {
  combineLatest,
  map,
  switchMap,
  take
} from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class AttendanceService {
  constructor(
    private settinsService: SettingsService,
    private afs: AngularFirestore,
    private angularFirefunctions: AngularFireFunctions
  ) {}

  getTodayAttendance() {
    return combineLatest(
      this.settinsService.getQueryingAttendancePeriod()
    ).pipe(
      switchMap((period: any) => {
        return this.afs
          .collection<Attendance>('attendance', ref =>
            ref
              .where('createdAt', '>=', period[0].start)
              .where('createdAt', '<=', period[0].end)
          )
          .valueChanges()
          .pipe(
            map(attendances =>
              attendances.sort(
                (a, b) =>
                  b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
              )
            )
          );
      })
    );
  }

  getYesterdayAttendance() {
    return this.afs
      .collection<Attendance>('attendance', ref =>
        ref
          .where('createdAt', '>=', addHours(startOfYesterday(), 5))
          .where('createdAt', '<=', addHours(startOfToday(), 5))
      )
      .valueChanges()
      .pipe(
        map(attendances =>
          attendances.sort(
            (a, b) => b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
          )
        )
      );
  }

  getAttendanceByID(id) {
    return this.afs.collection('attendance').doc(id).valueChanges();
  }

  getTodayAttendanceByDateRange(initialDate: any, finalDate: any) {
    return this.afs
      .collection<Attendance>('attendance', ref =>
        ref
          .where('createdAt', '>=', initialDate)
          .where('createdAt', '<=', finalDate)
      )
      .valueChanges()
      .pipe(
        take(1),
        map(attendances =>
          attendances.sort(
            (a, b) => b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
          )
        )
      );
  }

  createAttendance(userID, manual) {
    return this.angularFirefunctions
      .httpsCallable('takeAttendance')({ userID, manual }).pipe(take(1));
  }

  attendanceByUserID(userID: string) {
    return this.angularFirefunctions
      .httpsCallable('manualAttendance')({ userID })
      .toPromise();
  }

  takeOtherAttendace(data) {
    return this.angularFirefunctions
      .httpsCallable('otherAuthorizedsAttendance')(data)
      .toPromise();
  }
  takeCustomShareAttendance(data) {
    return this.angularFirefunctions
      .httpsCallable('customShareAttendace')(data)
      .toPromise();
  }

  deteleAttendace(attendanceID) {
    return this.afs.collection('attendance').doc(attendanceID).delete();
  }

  updateAttendanceWithGym(attendance, value) {
    return this.afs
      .collection('attendance')
      .doc(attendance.attendanceID)
      .update({
        gym: value,
      });
  }
}
