import { Component, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { Album, GalleryService } from 'src/app/@core/services/gallery.service';
import { AlbumFormComponent } from './components/album-form/album-form.component';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  constructor(
    private modal: NzModalService,
    private galleryService: GalleryService,
    private nzDrawerService: NzDrawerService
  ) {}

  albums$: Observable<Album[]> = this.galleryService.getAlbums();

  ngOnInit() {}

  onCreateAlbum(): void {
    this.modal.create({
      nzTitle: 'Crear Álbum',
      nzContent: AlbumFormComponent,
      nzData: {
        album: null,
        isEditMode: false,
      },
      nzFooter: null,
      nzWidth: '600px',
    });
  }
}
