import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import {
  Discipline,
  Inscription,
  InscriptionParticipant,
} from 'src/app/@core/models/inscription.interface';
import { InscriptionsService } from 'src/app/@core/services/inscriptions.service';
import { DisciplineFormComponent } from '../discipline-form/discipline-form.component';

@Component({
  selector: 'app-manage-inscriptions',
  templateUrl: './manage-inscriptions.component.html',
  styleUrls: ['./manage-inscriptions.component.scss'],
})
export class ManageInscriptionsComponent implements OnInit {
  @Input() set inscription(inscriptionData: Inscription) {
    this.inscriptionInput$.next(inscriptionData);
  }

  inGeneratingReport$ = new BehaviorSubject<boolean>(false);

  inscriptionInput$ = new BehaviorSubject<Inscription>(null);
  incriptionData$ = this.inscriptionInput$.pipe(
    switchMap(inscription => {
      return this.inscriptionService.getInscriptionById(inscription.id);
    })
  );

  allParticipants$ = this.incriptionData$.pipe(
    switchMap(inscription =>
      this.inscriptionService.getAllParticipants(inscription.id)
    )
  );

  participants: InscriptionParticipant[] = [];
  loading = false;
  saving = false;
  searchValue = '';
  visible = false;

  disciplines$ = this.incriptionData$.pipe(
    tap(inscription => console.log(inscription, 'inscription')),
    switchMap(inscription =>
      this.inscriptionService.getDisciplinesByInscription(inscription.id)
    )
  );

  constructor(
    private fb: FormBuilder,
    private inscriptionService: InscriptionsService,
    private message: NzMessageService,
    private modal: NzModalService
  ) {
    console.log(this.inscription);
  }

  ngOnInit() {}

  openDisciplineForm(inscriptionID: string, discipline?: Discipline) {
    const modal = this.modal.create({
      nzContent: DisciplineFormComponent,
      nzFooter: null,
      nzTitle: 'Crear Disciplina',
      nzData: {
        discipline,
        inscriptionID,
        option: discipline ? 'edit' : 'create',
      },
    });
  }

  reset(): void {
    this.searchValue = '';
    this.search();
  }
  generateReport(inscriptionID: string) {
    this.inGeneratingReport$.next(true);
    return this.inscriptionService
      .inscriptionReport({ inscriptionID })
      .subscribe({
        next: response => {
          const base64 = response.base64;
          // Convertir base64 a array de bytes
          const binaryString = window.atob(base64);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          // Crear y descargar el blob
          const blob = new Blob([bytes], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'inscription_report.xlsx';
          link.click();
          window.URL.revokeObjectURL(url);
          this.inGeneratingReport$.next(false);
        },
        error: () => {
          this.message.error('Error al generar el reporte');
          this.inGeneratingReport$.next(false);
        },
      });
  }

  search(): void {
    this.visible = false;
    // Implementar búsqueda si es necesario
  }
}
