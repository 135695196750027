import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzUploadFile, NzUploadChangeParam, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Observable, Observer, Subscription } from 'rxjs';
import { UploadService } from 'src/app/@core/services/upload.service';
import { SpacesService } from 'src/app/@core/services/spaces.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';



@Component({
  selector: 'app-ads-config-form',
  templateUrl: './ads-config-form.component.html',
  styleUrls: ['./ads-config-form.component.scss']
})
export class AdsConfigFormComponent implements OnInit {
  adsForm: FormGroup;
  leftImagePreview: string | null = null;
  rightImagePreview: string | null = null;
  centerImagePreview: string | null = null;
  uploading = false;

  spaceId:string;
  @Input() set data(value: any) {
    if (value) {
      console.log(value);
      this.spaceId = value.data.spaceID;
      this.initializeForm(value);
    }
  }

  @Output() formSubmit = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private spacesService: SpacesService,
    private uploadService: UploadService,
    private nzDrawerRef: NzDrawerRef
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.adsForm.get('layoutType')?.valueChanges.subscribe((layout: 'A' | 'B') => {
      this.resetFormSections(layout);
    });
  }

  private createForm(): void {
    this.adsForm = this.fb.group({
      layoutType: ['', Validators.required],
      leftSection: this.fb.group({
        imageUrl: [''],
        externalLink: [''],
        companyName: [''],
        shareNumber:[0],
        expirationDate: [null]
      }),
      rightSection: this.fb.group({
        imageUrl: [''],
        externalLink: [''],
        companyName: [''],
        shareNumber:[0],
        expirationDate: [null]
      }),
      centerSection: this.fb.group({
        imageUrl: ['', Validators.required],
        externalLink: [''],
        companyName: [''],
        shareNumber:[0],
        expirationDate: [null]
      })
    });
  }

  private initializeForm(data: any): void {
    // Establecer el layout
    this.adsForm.patchValue({
      layoutType: data.data.adsLayout
    });

    if (data.data.adsLayout === 'A' && Array.isArray(data.data.ads)) {
      // Layout A - Dos secciones (izquierda y derecha)
      const [leftAd, rightAd] = data.data.ads;

      if (leftAd) {
        this.leftImagePreview = leftAd.imgUrl;
        this.adsForm.get('leftSection')?.patchValue({
          imageUrl: leftAd.imgUrl,
          externalLink: leftAd.externalLink,
          companyName: leftAd.companyName,
          shareNumber: leftAd.shareNumber,
          expirationDate: leftAd.expirationDate ? new Date(leftAd.expirationDate.seconds * 1000) : null
        });
      }

      if (rightAd) {
        this.rightImagePreview = rightAd.imgUrl;
        this.adsForm.get('rightSection')?.patchValue({
          imageUrl: rightAd.imgUrl,
          externalLink: rightAd.externalLink,
          companyName: rightAd.companyName,
          shareNumber: rightAd.shareNumber,
          expirationDate: rightAd.expirationDate ? new Date(rightAd.expirationDate.seconds * 1000) : null
        });
      }
    } else if (data.data.adsLayout === 'B' && data.data.ads?.[0]) {
      // Layout B - Una sección central
      const centerAd = data.data.ads[0];
      this.centerImagePreview = centerAd.imgUrl;
      this.adsForm.get('centerSection')?.patchValue({
        imageUrl: centerAd.imgUrl,
        externalLink: centerAd.externalLink,
        companyName: centerAd.companyName,
        shareNumber: centerAd.shareNumber,
        expirationDate: centerAd.expirationDate ? new Date(centerAd.expirationDate.seconds * 1000) : null
      });
    }

    // Resetear las secciones según el layout
    this.resetFormSections(data.data.adsLayout);
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    const isImage = file.type?.startsWith('image/');
    if (!isImage) {
      this.message.error('Solo puedes subir archivos de imagen!');
      return false;
    }
    const isLt2M = (file.size || 0) / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.message.error('La imagen debe ser menor a 2MB!');
      return false;
    }
    return true;
  };

  customUploadReq = (item: NzUploadXHRArgs): Subscription => {
    const file = item.file as unknown as File;
    this.uploading = true;

    return this.uploadService.uploadImage(file).subscribe({
      next: (url) => {
        this.uploading = false;
        if (item.onSuccess) {
          item.onSuccess(url, item.file, {});
        }
      },
      error: (err) => {
        this.uploading = false;
        this.message.error('Error al subir la imagen');
        if (item.onError) {
          item.onError(err, item.file);
        }
      }
    });
  };

  handleChange = (info: NzUploadChangeParam, section: 'left' | 'right' | 'center'): void => {
    if (info.type === 'success') {
      const imageUrl = info.file.response;
      this.updateImagePreview(section, imageUrl);
    }
  };

  private updateImagePreview(section: 'left' | 'right' | 'center', imageUrl: string): void {
    switch (section) {
      case 'left':
        this.leftImagePreview = imageUrl;
        this.adsForm.get('leftSection')?.patchValue({ imageUrl });
        break;
      case 'right':
        this.rightImagePreview = imageUrl;
        this.adsForm.get('rightSection')?.patchValue({ imageUrl });
        break;
      case 'center':
        this.centerImagePreview = imageUrl;
        this.adsForm.get('centerSection')?.patchValue({ imageUrl });
        break;
    }
  }

  private resetFormSections(layout: 'A' | 'B'): void {
    if (layout === 'A') {
      this.adsForm.get('centerSection')?.disable();
      this.adsForm.get('leftSection')?.enable();
      this.adsForm.get('rightSection')?.enable();
      this.centerImagePreview = null;
    } else {
      this.adsForm.get('centerSection')?.enable();
      this.adsForm.get('leftSection')?.disable();
      this.adsForm.get('rightSection')?.disable();
      this.leftImagePreview = null;
      this.rightImagePreview = null;
    }
  }

  async onSubmit(): Promise<void> {
    if (this.adsForm.valid) {
      const formValue = this.adsForm.value;
      const layout = formValue.layoutType;
      
      if (layout === 'A') {
        const leftFilled = this.isSectionFilled(formValue.leftSection);
        const rightFilled = this.isSectionFilled(formValue.rightSection);
        
        if (!leftFilled && !rightFilled) {
          this.message.error('Al menos una sección debe estar completa');
          return;
        }
      }
      
      // Formatear los datos para que coincidan con la estructura esperada
      const result = {
        adsLayout: layout,
        hasAds: true,
        ads: layout === 'A' 
          ? [
              this.formatAdSection(formValue.leftSection),
              this.formatAdSection(formValue.rightSection)
            ]
          : [this.formatAdSection(formValue.centerSection)]
      };
      
     await this.spacesService.loadAds(this.spaceId, result);
     this.nzDrawerRef.close();
     this.message.success('Anuncio cargado correctamente');
    }
  }

  private formatAdSection(section: any) {
    return {
      imgUrl: section.imageUrl || '',
      externalLink: section.externalLink || '',
      companyName: section.companyName || '',
      shareNumber: section.shareNumber || 0,
      expirationDate: section.expirationDate 
        ? {
            seconds: Math.floor(section.expirationDate.getTime() / 1000),
            nanoseconds: 0
          }
        : null
    };
  }

  private isSectionFilled(section: any): boolean {
    return !!(section.imageUrl || section.externalLink || section.companyName || section.shareNumber || section.expirationDate);
  }
}
