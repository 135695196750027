import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  NzTableFilterFn,
  NzTableFilterList,
  NzTableSortFn,
  NzTableSortOrder,
} from 'ng-zorro-antd/table';
import { BehaviorSubject, map, startWith, switchMap } from 'rxjs';
import { Discipline } from 'src/app/@core/models/inscription.interface';
import { InscriptionsService } from 'src/app/@core/services/inscriptions.service';
import { ManualInscriptionComponent } from 'src/app/pages/inscriptions/manual-inscription/manual-inscription.component';
import { DisciplineFormComponent } from '../../../discipline-form/discipline-form.component';
import { ParticipantFormComponent } from '../participant-form/participant-form.component';
interface DataItem {
  fullName: string;
  type: string;
  email: string;
  phoneNumber: string;
  id: string;
  createdAt: Date | any;
  age: number;
}

interface ColumnItem {
  name: string;
  sortOrder: NzTableSortOrder | null;
  sortFn: NzTableSortFn<DataItem> | null;
  listOfFilter: NzTableFilterList;
  filterFn: NzTableFilterFn<DataItem> | null;
  filterMultiple: boolean;
  sortDirections: NzTableSortOrder[];
  searchText?: string;
  filterVisible?: boolean;
}
@Component({
  selector: 'app-participant-table',
  templateUrl: './participant-table.component.html',
  styleUrls: ['./participant-table.component.css'],
  styles: [
    `
      :host ::ng-deep .ant-table-wrapper {
        width: 100%;
        overflow-x: auto;
      }

      :host ::ng-deep .ant-table table {
        min-width: 800px;
      }

      :host ::ng-deep .email-cell {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      :host ::ng-deep .name-cell {
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `,
  ],
})
export class ParticipantTableComponent implements OnInit {
  @Input() set config(config: { disciplineID: string; inscriptionID: string }) {
    this.config$.next(config);
  }

  config$ = new BehaviorSubject<any>(null);
  isLoading$ = new BehaviorSubject<boolean>(false);

  getDiscipline$ = this.config$.pipe(
    startWith({ isLoading: true, data: null }),
    switchMap(config => {
      return this.inscriptionsService.getDiscipline(
        config.inscriptionID,
        config.disciplineID
      );
    }),
    map(discipline => {
      return { isLoading: false, data: discipline };
    })
  );

  participants$ = this.config$.pipe(
    switchMap(config => {
      return this.inscriptionsService.getParticipantsByDiscipline(
        config.inscriptionID,
        config.disciplineID
      );
    }),
    map(participants => {
      return participants.sort(
        (a, b) => a.createdAt.seconds * 1000 - b.createdAt.seconds * 1000
      );
    })
  );

  searchValue = '';
  visible = false;
  activeColumn: ColumnItem | null = null;
  listOfDisplayData: DataItem[] = [];

  constructor(
    private inscriptionsService: InscriptionsService,
    private modal: NzModalService,
    private nzDrawer: NzDrawerService,
    private message: NzMessageService
  ) {}

  listOfColumns: ColumnItem[] = [
    {
      name: 'Estado',
      sortOrder: null,
      sortFn: null,
      listOfFilter: [],
      filterFn: null,
      filterMultiple: true,
      sortDirections: ['ascend', 'descend', null],
    },
    {
      name: 'Nombre',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) =>
        a.fullName.localeCompare(b.fullName),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
      searchText: '',
      filterVisible: false,
    },
    {
      name: 'Accion',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: DataItem, b: DataItem) => a.type.localeCompare(b.type),
      filterMultiple: true,
      listOfFilter: [
        { text: 'Socio', value: 'socio' },
        { text: 'Invitado', value: 'invitado' },
      ],
      filterFn: (list: string[], item: DataItem) =>
        list.length ? list.includes(item.type) : true,
    },
    {
      name: 'Edad',
      sortOrder: null,
      sortDirections: ['ascend', 'descend', null],
      sortFn: (a: DataItem, b: DataItem) => a.age - b.age,
      filterMultiple: true,
      listOfFilter: [],
      filterFn: (list: string[], item: DataItem) =>
        list.length ? list.includes(item.type) : true,
    },
    {
      name: 'Email',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: null,
      searchText: '',
      filterVisible: false,
    },
    {
      name: 'Teléfono',
      sortOrder: null,
      sortFn: (a: DataItem, b: DataItem) =>
        a.phoneNumber.localeCompare(b.phoneNumber),
      sortDirections: ['ascend', 'descend', null],
      filterMultiple: true,
      listOfFilter: [],
      filterFn: (list: string[], item: DataItem) =>
        list.length
          ? list.some(phone => item.phoneNumber.indexOf(phone) !== -1)
          : true,
    },
  ];

  ngOnInit() {}

  openManualInscription(config: {
    disciplineID: string;
    inscriptionID: string;
  }) {
    this.modal.create({
      nzContent: ManualInscriptionComponent,
      nzTitle: 'Inscribir participante',
      nzData: {
        inscriptionID: config.inscriptionID,
        disciplineID: config.disciplineID,
      },
      nzFooter: null,
    });
  }

  generateReport(config: { inscriptionID: string; disciplineID: string }) {
    this.isLoading$.next(true);
    return this.inscriptionsService.disciplineReport(config).subscribe({
      next: response => {
        const base64 = response.base64;
        // Convertir base64 a array de bytes
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Crear y descargar el blob
        const blob = new Blob([bytes], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'discipline_report.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
        this.isLoading$.next(false);
      },
      error: () => {
        this.message.error('Error al generar el reporte');
        this.isLoading$.next(false);
      },
    });
  }
  removeParticipant(
    participant: any,
    config: {
      inscriptionID: string;
      disciplineID: string;
    }
  ) {
    console.log({ participant, config }, 'table');
    this.inscriptionsService
      .deleteParticipant(participant.id, {
        inscriptionID: config.inscriptionID,
        disciplineID: config.disciplineID,
      })
      .subscribe(response => {
        console.log(response);
      });
  }

  setActiveColumn(column: ColumnItem): void {
    this.activeColumn = column;
    this.searchValue = column.searchText || '';
  }

  search(event: Event, column: ColumnItem | null): void {
    if (!column) return;

    const searchText = (event.target as HTMLInputElement).value.toLowerCase();
    column.searchText = searchText;

    this.participants$ = this.participants$.pipe(
      map(participants => {
        return participants.filter(data => {
          if (column.name === 'Nombre') {
            return data.fullName.toLowerCase().includes(searchText);
          }
          if (column.name === 'Email') {
            return data.email.toLowerCase().includes(searchText);
          }
          return true;
        });
      })
    );
  }

  reset(column: ColumnItem | null): void {
    if (!column) return;

    column.searchText = '';
    column.filterVisible = false;
    this.searchValue = '';
    this.participants$ = this.config$.pipe(
      switchMap(config => {
        return this.inscriptionsService.getParticipantsByDiscipline(
          config.inscriptionID,
          config.disciplineID
        );
      })
    );
  }
  deleteDiscipline(config: { disciplineID: string; inscriptionID: string }) {
    this.modal.confirm({
      nzTitle: 'Eliminar disciplina',
      nzContent:
        '¿Estás seguro de querer eliminar la disciplina?, esto eliminara los participantes inscritos en esta disciplina, y no se puede revertir',
      nzOkText: 'Eliminar',
      nzCancelText: 'Cancelar',
      nzOnOk: () => {
        return this.inscriptionsService
          .deleteDiscipline(config.disciplineID, config.inscriptionID)
          .subscribe();
      },
    });
  }
  editParticipantInscription(participant: any, config: any): void {
    console.log({ participant, config }, 'editParticipantInscription');
    const participantDrawer = this.nzDrawer.create({
      nzTitle: 'Editar Participante',
      nzContent: ParticipantFormComponent,
      nzContentParams: {
        participant,
        config,
      },
      nzWidth: 720,
    });
  }

  generateCertificates(participant: any) {
    console.log(participant, 'generateCertificates');
  }

  openDisciplineForm(discipline: Discipline) {
    this.modal.create({
      nzContent: DisciplineFormComponent,
      nzFooter: null,
      nzTitle: 'Editar Disciplina',
      nzData: {
        discipline,
        option: 'edit',
      },
    });
  }
}
