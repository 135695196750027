import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, EventApi } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { ClassFormComponent } from './class-form/class-form.component';
import { GymService } from 'src/app/@core/services/gym.service';
import { combineLatest } from 'rxjs';
import { addHours } from 'date-fns';
import { GymSettingsComponent } from './gym-settings/gym-settings.component';

@Component({
  selector: 'app-gym',
  templateUrl: './gym.component.html',
  styleUrls: ['./gym.component.scss'],
})
export class GymComponent implements OnInit {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  drawerRef: NzDrawerRef;

  availableClasses$ = this.gymService.getAvailableClasses();
  activeClasses$ = this.gymService.getActiveClasses();
  gymSettings$ = this.gymService.getGymSettings();

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    initialView: 'timeGridWeek',
    nowIndicator: true,
    height: 'auto',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
    slotDuration: '00:30:00',
    allDaySlot: false,

    buttonText: {
      today: 'Hoy',
      month: 'Mes',
      week: 'Semana',
      day: 'Día',
      list: 'Lista',
    },
    editable: true,
    locale: 'es',
    eventMinHeight: 50,
    slotLabelInterval: '00:30:00',
    contentHeight: 'auto',
    timeZone: 'America/Caracas',
    slotLabelFormat: {
      hour: '2-digit',
      hour12: true,
      meridiem: 'short',
      minute: '2-digit',
    },
    droppable: true,

    events: [],
    eventReceive: this.renderEventContent.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventDrop: this.handleEventDrop.bind(this),
  };

  constructor(
    private drawerService: NzDrawerService,
    private gymService: GymService
  ) {}

  ngOnInit(): void {
    this.gymSettings$.subscribe(settings => {
      this.calendarOptions.businessHours = {
        ...settings.config?.businessHours,
      };
      this.calendarOptions.slotMaxTime = settings.config?.businessHours.endTime;
      this.calendarOptions.slotMinTime =
        settings.config?.businessHours.startTime;
    });
  }

  editClass(classData: any): void {
    this.openClassForm(classData);
  }

  openCreateClassForm(): void {
    this.drawerRef = this.drawerService.create<ClassFormComponent>({
      nzTitle: 'Crear Nueva Clase',
      nzContent: ClassFormComponent,
      nzWidth: 420,
    });
  }

  openClassForm(classData?: any): void {
    this.drawerRef = this.drawerService.create<ClassFormComponent>({
      nzTitle: classData ? 'Editar Clase' : 'Crear Nueva Clase',
      nzContent: ClassFormComponent,
      nzContentParams: {
        classData: classData,
        from: 'calendar',
      },
      nzWidth: 420,
    });
  }

  handleEventClick(info: any): void {
    this.openClassForm(info.event.toPlainObject());
  }

  handleEventDrop(info: any): void {
    console.log('Evento movido:', info.event);

    if (!info.event.title) {
      return;
    }

    const updatedClass = {
      id: info.event.id,
      start: addHours(new Date(info.event.start), 4),
      end: addHours(new Date(info.event.end), 4),
      status: 'Nuevo horario',
    };

    this.gymService
      .updateActiveClass(updatedClass.id, updatedClass)
      .then(() => console.log('Clase actualizada exitosamente'))
      .catch(error => console.error('Error al actualizar la clase:', error));
  }

  private durationToMilliseconds(duration: string): number {
    const [hours, minutes] = duration.split(':').map(Number);
    return (hours * 60 + minutes) * 60 * 1000;
  }

  renderEventContent(eventInfo: any) {
    return this.gymService.addActiveClass(eventInfo.event.toPlainObject());
  }

  openSettings(): void {
    this.drawerRef = this.drawerService.create<GymSettingsComponent>({
      nzTitle: 'Configuración',
      nzContent: GymSettingsComponent,
    });
  }
}
