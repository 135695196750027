import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { arrayRemove, deleteField } from '@angular/fire/firestore';
import { addHours } from 'date-fns';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GymService {
  constructor(
    private aff: AngularFireFunctions,
    private afs: AngularFirestore
  ) {}

  createClass(classData: any) {
    const callable = this.aff.httpsCallable('createClass');
    return callable({ classData }).toPromise();
  }

  getAvailableClasses() {
    return this.afs
      .collection('gym/availableClasses/classes')
      .valueChanges() as Observable<any[]>;
  }

  getActiveClasses() {
    return this.afs
      .collection('gym/activeClasses/classes')
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
  }

  getClass(classId: string) {
    return this.afs
      .doc(`gym/activeClasses/classes/${classId}`)
      .valueChanges() as Observable<any>;
  }

  getClassAttendees(classId: string) {
    return this.afs
      .collection(`gym/activeClasses/classes/${classId}/bookings`)
      .valueChanges() as Observable<any[]>;
  }

  deleteClass(classData: any) {
    const callable = this.aff.httpsCallable('deleteClass');
    return callable({ classData }).toPromise();
  }

  updateClass(classData: any) {
    const callable = this.aff.httpsCallable('updateClass');
    return callable({ classData }).toPromise();
  }

  createAvailableClass(classData: any) {
    const id = this.afs.createId();

    return this.afs
      .collection('gym/availableClasses/classes')
      .doc(id)
      .set({ ...classData, id, createdAt: new Date() });
  }

  async addActiveClass(classData: any) {
    console.log(classData, 'classData-------');
    if (
      !classData.title ||
      classData.title === null ||
      classData.title === 'null'
    ) {
      return;
    }

    const newClass = {
      ...classData,
      status: 'confirmed', // Estado por defecto al crear una nueva clase
    };

    console.log(newClass, 'nueva clase');

    return await this.aff
      .httpsCallable('createActiveClass')({
        classData: { ...newClass, active: true },
      })
      .toPromise();
  }

  /// ACTIVE CLASSES

  async updateActiveClass(classId: string, classData: any) {
    console.log('updateActiveClass');
    console.log(classData, 'classData');

    if (classData.start || classData.end) {
      const startString = addHours(new Date(classData.start), -4).toISOString();
      const endString = addHours(new Date(classData.end), -4).toISOString();

      const updatedClassData = {
        ...classData,
        start: startString,
        end: endString,
      };
      return await this.afs.doc(`gym/activeClasses/classes/${classId}`).update({
        ...updatedClassData,
      });
    }

    return await this.afs.doc(`gym/activeClasses/classes/${classId}`).update({
      ...classData,
    });
  }
  async deleteActiveClass(classId: string) {
    return await this.afs.doc(`gym/activeClasses/classes/${classId}`).delete();
  }

  /// AVAILABLE CLASSES
  updateAvailableClass(classId: string, classData: any): Promise<void> {
    console.log(classData, 'updateAvailableClass-------');
    return this.afs
      .doc(`gym/availableClasses/classes/${classId}`)
      .update(classData);
  }

  deleteAvailableClass(classId: string): Promise<void> {
    return this.afs.doc(`gym/availableClasses/classes/${classId}`).delete();
  }

  markAbsent(classId: string, userId: string) {
    this.afs
      .collection(`gym/activeClasses/classes/${classId}/bookings`)
      .doc(userId)
      .update({ notShow: true });

    this.afs
      .collection(`users`)
      .doc(userId)
      .update({ gymNotShow: true, gymNotShowAt: new Date() });
  }

  getGymSettings() {
    return this.afs.doc('gym/settings').valueChanges() as Observable<any>;
  }

  saveGymSettings(settings: any) {
    return this.afs.doc('gym/settings').update(settings);
  }

  removeGymNotShow(userId: string) {
    return this.afs.collection('users').doc(userId).update({
      gymNotShow: deleteField(),
      gymNotShowAt: deleteField(),
      gymClassCanceled: deleteField(),
    });
  }
}
