import { Component, Input } from '@angular/core';
import {
  CellContextMenuEvent,
  ColDef,
  GridOptions,
  GridReadyEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import {
  NzContextMenuService,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { Observable, Subject, map, switchMap, zip } from 'rxjs';
import { SharesService } from 'src/app/@core/services/shares.service';

@Component({
  selector: 'app-share-drawer-transactions-table',
  templateUrl: './share-drawer-transactions-table.component.html',
  styleUrls: ['./share-drawer-transactions-table.component.scss'],
})
export class ShareDrawerTransactionsTableComponent {
  public rowData$!: Observable<any[]>;
  loading = false;

  constructor(
    private shareService: SharesService,
    private nzContextMenuService: NzContextMenuService
  ) {}
  cellContextMenuEvent$ = new Subject<CellContextMenuEvent>();
  cellContextMouseEvent$ = new Subject<MouseEvent>();
  cellDropDownMenu$ = new Subject<NzDropdownMenuComponent>();

  shareNumber$ = new Subject<number>();
  @Input('shareNumber') set data(shareNumber: any) {
    this.shareNumber$.next(shareNumber);
  }
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    flex: 1,
    resizable: true,
  };

  gridOptions: GridOptions = {
    suppressContextMenu: true,
    preventDefaultOnContextMenu: true,
  };

  public columnDefs: ColDef[] = [
    { field: 'type', headerName: 'Tipo' },
    { field: 'description', headerName: 'Descripción' },
    { field: 'amount', headerName: 'Monto' },
    { field: 'status', headerName: 'Estado' },
    { field: 'shareNumber', headerName: 'Acciones' },
  ];

  onRowClicked(e: RowClickedEvent) {}

  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.shareNumber$.pipe(
      switchMap(shareNumber =>
        this.shareService.getTransactionsByShareNumber(shareNumber)
      )
    );
  }

  contextMenuRowData$ = zip(
    this.cellContextMouseEvent$,
    this.cellDropDownMenu$,
    this.cellContextMenuEvent$
  ).pipe(
    map(([event, dropDown, { data }]) => {
      this.nzContextMenuService.create(event, dropDown);
      return data;
    })
  );

  contextMenu(
    mouseEvent: MouseEvent,
    dropDownMenu: NzDropdownMenuComponent
  ): void {
    this.cellContextMouseEvent$.next(mouseEvent);
    this.cellDropDownMenu$.next(dropDownMenu);
  }

  closeMenu(): void {
    this.nzContextMenuService.close();
  }

  handleUserDetails(e: any) {
    console.log(e);
  }

  handleUserVerification(e: any) {
    console.log(e);
  }
}
