<form nz-form [formGroup]="editForm" (ngSubmit)="submitForm()">
  <!-- Nombre completo -->
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Nombre completo</nz-form-label>
    <nz-form-control
      [nzSpan]="14"
      nzErrorTip="Por favor ingrese el nombre completo">
      <input nz-input formControlName="fullName" />
    </nz-form-control>
  </nz-form-item>

  <!-- Email -->
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Email</nz-form-label>
    <nz-form-control
      [nzSpan]="14"
      nzErrorTip="Por favor ingrese un email válido">
      <input nz-input formControlName="email" type="email" />
    </nz-form-control>
  </nz-form-item>

  <!-- Teléfono -->
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Teléfono</nz-form-label>
    <nz-form-control
      [nzSpan]="14"
      nzErrorTip="Por favor ingrese un teléfono válido">
      <ngx-intl-tel-input
        cssClass="ant-input w-full"
        [preferredCountries]="preferredCountries"
        [searchCountryPlaceholder]="'Busca por pais'"
        [enableAutoCountrySelect]="true"
        [enablePlaceholder]="true"
        [searchCountryFlag]="true"
        [separateDialCode]="true"
        [searchCountryField]="[
          SearchCountryField.Iso2,
          SearchCountryField.Name
        ]"
        [selectFirstCountry]="false"
        [selectedCountryISO]="CountryISO.Venezuela"
        [maxLength]="15"
        [phoneValidation]="true"
        [numberFormat]="PhoneNumberFormat.International"
        formControlName="phoneNumber">
      </ngx-intl-tel-input>
    </nz-form-control>
  </nz-form-item>

  <!-- Edad -->
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Edad</nz-form-label>
    <nz-form-control [nzSpan]="14" nzErrorTip="Por favor ingrese la edad">
      <nz-input-number
        formControlName="age"
        [nzMin]="0"
        [nzMax]="120"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <!-- Número de participación -->
  @if (participant.type === 'socio') {
    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired>Número de acción</nz-form-label>
      <nz-form-control
        [nzSpan]="14"
        nzErrorTip="Por favor ingrese el número de acción">
        <input
          nz-input
          type="number"
          formControlName="shareNumber"
          placeholder="Número de acción" />
      </nz-form-control>
    </nz-form-item>
  }

  @if (participant.type === 'invitado') {
    <nz-form-item>
      <nz-form-label [nzSpan]="6" nzRequired>Acción</nz-form-label>
      <nz-form-control [nzSpan]="14" nzErrorTip="Por favor ingrese la acción">
        <input
          nz-input
          formControlName="shareNumber"
          placeholder="Número de acción" />
      </nz-form-control>
    </nz-form-item>
  }

  <!-- Estado -->
  <nz-form-item>
    <nz-form-label [nzSpan]="6">Estado</nz-form-label>
    <nz-form-control [nzSpan]="14">
      <nz-select formControlName="status">
        <nz-option nzValue="pending" nzLabel="Pendiente"></nz-option>
        <nz-option nzValue="approved" nzLabel="Aprobado"></nz-option>
        <nz-option nzValue="rejected" nzLabel="Rechazado"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <!-- Botones de acción -->
  <nz-form-item>
    <nz-form-control [nzOffset]="6" [nzSpan]="14">
      <button
        nz-button
        nzType="primary"
        type="submit"
        [disabled]="!editForm.valid">
        Guardar
      </button>
      <button nz-button nzType="default" (click)="cancel()" class="ml-2">
        Cancelar
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
