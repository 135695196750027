<ng-container
  *ngIf="{
    data: userID$ | async,
    userData: userData$ | async,
    profession: profession$ | async,
    isFormSubmiting: isFormSubmiting$ | async,
    countries: countries$ | async,
    share: share$ | async,
    currentUser: currentUser$ | async,
    isGeneratingResetLink: isGeneratingResetLink$ | async
  } as state"
  (keydown.enter)="$event.preventDefault()">
  asdasdas
  {{ state.userData?.gymClassCanceled | json }}
  <form
    nz-form
    [formGroup]="createMemberForm"
    nzLayout="vertical"
    enctype="multipart/form-data"
    (keydown.enter)="$event.preventDefault()">
    <div nz-row [nzGutter]="10">
      <!-- col -->
      <div nz-col nzSpan="9" class="flex justify-center">
        <div class="w-[150px] h-[200px] rounded-xl flex relative">
          <label for="profilePhoto">
            <img
              class="-z-1 aspect-auto absolute rounded-xl object-cover h-[200px] w-[150px]"
              [src]="
                state.userData?.profilePhoto ??
                '/assets/img/profile-placeholder.png'
              " />
          </label>
          <input
            type="file"
            *ngIf="state.userData?.userID"
            name="profilePhoto"
            id="profilePhoto"
            accept="image/*"
            (change)="uploadImage($event, state.userData?.userID)" />

          <div>
            <span
              class="absolute top-4 right-4 bg-blue-700 rounded-full w-3 h-3 cursor-pointer"
              nz-tooltip
              [nzTooltipTitle]="
                !state.userData?.userID
                  ? 'Despues de crear el usuario, pordras cargar la foto.'
                  : 'Haz click en la foto para cambiarla'
              "></span>
          </div>
        </div>
      </div>

      <!-- col -->
      <div nz-col nzSpan="15">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="6" nzFor="cid">Cédula:</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              formControlName="cid"
              name="cid"
              type="number"
              id="cid"
              pattern="^[0-9/-0-9]*$" />
            <div
              *ngIf="
                createMemberForm.get('cid').errors &&
                (createMemberForm.get('cid').dirty ||
                  createMemberForm.get('cid').touched)
              ">
              <div class="text-red-600">Este es un campo obligatorio</div>
            </div>
          </nz-form-control>
        </nz-form-item>

        <!-- names -->
        <nz-form-item class="mb-2">
          <nz-form-label nzRequired [nzSpan]="24" nzFor="fname">
            {{
              !(
                state.share?.type === 'Jurídica' &&
                !state.share?.authorized.includes(state.userData?.userID)
              )
                ? 'Nombre y Apellido'
                : 'Razon Social'
            }}
          </nz-form-label>
          <nz-form-control>
            <input
              nz-input
              formControlName="fname"
              name="fname"
              type="text"
              id="fname"
              oninput="this.value = this.value.toUpperCase()" />
            <div
              *ngIf="
                createMemberForm.get('fname').errors &&
                (createMemberForm.get('fname').dirty ||
                  createMemberForm.get('fname').touched)
              ">
              <div class="text-red-600">Este es un campo obligatorio</div>
            </div>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="address"
            >Dirección:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              formControlName="address"
              name="address"
              type="text"
              id="address"
              oninput="this.value = this.value.toUpperCase()" />
            <div
              *ngIf="
                createMemberForm.get('address').errors &&
                (createMemberForm.get('address').dirty ||
                  createMemberForm.get('address').touched)
              ">
              <div class="text-red-600">Este es un campo obligatorio</div>
            </div>
          </nz-form-control>
        </nz-form-item>
        <!-- lnames -->
        <!-- lastName wont be used  
        <nz-form-item class="mb-2">
          <nz-form-label nzRequired [nzSpan]="6" nzFor="lnames"
            >Apellidos:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              formControlName="lname"
              name="lnames"
              type="text"
              id="lnames"
              oninput="this.value = this.value.toUpperCase()" />
            <div
              *ngIf="
                createMemberForm.get('lname').errors &&
                (createMemberForm.get('lname').dirty ||
                  createMemberForm.get('lname').touched)
              ">
              <div class="text-red-600">Este es un campo obligatorio</div>
            </div>
          </nz-form-control>
        </nz-form-item>-->
      </div>
    </div>

    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="rif">RIF:</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              name="rif"
              formControlName="rif"
              type="text"
              id="rif"
              oninput="this.value = this.value.toUpperCase()" />
            <div
              *ngIf="
                createMemberForm.get('rif').errors &&
                (createMemberForm.get('rif').dirty ||
                  createMemberForm.get('rif').touched)
              ">
              <div class="text-red-600">Este es un campo obligatorio</div>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label nzRequired [nzSpan]="24" nzFor="email"
            >Correo:</nz-form-label
          >
          <nz-form-control>
            <nz-input-group
              [nzSuffix]="
                isValidEmail(state.userData?.email) &&
                state.currentUser?.authorization?.admin
                  ? unlockButton
                  : null
              ">
              <input
                nz-input
                name="email"
                formControlName="email"
                type="email"
                id="email" />
            </nz-input-group>

            <div *ngIf="createMemberForm.get('email')?.errors?.taken">
              Este correo ya existe.
            </div>
            <div
              *ngIf="
                createMemberForm.get('email').errors &&
                (createMemberForm.get('email').dirty ||
                  createMemberForm.get('email').touched)
              ">
              <div class="text-red-600">Este es un campo obligatorio</div>
            </div>
          </nz-form-control>

          <ng-template #unlockButton>
            <span
              nz-tooltip
              nzTooltipTitle="Generar Link de Recuperación de contraseña"
              (click)="generateAndCopyRecoveryLink(state.userData?.email)">
              <span
                class="mb-1"
                nz-icon
                [nzType]="state.isGeneratingResetLink ? 'loading' : 'unlock'"
                nzTheme="outline"></span>
            </span>
          </ng-template>

          <div class="flex justify-end pt-1">
            <a (click)="generateFakeEmail()">Generar Email Aleatorio</a>
          </div>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="dob"
            >Fecha de Nacimiento:</nz-form-label
          >
          <nz-form-control nzErrorTip="Debe ingresar una fecha de nacimiento">
            <input
              nz-input
              nzRequired
              name="dob"
              formControlName="dob"
              type="date"
              id="dob" />

            <div
              *ngIf="
                createMemberForm.get('dob').invalid &&
                (createMemberForm.get('dob').dirty ||
                  createMemberForm.get('dob').touched)
              ">
              <div class="text-red-600">Este es un campo obligatorio</div>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="nacionality"
            >Nacionalidad:</nz-form-label
          >
          <nz-form-control>
            <nz-select
              nzShowSearch
              formControlName="nacionality"
              id="nacionality"
              nzAllowClear
              nzPlaceHolder="Nacionalidad"
              name="dob"
              formControlName="nacionality"
              id="nacionality">
              <nz-option
                *ngFor="let country of state.countries?.list"
                [nzLabel]="country"
                [nzValue]="country"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="24">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="profession"
            >Profesion:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              placeholder="Ingrese Profesion"
              name="profession"
              type="text"
              formControlName="profession"
              id="profession"
              oninput="this.value = this.value.toUpperCase()" />
            <!-- <nz-select
              nzShowSearch
              nzAllowClear
              nzPlaceHolder="Selecciona una Profesion"
              formControlName="profession">
              <nz-option
                *ngFor="let profession of state.profession?.listv2"
                [nzLabel]="profession"
                [nzValue]="profession"></nz-option>
            </nz-select> -->
            <!-- <input nz-input name="profession"  type="text" id="profession"> -->
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="16">
        <nz-form-item class="mb-2">
          <nz-form-label>Teléfono:</nz-form-label>
          <nz-form-control>
            <ngx-intl-tel-input
              nz-re
              cssClass="ant-input w-full"
              [preferredCountries]="preferredCountries"
              [searchCountryPlaceholder]="'Busca por pais'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [separateDialCode]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Venezuela"
              [maxLength]="15"
              [phoneValidation]="true"
              [numberFormat]="PhoneNumberFormat.International"
              formControlName="phoneNumber">
            </ngx-intl-tel-input>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24">Es Directivo:</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="isDirective"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzFor="description"
            >Descripción:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="description"
              type="textarea"
              formControlName="description"
              id="description" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-collapse>
          <!-- Detalles de la accion -->
          <nz-collapse-panel nzHeader="Empresa">
            <div formGroupName="company">
              <div nz-row [nzGutter]="10">
                <div nz-col nzSpan="15">
                  <nz-form-item class="mb-2">
                    <nz-form-label [nzSpan]="24" nzFor="companyName"
                      >Nombre:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="companyName"
                        type="text"
                        formControlName="companyName"
                        id="companyName"
                        oninput="this.value = this.value.toUpperCase()" />
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item class="mb-2">
                    <nz-form-label [nzSpan]="24" nzFor="position"
                      >Posición:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="position"
                        type="text"
                        formControlName="position"
                        id="position"
                        oninput="this.value = this.value.toUpperCase()" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="9" class="flex justify-center">
                  <div
                    class="w-[150px] h-[150px] rounded-xl flex relative items-end">
                    <img
                      [src]="
                        !state.data?.value?.company?.companyLogo
                          ? companyLogoPreview ||
                            '/assets/img/profile-placeholder.png'
                          : state.data?.value?.company?.companyLogo
                      "
                      class="-z-1 absolute object-cover aspect-auto rounded-xl h-[150px] w-[150px] border-2" />
                    <div>
                      <span
                        class="absolute top-2 right-2 bg-blue-700 rounded-full w-3 h-3 cursor-pointer"
                        nz-tooltip
                        nzTooltipTitle="Tamaño: 150px 150px"></span>
                    </div>
                    <div class="z-10">
                      <label for="companyLogo">
                        <span
                          nz-button
                          nzType="primary"
                          class="rounded-se-xl rounded-es-xl bg-white px-2 py-1"
                          >Cargar Foto</span
                        >
                      </label>
                      <input
                        type="file"
                        name="companyLogo"
                        id="companyLogo"
                        accept="image/*"
                        (change)="handleShowPreview($event)" />
                    </div>
                  </div>
                </div>
              </div>
              <div nz-row [nzGutter]="10">
                <div nz-col nzSpan="15">
                  <nz-form-item class="mb-2">
                    <nz-form-label [nzSpan]="24" nzFor="corporateMail"
                      >Correo Corporativo:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="corporateMail"
                        formControlName="corporateMail"
                        type="email"
                        id="corporateMail" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div nz-col nzSpan="9">
                  <nz-form-item class="mb-2">
                    <nz-form-label [nzSpan]="24" nzFor="website"
                      >Sitio Web:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="website"
                        type="text"
                        formControlName="website"
                        id="website"
                        oninput="this.value = this.value.toUpperCase()" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </div>
          </nz-collapse-panel>

          <!-- emergency contacts -->
          <nz-collapse-panel
            nzHeader="Contacto de Emergencias"
            formGroupName="emergencyContacts">
            <nz-card nzTitle="Contacto #1" class="mb-2">
              <div nz-row class="mt-4">
                <div nz-col nzSpan="24">
                  <nz-form-item class="mb-2">
                    <nz-form-label [nzSpan]="24" nzFor="fullName"
                      >Nombre:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="fullName"
                        type="text"
                        id="fullName"
                        oninput="this.value = this.value.toUpperCase()" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row class="mt-4">
                <div nz-col nzSpan="24">
                  <nz-form-item class="mb-2">
                    <nz-form-label [nzSpan]="24" nzFor="phoneNumber"
                      >Telefono:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="phoneNumber"
                        type="text"
                        id="phoneNumber"
                        oninput="this.value = this.value.toUpperCase()" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </nz-card>
            <nz-card nzTitle="Contacto #2">
              <div nz-row class="mt-4">
                <div nz-col nzSpan="24">
                  <nz-form-item class="mb-2">
                    <nz-form-label [nzSpan]="24" nzFor="fullName"
                      >Nombre:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="fullName"
                        type="text"
                        id="fullName"
                        oninput="this.value = this.value.toUpperCase()" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row class="mt-4">
                <div nz-col nzSpan="24">
                  <nz-form-item class="mb-2">
                    <nz-form-label nzRequired [nzSpan]="24" nzFor="phoneNumber"
                      >Telefono:</nz-form-label
                    >
                    <nz-form-control>
                      <input
                        nz-input
                        name="phoneNumber"
                        type="text"
                        id="phoneNumber"
                        oninput="this.value = this.value.toUpperCase()" />
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </nz-card>
          </nz-collapse-panel>

          <nz-collapse-panel nzHeader="Archivos" [nzExtra]="extraTpl">
            <ng-template #extraTpl>
              <div class="z-10">
                <a
                  [disabled]="!state.data?.value?.userID"
                  nz-button
                  nzType="primary"
                  [nz-tooltip]="
                    !state.data?.value?.userID
                      ? 'Debes crear el Miembro Primero'
                      : ''
                  "
                  (click)="
                    $event.stopPropagation();
                    openFileUploadComponent(state.data)
                  "
                  class="px-2 py-1"
                  >Cargar Archivos</a
                >
              </div>
            </ng-template>
            <ng-container
              *ngIf="
                state.userData?.files?.length > 0 && state.userData?.userID;
                else emptyTpl
              ">
              Archivos Guardados:

              <div *ngFor="let file of state.userData?.files; let i = index">
                <div class="flex justify-between">
                  <a
                    [href]="file?.url"
                    target="_blank"
                    [download]="file?.url"
                    >{{ file?.fileName }}</a
                  >
                  <a
                    class="text-red-500"
                    (click)="deleteFile(file, state.userData?.userID)"
                    >Borrar</a
                  >
                </div>
              </div>
            </ng-container>

            <ng-template #emptyTpl>
              <nz-empty></nz-empty>
            </ng-template>
          </nz-collapse-panel>

          <nz-collapse-panel nzHeader="Otras Opciones">
            <div nz-row class="w-full p-1">
              <div class="flex justify-between items-center w-full">
                <p class="m-0 w-auto">
                  El usuario tiene:
                  {{ state.userData?.notShowCounter }} inasistencias
                </p>
                <button
                  nz-button
                  nzDanger
                  [disabled]="state.userData?.notShowCounter === 0"
                  (click)="resetCounter(state.userData)">
                  Reiniciar Inasistencias
                </button>
              </div>
            </div>
            <div
              class="flex justify-between items-center w-full"
              *ngIf="state.userData?.gymNotShowAt">
              <p class="m-0 w-auto">
                El usuario tiene inasistencias en Gym el día
                {{
                  state.userData?.gymNotShowAt?.seconds * 1000
                    | date: 'dd/MM/yyyy'
                }}
              </p>
              <button
                nz-button
                nzDanger
                [disabled]="!state.userData?.gymNotShow"
                (click)="resetGymNotShow(state.userData)">
                Reiniciar Gym
              </button>
            </div>

            <div class="flex justify-between items-center w-full">
              <ul class="w-full">
                <li
                  *ngFor="let canceledClass of state.userData?.gymClassCanceled"
                  class="flex justify-between items-center w-full">
                  <div>
                    <p>Clase: {{ canceledClass.title }}</p>
                    <p>
                      Fecha: {{ canceledClass.classDate | date: 'dd/MM/yyyy' }}
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
  </form>

  <div style="float: right" class="py-4">
    <button
      nz-button
      nzDanger
      style="margin-right: 8px"
      (click)="drawerRef.close()">
      Cancelar
    </button>
    <button
      nz-button
      nzDanger
      *ngIf="state.data?.option === 'update'"
      (click)="deleteUser(state.data?.value)"
      style="margin-right: 8px">
      Borrar Miembro
    </button>
    <button
      nz-button
      [nzLoading]="state.isFormSubmiting"
      nzType="primary"
      (click)="
        submitForm(
          state.data?.option,
          state.data?.value?.userID,
          state.data?.share
        )
      ">
      {{
        state.data?.option === 'update'
          ? 'Actualizar Miembro'
          : state.isFormSubmiting
            ? 'Creando Miembro'
            : 'Crear Miembro'
      }}
    </button>
  </div>
</ng-container>
