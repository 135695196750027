<ng-container *ngIf="{ isLoading: isLoading$ | async } as state">
  <form
    nz-form
    [formGroup]="classForm"
    nzLayout="vertical"
    (ngSubmit)="onSubmit()">
    <nz-form-item>
      <nz-form-label nzFor="title">Nombre de la clase</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="title" id="title" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="instructor">Instructor</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="instructor" id="instructor" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="capacity">Capacidad</nz-form-label>
      <nz-form-control>
        <nz-input-number
          formControlName="capacity"
          id="capacity"
          [nzMin]="1"></nz-input-number>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="duration">Duración</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="duration" id="duration">
          <nz-option
            *ngFor="let duration of durations"
            [nzValue]="duration.value"
            [nzLabel]="duration.label">
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label nzFor="color">Color</nz-form-label>
      <nz-form-control>
        <div class="flex flex-row flex-wrap gap-2">
          <div
            *ngFor="let color of colors"
            [style.backgroundColor]="color.value"
            class="w-8 h-8 rounded-full cursor-pointer"
            [class.ring-2]="classForm.get('color')?.value === color.value"
            [class.ring-blue-500]="
              classForm.get('color')?.value === color.value
            "
            (click)="classForm.get('color')?.setValue(color.value)"></div>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Estado</nz-form-label>
      <nz-form-control>
        <nz-select class="flex w-full gap-x-2" formControlName="status">
          <nz-option
            *ngFor="let option of statusOptions"
            [nzValue]="option"
            [nzLabel]="option"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <div class="flex justify-between">
      <button
        *ngIf="from === 'calendar'"
        nz-button
        nzType="primary"
        nzDanger
        (click)="deleteClass()"
        [nzLoading]="state.isLoading!">
        Eliminar
      </button>
      <button
        [nzLoading]="state.isLoading!"
        nz-button
        nzType="primary"
        [disabled]="!classForm.valid">
        Guardar
      </button>
    </div>
  </form>
  {{ from }}
  <div class="flex justify-end" *ngIf="from === 'calendar'">
    <a
      [routerLink]="['pdf/gym-class', classData?.id]"
      nz-button
      nzType="primary"
      >Ver Listado</a
    >
  </div>
</ng-container>
