import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AgGridModule } from 'ag-grid-angular';
import { getFunctions } from 'firebase/functions';
import { es_ES, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { QuillModule } from 'ngx-quill';
import { environment } from 'src/environments/environment';
import { ShareHolderPickerModule } from './@core/components/share-holder-picker/share-holder-picker.module';
import { FilterPipe } from './@core/pipes/filter-name.pipe';
import { GroupGraphByPipe } from './@core/pipes/group-graph-by.pipe';
import { ReverseArrayPipe } from './@core/pipes/reverse-array.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { exportedComponents } from './component.index';
import { IconsProviderModule } from './icons-provider.module';
import { ngZorroModules } from './ngzorro.index';
import { AppointmentsModule } from './pages/appointments/appointments.module';
import { EmployeesModule } from './pages/employees/employees.module';
import { GalleryModule } from './pages/gallery/gallery.module';
import { GymModule } from './pages/gym/gym.module';
import { PoolModule } from './pages/pool/pool.module';
import { PopUpModule } from './pages/pop-up/pop-up.module';
import { SettingsModule } from './pages/settings/settings.module';
import { ShareListModule } from './pages/shares/share-list/share-list.module';
registerLocaleData(es);
@NgModule({
  declarations: [...exportedComponents],
  providers: [
    provideNgxMask(),
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: NZ_I18N,
      useValue: es_ES,
    },
    {
      provide: FIREBASE_OPTIONS,
      useValue: environment.firebase,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    ...ngZorroModules,
    AgGridModule,
    PopUpModule,
    GymModule,
    NzImageModule,
    GalleryModule,
    CommonModule,
    AppointmentsModule,
    NzSelectModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EmployeesModule,
    FormsModule,
    FullCalendarModule,
    HttpClientModule,
    IconsProviderModule,
    PoolModule,
    ReactiveFormsModule,
    SettingsModule,
    ShareListModule,
    ZXingScannerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    QuillModule,
    provideFunctions(() => getFunctions()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    AppRoutingModule,
    FilterPipe,
    GroupGraphByPipe,
    ShareHolderPickerModule,
    ReverseArrayPipe,
  ],
})
export class AppModule {}
