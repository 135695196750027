import { Injectable, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PoolService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions
  ) {}

  getNextResourceNumber() {
    return this.afs
      .collection('pool/tables/resources', ref =>
        ref.orderBy('tableNumber', 'desc').limit(1)
      )
      .valueChanges()
      .pipe(
        map((resourceData: any) => {
          return parseInt(resourceData[0]?.tableNumber) + 1 || 1;
        })
      );
  }

  getPoolConfig() {
    return this.afs
      .collection('pool')
      .doc('config')
      .valueChanges() as Observable<any>;
  }

  getPoolReservations() {
    return this.afs
      .collection('pool/reservations/history')
      .valueChanges() as Observable<any>;
  }
  getPoolResources() {
    return this.afs
      .collection('pool/tables/resources', ref =>
        ref.orderBy('tableNumber', 'asc')
      )
      .valueChanges();
  }

  async createResource(resource: any) {
    // Crear un nuevo documento con ID autogenerado
    const tableColRef = this.afs.collection('pool/tables/resources');
    const id = this.afs.createId();

    // Añadir el resourceID al objeto de datos
    const dataWithId = {
      ...resource,
      resourceId: id,
    };

    // Guardar el documento en Firebase
    return await tableColRef.doc(id).set(dataWithId);
  }

  deleteTable(id) {
    return this.afs.collection(`pool/tables/resources`).doc(id).delete();
  }

  async updateResource(resourceId: string, resourceData: any) {
    return this.afs
      .collection(`pool/tables/resources`)
      .doc(resourceId)
      .set(resourceData, { merge: true });
  }
}
