import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { GymService } from 'src/app/@core/services/gym.service';

@Component({
  selector: 'app-gym-settings',

  templateUrl: './gym-settings.component.html',
  styleUrl: './gym-settings.component.scss'
})
export class GymSettingsComponent implements OnInit {
  form: FormGroup;

  constructor(private fb: FormBuilder, private gymService: GymService) {}

  daysOfWeek = [
    { key: 0, label: 'Domingo' },
    { key: 1, label: 'Lunes' },
    { key: 2, label: 'Martes' },
    { key: 3, label: 'Miercoles' },
    { key: 4, label: 'Jueves' },
    { key: 5, label: 'Viernes' },
    { key: 6, label: 'Sabado' },
  ];
  ngOnInit(): void {




    this.form = this.fb.group({
      isActive: [true],
      cancelDeadline: [0, Validators.required],
      penalizationDays: [0, Validators.required],
      config: this.fb.group({
        businessHours: this.fb.group({
          daysOfWeek: [null],
          endTime: [null],
          startTime: [null],
        }),
      }),
    });

    this.gymService.getGymSettings().subscribe((settings) => {
      this.form.patchValue(settings);
    });
  }

  saveSettings(): void {
    this.gymService.saveGymSettings(this.form.value)
  }
}
