import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { InscriptionsService } from 'src/app/@core/services/inscriptions.service';

@Component({
  selector: 'app-edit-participant-form',
  templateUrl: './participant-form.component.html',
  styleUrls: ['./participant-form.component.css'],
})
export class ParticipantFormComponent implements OnInit {
  @Input() participant: any;
  @Input() config: any;
  editForm!: FormGroup;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.Venezuela,
    CountryISO.UnitedStates,
    CountryISO.Italy,
  ];
  PhoneNumberFormat = PhoneNumberFormat;
  constructor(
    private fb: FormBuilder,
    private nzDrawerRef: NzDrawerRef,
    private nzNotification: NzNotificationService,
    private inscriptionsService: InscriptionsService
  ) {
    console.log(this.config, 'config');
  }

  ngOnInit(): void {
    this.editForm = this.fb.group({
      fullName: [this.participant.fullName, [Validators.required]],
      email: [this.participant.email, [Validators.required, Validators.email]],
      phoneNumber: [
        this.participant.phoneNumber?.number || '',
        [Validators.required],
      ],
      age: [this.participant.age, [Validators.required]],
      shareNumber: [this.participant.shareNumber, [Validators.required]],
      status: [this.participant.status, [Validators.required]],
    });
  }

  async submitForm(): Promise<void> {
    if (this.editForm.valid) {
      const formValue = this.editForm.value;

      const updatedParticipant = {
        ...this.participant,
        fullName: formValue.fullName,
        email: formValue.email,
        phoneNumber: formValue.phoneNumber,
        age: formValue.age,
        status: formValue.status,
        shareNumber: formValue.shareNumber,
      };

      await this.inscriptionsService
        .updateParticipant(this.participant.inscriptionID, updatedParticipant)
        .then(() => {
          this.nzNotification.success(
            'Participante actualizado',
            'Participante actualizado correctamente'
          );
          this.nzDrawerRef.close();
        });
    }
  }

  cancel(): void {
    this.nzDrawerRef.close();
  }
}
