<ng-container
  *ngIf="{
    disciplines: disciplines$ | async,
    inscription: incriptionData$ | async,
    inGeneratingReport: inGeneratingReport$ | async,
    allParticipants: allParticipants$ | async
  } as state">
  <div class="manage-inscriptions">
    <!-- Header con información general -->

    <div class="mb-6">
      <div class="flex justify-between items-center mb-4">
        <div>
          <h2 class="text-2xl font-semibold">{{ state.inscription?.name }}</h2>
          <p class="text-gray-500">
            Capacidad total: {{ state.inscription?.capacity }} participantes
          </p>
          <p class="text-gray-500">
            Total Disciplinas: {{ state.disciplines?.length || 0 }}
          </p>
        </div>

        <div class="text-right">
          <p class="text-sm text-gray-500">
            Publicación:
            {{
              state.inscription?.publishDate?.seconds * 1000 | date: 'shortDate'
            }}
          </p>
          <p class="text-sm text-gray-500">
            Límite:
            {{
              state.inscription?.deadlineDate?.seconds * 1000
                | date: 'shortDate'
            }}
          </p>
        </div>
      </div>

      <!-- Estadísticas -->
      <div nz-row [nzGutter]="16" class="mb-4">
        <div nz-col [nzSpan]="8">
          <nz-card class="h-full">
            <nz-statistic
              class="h-full"
              [nzValue]="state.allParticipants?.length || 0"
              [nzTitle]="'Total Inscritos'"
              [nzSuffix]="'/ ' + state.inscription?.capacity">
            </nz-statistic>
          </nz-card>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-card class="h-full">
            <nz-statistic
              class="h-full"
              [nzValue]="
                (state.allParticipants | filter: 'type' : 'socio')?.length || 0
              "
              [nzTitle]="'Socios'">
            </nz-statistic>
          </nz-card>
        </div>
        <div nz-col [nzSpan]="8">
          <nz-card class="h-full">
            <nz-statistic
              class="h-full"
              [nzValue]="
                (state.allParticipants | filter: 'type' : 'invitado')?.length ||
                0
              "
              [nzTitle]="'Invitados'">
            </nz-statistic>
          </nz-card>
        </div>
      </div>
    </div>

    <!-- Buscador -->
    <div class="flex justify-between mb-4 gap-x-2">
      <nz-input-group [nzSuffix]="suffixIcon">
        <input
          type="text"
          nz-input
          placeholder="Buscar disciplina"
          [(ngModel)]="searchValue"
          (ngModelChange)="search()" />
      </nz-input-group>
      <ng-template #suffixIcon>
        <span nz-icon nzType="search"></span>
      </ng-template>

      <!-- Botones de acción -->
      <div class="flex justify-between">
        <button
          type="button"
          nz-button
          nzType="dashed"
          (click)="openDisciplineForm(state.inscription.id)">
          <span nz-icon nzType="plus"></span>
          Agregar Disciplina
        </button>
      </div>
      <div class="flex justify-between">
        <button
          type="button"
          nz-button
          nzType="dashed"
          [disabled]="state.inGeneratingReport"
          nz-tooltip
          [nzTooltipTitle]="
            state.inGeneratingReport
              ? 'Generando reporte...'
              : 'Generar reporte'
          "
          (click)="generateReport(state.inscription.id)">
          <span
            nz-icon
            [nzType]="
              state.inGeneratingReport ? 'loading' : 'file-text'
            "></span>
        </button>
      </div>
    </div>

    <!-- Listado de Disciplinas -->
    @defer {
      @for (
        discipline of state.disciplines | filter: 'name' : searchValue;
        track discipline.id
      ) {
        <div class="mb-4">
          @defer (on viewport) {
            <app-participant-table
              [config]="{
                disciplineID: discipline.id,
                inscriptionID: state.inscription.id
              }">
            </app-participant-table>
          } @placeholder {
            <div class="flex flex-col justify-center items-center h-full">
              <nz-spin></nz-spin>
              <p>Cargando...</p>
            </div>
          } @loading (minimum 1000ms) {
            <div class="flex flex-col justify-center items-center h-full">
              <nz-spin></nz-spin>
              <p>Cargando...</p>
            </div>
          }
        </div>
      } @empty {
        <div class="flex flex-col justify-center items-center h-full">
          <p>No hay disciplinas</p>
          <button
            type="button"
            nz-button
            nzType="dashed"
            (click)="openDisciplineForm(state.inscription.id)">
            <span nz-icon nzType="plus"></span>
            Agregar Disciplina
          </button>
        </div>
      }
    } @loading (minimum 1000ms) {
      <div class="flex flex-col justify-center items-center h-full">
        <nz-spin></nz-spin>
        <p>Cargando...</p>
      </div>
    }
  </div>
</ng-container>
