import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { InscriptionsService } from 'src/app/@core/services/inscriptions.service';
import { Inscription } from '../../@core/models/inscription.interface';
import { InscriptionFormComponent } from './components/inscription-form/inscription-form.component';
{
}
@Component({
  selector: 'app-inscriptions',
  templateUrl: './inscriptions.component.html',
  styleUrls: ['./inscriptions.component.scss'],
})
export class InscriptionsComponent implements OnInit {
  inscriptions$: Observable<Inscription[]>;
  selectedInscription$ = new BehaviorSubject<Inscription>(null);
  isCreateModalVisible = false;
  currentView: 'manage' | 'manual' | null = 'manage';
  searchQuery: string = '';

  constructor(
    private message: NzMessageService,
    private inscriptionService: InscriptionsService,
    private modal: NzModalService
  ) {
    this.inscriptions$ = this.inscriptionService.getInscriptions();
  }

  ngOnInit(): void {}

  selectInscription(inscription: Inscription): void {
    this.selectedInscription$.next(inscription);
  }

  editInscription(inscription: Inscription): void {
    this.showCreateInscription(inscription, 'edit');
  }

  showCreateInscription(
    inscription?: any,
    option: 'create' | 'edit' = 'create'
  ): void {
    this.modal.create<
      InscriptionFormComponent,
      {
        inscription: any;
        option: string;
      }
    >({
      nzTitle: option === 'create' ? 'Crear Inscripción' : 'Editar Inscripción',
      nzContent: InscriptionFormComponent,
      nzData: {
        inscription,
        option,
      },
      nzWidth: '1000px',

      nzFooter: null,
      nzOkText: option === 'create' ? 'Crear' : 'Editar',
      nzCancelText: 'Cancelar',
    });
  }

  deleteInscription(inscriptionID: string) {
    return this.modal.confirm({
      nzTitle: '¿Estás seguro?',
      nzContent: '¿Estás seguro de querer eliminar esta inscripción?',
      nzOkText: 'Eliminar',
      nzCancelText: 'Cancelar',
      nzOnOk: () => this.inscriptionService.deleteInscription(inscriptionID),
    });
  }
}
