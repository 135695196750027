import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AlbumCardComponent } from './album-card.component';

@NgModule({
  imports: [CommonModule, NzIconModule, NzToolTipModule],
  declarations: [AlbumCardComponent],
  exports: [AlbumCardComponent],
})
export class AlbumCardModule {}
