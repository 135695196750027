import { Component, Input, OnInit } from '@angular/core';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { NzImageService } from 'ng-zorro-antd/image';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NgxMasonryOptions } from 'ngx-masonry';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  finalize,
  map,
  switchMap,
  take,
} from 'rxjs';
import {
  Album,
  GalleryService,
  Photo,
} from 'src/app/@core/services/gallery.service';
import { AlbumPhotosUploadComponent } from '../album-photos-upload/album-photos-upload.component';
import { ImageDetailModalComponent } from './image-detail-modal/image-detail-modal.component';
import { TopTenModalComponent } from './top-ten-modal/top-ten-modal.component';
type SortOption =
  | 'date-desc'
  | 'date-asc'
  | 'votes-desc'
  | 'votes-asc'
  | 'approved-desc'
  | 'approved-asc';

@Component({
  selector: 'app-album-content',
  templateUrl: './album-content.component.html',
  styleUrls: ['./album-content.component.css'],
})
export class AlbumContentComponent implements OnInit {
  @Input() set data(album: Album) {
    this.albumData$.next(album);
    this.albumId$.next(album.id);
  }

  albumId$ = new BehaviorSubject<string>('');
  albumData$ = new BehaviorSubject<Album>(null);
  currentSort$ = new BehaviorSubject<SortOption>('date-desc');
  selectedCategory$ = new BehaviorSubject<string>('all');
  showTopTen$ = new BehaviorSubject<boolean>(false);
  isApprovingPhoto$ = new BehaviorSubject<boolean>(false);
  isDeletingPhoto$ = new BehaviorSubject<boolean>(false);

  // Observable for all available categories
  categories$: Observable<string[]>;

  // Updated photos$ to include category filtering
  photos$ = combineLatest([
    this.albumId$.pipe(switchMap(id => this.galleryService.getAlbumPhotos(id))),
    this.currentSort$,
    this.selectedCategory$,
  ]).pipe(
    map(([photos, sortOption, selectedCategory]) => {
      // First filter by category if not 'all'
      let filteredPhotos = photos;
      if (selectedCategory !== 'all') {
        filteredPhotos = photos.filter(
          photo => photo.category && photo.category.includes(selectedCategory)
        );
      }

      // Then sort the filtered photos
      return this.sortPhotos(filteredPhotos, sortOption);
    })
  );

  // Top 10 photos observable - sorted by votes
  topTenPhotos$: Observable<Photo[]> = this.albumId$.pipe(
    switchMap(id => this.galleryService.getAlbumPhotos(id)),
    map(photos => {
      if (!photos) return [];
      // Sort by votes (highest first) and take top 10
      return [...photos]
        .sort((a, b) => b.votes - a.votes)
        .filter(photo => photo.approved) // Only approved photos
        .slice(0, 10);
    })
  );

  masonryOptions: NgxMasonryOptions = {
    gutter: 20,
    percentPosition: false,
    columnWidth: 300,
    itemSelector: '.grid-item',
    horizontalOrder: true,
    resize: true,
    fitWidth: true,
    originLeft: true,
    originTop: true,
  };

  sortOptions = [
    { label: 'Más recientes primero', value: 'date-desc' },
    { label: 'Más antiguos primero', value: 'date-asc' },
    { label: 'Más votados primero', value: 'votes-desc' },
    { label: 'Menos votados primero', value: 'votes-asc' },
    { label: 'Aprobados primero', value: 'approved-desc' },
    { label: 'No aprobados primero', value: 'approved-asc' },
  ];

  constructor(
    private galleryService: GalleryService,
    private modal: NzModalService,
    private nzImageService: NzImageService
  ) {}

  ngOnInit() {
    // Initialize the categories$ observable
    this.categories$ = this.albumId$.pipe(
      switchMap(id => this.galleryService.getAlbumPhotos(id)),
      map(photos => {
        // Extract all categories from photos
        const categoriesSet = new Set<string>();

        // Add 'all' as the first option
        categoriesSet.add('all');

        // Extract categories from each photo
        photos.forEach(photo => {
          if (photo.category && photo.category.length > 0) {
            categoriesSet.add(photo.category);
          }
        });

        // Convert Set to Array and sort alphabetically
        return Array.from(categoriesSet).sort((a, b) => {
          // Keep 'all' at the beginning
          if (a === 'all') return -1;
          if (b === 'all') return 1;
          return a.localeCompare(b);
        });
      })
    );
  }

  onSortChange(value: SortOption) {
    this.currentSort$.next(value);
  }

  private sortPhotos(photos: Photo[], sortOption: SortOption): Photo[] {
    if (!photos) return [];

    const sorted = [...photos];

    switch (sortOption) {
      case 'date-desc':
        return sorted.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
      case 'date-asc':
        return sorted.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
      case 'votes-desc':
        return sorted.sort((a, b) => b.votes - a.votes);
      case 'votes-asc':
        return sorted.sort((a, b) => a.votes - b.votes);
      case 'approved-desc':
        return sorted.sort((a, b) => {
          if (a.approved && !b.approved) return -1;
          if (!a.approved && b.approved) return 1;
          return 0;
        });
      case 'approved-asc':
        return sorted.sort((a, b) => {
          if (a.approved && !b.approved) return 1;
          if (!a.approved && b.approved) return -1;
          return 0;
        });
      default:
        return sorted;
    }
  }

  showPreview(currentPhoto: Photo, albumData: Album): void {
    this.modal.create({
      nzTitle: 'Detalle de la foto',
      nzContent: ImageDetailModalComponent,
      nzWidth: '80%',
      nzBodyStyle: { padding: '0' },
      nzFooter: null,
      nzData: {
        photo: currentPhoto,
        albumData,
      },
    });
  }

  /**
   * Muestra un lightbox con la imagen seleccionada y permite navegar entre todas las imágenes del álbum
   * @param currentPhoto La foto actual que se va a mostrar
   */
  showLightbox(currentPhoto: Photo): void {
    this.photos$.pipe(take(1)).subscribe(photos => {
      if (!photos || photos.length === 0) return;

      // Crear un array de imágenes para el lightbox
      const imageList = photos.map(photo => ({
        src: photo.url,
        alt: photo.title || 'Imagen',
        width: 'auto',

        title: this.formatPhotoTitle(photo),
      }));

      // Encontrar el índice de la foto actual
      const currentIndex = photos.findIndex(
        photo => photo.id === currentPhoto.id
      );

      // Mostrar el lightbox con la configuración deseada
      const imageService = this.nzImageService.preview(imageList, {
        nzZoom: 0.5,
      });

      // Establecer la imagen actual después de que el lightbox se haya abierto
      if (currentIndex > -1) {
        setTimeout(() => {
          imageService.switchTo(currentIndex);
        }, 100);
      }
    });
  }

  /**
   * Formatea el título de la foto para mostrar en el lightbox
   * @param photo La foto a formatear
   * @returns El título formateado con información adicional
   */
  private formatPhotoTitle(photo: Photo): string {
    const title = photo.title?.split('.')[0] || 'Sin título';
    const votes = photo.votes > 0 ? `${photo.votes} votos` : 'Sin votos';
    const submitter = photo.submittedBy
      ? `Por: ${photo.submittedBy.fname}`
      : '';
    const date = photo.createdAt
      ? new Date(photo.createdAt.seconds * 1000).toLocaleDateString()
      : '';

    return `${title} ${submitter ? '| ' + submitter : ''} ${date ? '| ' + date : ''} ${votes ? '| ' + votes : ''}`;
  }

  deletePhoto(photo: Photo, albumData: Album): void {
    this.modal.confirm({
      nzTitle: 'Eliminar foto',
      nzContent: '¿Estás seguro de querer eliminar esta foto?',
      nzOkText: 'Eliminar',
      nzCancelText: 'Cancelar',
      nzOnOk: () => {
        this.isDeletingPhoto$.next(true);
        this.galleryService
          .deletePhoto(albumData.id, photo.id)
          .pipe(finalize(() => this.isDeletingPhoto$.next(false)))
          .subscribe();
      },
    });
  }

  approvePhoto(photo: Photo, albumData: Album): void {
    this.isApprovingPhoto$.next(true);
    this.galleryService
      .updatePhoto(albumData.id, photo.id, {
        ...photo,
        approved: true,
      })
      .pipe(finalize(() => this.isApprovingPhoto$.next(false)))
      .subscribe();
  }

  downloadPhoto(photo: Photo): void {
    const link = document.createElement('a');
    link.href = photo.url;
    link.target = '_blank';
    link.download = photo.title;
    link.click();
    link.remove();
  }

  onAddPhotos(album: Album): void {
    const modal = this.modal.create({
      nzTitle: 'Agregar Fotos',
      nzContent: AlbumPhotosUploadComponent,
      nzWidth: '80%',
      nzFooter: null,
      nzComponentParams: {
        albumId: album.id,
      },
    });

    modal.afterClose.subscribe(result => {
      console.log(result);
    });
  }

  // Show Top 10 modal
  showTopTen(albumData: Album, photos: Photo[]): void {
    // Create a modal with the top 10 photos
    this.modal.create({
      nzTitle: 'Top 10 Fotos Más Votadas',
      nzContent: TopTenModalComponent,
      nzWidth: '90%',
      nzFooter: null,
      nzBodyStyle: { padding: '24px' },
      nzClassName: 'top-ten-modal',
      nzData: {
        photos: photos,
        albumData: albumData,
      },
    });
  }

  // Method to handle category change
  onCategoryChange(category: string): void {
    this.selectedCategory$.next(category);
  }

  relativeTime(date: number): string {
    return formatDistanceToNow(date, { addSuffix: true, locale: es });
  }
}
