<ng-container *ngIf="{ saving: saving$ | async } as state">
  <form nz-form [formGroup]="form" class="p-4">
    <!-- Nombre -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Nombre</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Por favor ingrese un nombre válido">
        <input
          nz-input
          formControlName="name"
          placeholder="Nombre de la disciplina" />
      </nz-form-control>
    </nz-form-item>

    <!-- Género -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Género</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Por favor seleccione el género">
        <nz-select
          formControlName="gender"
          nzPlaceHolder="Seleccione el género"
          class="w-full">
          <nz-option
            *ngFor="let option of genderOptions"
            [nzValue]="option.value"
            [nzLabel]="option.label">
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Capacidad -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Capacidad</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Por favor ingrese una capacidad válida">
        <input nz-input formControlName="capacity" placeholder="Capacidad" />
      </nz-form-control>
    </nz-form-item>

    <nz-slider nzRange></nz-slider>

    <!-- Edad mínima -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Edad mínima</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Por favor ingrese una edad mínima válida">
        <input nz-input formControlName="minAge" placeholder="Edad mínima" />
      </nz-form-control>
    </nz-form-item>

    <!-- Edad máxima -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Edad máxima</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Por favor ingrese una edad máxima válida">
        <input nz-input formControlName="maxAge" placeholder="Edad máxima" />
      </nz-form-control>
    </nz-form-item>

    <!-- Coach -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Entrenador</nz-form-label>
      <nz-form-control [nzSpan]="24" nzErrorTip="Por favor seleccione el coach">
        <input nz-input formControlName="coach" placeholder="Coach" />
      </nz-form-control>
    </nz-form-item>

    <!-- Coordinator -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Coordinador</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Por favor seleccione el coordinador">
        <input
          nz-input
          formControlName="coordinator"
          placeholder="Coordinador" />
      </nz-form-control>
    </nz-form-item>

    <!-- Botones -->
    <div class="flex justify-end gap-2 mt-4">
      <button nz-button type="button" (click)="onCancel()">Cancelar</button>
      <button
        nz-button
        nzType="primary"
        (click)="onSubmit()"
        [nzLoading]="state.saving"
        [disabled]="form.invalid">
        {{
          data?.option === 'create'
            ? state.saving
              ? 'Creando...'
              : 'Crear'
            : state.saving
              ? 'Actualizando...'
              : 'Actualizar'
        }}
      </button>
    </div>
  </form>
</ng-container>
