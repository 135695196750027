import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AlbumCardModule } from './components/album-card/album-card.module';
import { AlbumContentModule } from './components/album-content/album-content.module';
import { AlbumFormModule } from './components/album-form/album-form.module';
import { AlbumPhotosUploadModule } from './components/album-photos-upload/album-photos-upload.module';
import { GalleryRoutingModule } from './gallery-routing.module';
import { GalleryComponent } from './gallery.component';

@NgModule({
  imports: [
    CommonModule,
    GalleryRoutingModule,
    NzIconModule,
    NzImageModule,
    NzToolTipModule,
    AlbumCardModule,
    AlbumPhotosUploadModule,
    AlbumFormModule,
    AlbumContentModule,
  ],
  declarations: [GalleryComponent],
})
export class GalleryModule {}
