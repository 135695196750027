<div class="inscriptions-container">
  <ng-container
    *ngIf="{
      inscriptions: inscriptions$ | async,
      selectedInscription: selectedInscription$ | async
    } as state">
    <div nz-row [nzGutter]="[16, 16]" class="h-full">
      <!-- Panel Izquierdo - Lista de Inscripciones -->
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="6">
        <nz-card
          class="h-full shadow-sm"
          [nzTitle]="listHeader"
          [nzBordered]="false">
          <ng-template #listHeader>
            <div class="flex justify-between items-center">
              <div>
                <h2 class="text-lg font-semibold m-0">Inscripciones</h2>
                <p class="text-sm text-gray-500">
                  {{ state.selectedInscription?.name }}
                </p>
              </div>
              <div class="flex gap-2">
                <button
                  nz-button
                  nzType="default"
                  nzShape="circle"
                  class="flex items-center justify-center"
                  nz-tooltip
                  nzTooltipTitle="Eliminar Inscripción"
                  *ngIf="state.selectedInscription"
                  (click)="deleteInscription(state.selectedInscription.id)">
                  <span nz-icon nzType="delete" class="text-red-500"></span>
                </button>
                <button
                  nz-button
                  nzType="default"
                  nzShape="circle"
                  class="flex items-center justify-center"
                  nz-tooltip
                  nzTooltipTitle="Editar Inscripción"
                  *ngIf="state.selectedInscription"
                  (click)="editInscription(state.selectedInscription)">
                  <span nz-icon nzType="edit" class="text-blue-500"></span>
                </button>
                <button
                  nz-button
                  nzType="primary"
                  class="flex items-center"
                  (click)="showCreateInscription('create')">
                  <span nz-icon nzType="plus"></span>
                  Nueva
                </button>
              </div>
            </div>
          </ng-template>

          <!-- Barra de búsqueda -->
          <div class="mb-4">
            <nz-input-group [nzPrefix]="searchPrefix">
              <input
                nz-input
                type="text"
                placeholder="Buscar inscripción..."
                [(ngModel)]="searchQuery" />
            </nz-input-group>
            <ng-template #searchPrefix>
              <span nz-icon nzType="search" class="text-gray-400"></span>
            </ng-template>
          </div>

          <!-- Lista de Inscripciones -->
          <div
            class="inscription-list overflow-auto"
            style="height: calc(100vh - 250px)">
            <nz-list
              [nzDataSource]="state.inscriptions | filter: 'name' : searchQuery"
              [nzRenderItem]="inscriptionItem"
              [nzLoading]="!state.inscriptions">
              <ng-template #inscriptionItem let-inscription>
                <nz-card
                  class="mb-3 cursor-pointer transition-all duration-200 hover:shadow-lg border-l-4 overflow-hidden"
                  [class.border-l-primary]="
                    inscription.id === state.selectedInscription?.id
                  "
                  [class.border-l-gray-300]="
                    inscription.id !== state.selectedInscription?.id
                  "
                  [nzBordered]="true"
                  (click)="selectInscription(inscription)">
                  <div class="flex flex-col">
                    <!-- Encabezado con título - Siempre visible -->
                    <div class="flex items-center justify-between">
                      <h4 class="text-lg font-semibold text-gray-800 m-0">
                        {{ inscription.name | uppercase }}
                      </h4>
                      <span
                        nz-icon
                        [nzType]="
                          inscription.id === state.selectedInscription?.id
                            ? 'down-circle'
                            : 'right-circle'
                        "
                        [class.text-primary]="
                          inscription.id === state.selectedInscription?.id
                        "
                        class="text-lg transition-all duration-200">
                      </span>
                    </div>

                    <!-- Contenido expandible -->
                    <div
                      class="grid grid-cols-1 gap-2 overflow-hidden transition-all duration-300"
                      [class.h-0]="
                        inscription.id !== state.selectedInscription?.id
                      "
                      [class.opacity-0]="
                        inscription.id !== state.selectedInscription?.id
                      "
                      [class.h-auto]="
                        inscription.id === state.selectedInscription?.id
                      "
                      [class.opacity-100]="
                        inscription.id === state.selectedInscription?.id
                      "
                      [class.mt-0]="
                        inscription.id !== state.selectedInscription?.id
                      "
                      [class.mt-3]="
                        inscription.id === state.selectedInscription?.id
                      ">
                      <!-- Fecha de Publicación -->
                      <div
                        class="flex items-center gap-2 bg-blue-50 p-2 rounded-lg">
                        <span
                          nz-icon
                          nzType="calendar"
                          class="text-blue-500"></span>
                        <div class="flex flex-col">
                          <span class="text-xs text-gray-500">Publicación</span>
                          <span class="text-sm font-medium text-blue-700">
                            {{
                              inscription.publishDate.seconds * 1000
                                | date: 'dd MMM, yyyy'
                            }}
                          </span>
                        </div>
                      </div>

                      <!-- Fecha Límite -->
                      <div
                        class="flex items-center gap-2 bg-red-50 p-2 rounded-lg">
                        <span
                          nz-icon
                          nzType="clock-circle"
                          class="text-red-500"></span>
                        <div class="flex flex-col">
                          <span class="text-xs text-gray-500"
                            >Fecha Límite</span
                          >
                          <span class="text-sm font-medium text-red-700">
                            {{
                              inscription.deadlineDate.seconds * 1000
                                | date: 'dd MMM, yyyy'
                            }}
                          </span>
                        </div>
                      </div>

                      <!-- Capacidad -->
                      <div
                        class="flex items-center gap-2 bg-green-50 p-2 rounded-lg">
                        <span
                          nz-icon
                          nzType="team"
                          class="text-green-500"></span>
                        <div class="flex flex-col">
                          <span class="text-xs text-gray-500">Capacidad</span>
                          <span class="text-sm font-medium text-green-700">
                            {{ inscription.capacity }} participantes
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </nz-card>
              </ng-template>
            </nz-list>
          </div>
        </nz-card>
      </div>

      <!-- Panel Derecho - Detalles -->
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="18">
        <nz-card
          *ngIf="state.selectedInscription; else noSelection"
          class="h-full shadow-sm"
          [nzBordered]="false">
          <app-manage-inscriptions [inscription]="state.selectedInscription">
          </app-manage-inscriptions>
        </nz-card>

        <ng-template #noSelection>
          <div
            class="h-full flex items-center justify-center bg-white rounded-lg shadow-sm">
            <div class="text-center p-8">
              <span
                nz-icon
                nzType="file-text"
                nzTheme="outline"
                class="text-6xl text-gray-300 mb-4">
              </span>
              <h3 class="text-xl font-medium text-gray-600 mb-2">
                {{
                  state.inscriptions?.length
                    ? 'Selecciona una inscripción'
                    : 'No hay inscripciones'
                }}
              </h3>
              <p class="text-gray-500 mb-4">
                {{
                  state.inscriptions?.length
                    ? 'Haz clic en una inscripción para ver sus detalles'
                    : 'Comienza creando una nueva inscripción'
                }}
              </p>
              <button
                nz-button
                nzType="primary"
                (click)="showCreateInscription('create')">
                <span nz-icon nzType="plus"></span>
                Crear Inscripción
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>
</div>
