// table-form.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { finalize } from 'rxjs/operators';
import { PoolService } from 'src/app/@core/services/pool.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrls: ['./table-form.component.scss'],
})
export class TableFormComponent implements OnInit {
  tableForm: FormGroup;
  tableTypes = ['Gazebo', 'Mesa', 'Silla', 'Sofa', 'Cama', 'Otro'];
  imageUrl: string | undefined;
  loading = false;
  isEditing = false;
  resourceId: string | null = null;

  @Input() set data(tableInput: any) {
    if (tableInput && tableInput.table) {
      const table = {
        title: tableInput.table.title,
        ...tableInput.table.extendedProps,
      };

      this.isEditing = true;
      this.resourceId = tableInput.table.extendedProps.resourceId;
      this.initForm();
      this.tableForm.patchValue(table);
      this.imageUrl = table.tablePhoto;
    } else {
      this.isEditing = false;
      this.resourceId = null;
      this.initForm();
    }
  }

  constructor(
    private fb: FormBuilder,
    private poolService: PoolService,
    private message: NzMessageService,
    private drawerRef: NzDrawerRef,
    private storage: AngularFireStorage
  ) {}

  ngOnInit(): void {
    console.log(this.isEditing, 'this.isEditing');
    if (!this.isEditing) {
      this.initForm();
    }
  }

  initForm(): void {
    this.tableForm = this.fb.group({
      title: [null, [Validators.required]],
      tableNumber: [null, [Validators.required]],
      tableType: [null, [Validators.required]],
      tableCapacity: [null, [Validators.required, Validators.min(1)]],
      tablePhoto: [null, [Validators.required]],
      tableLocation: [null, [Validators.required]],
    });

    if (!this.isEditing) {
      this.poolService.getNextResourceNumber().subscribe(number => {
        this.tableForm.patchValue({ tableNumber: number });
        this.tableForm.patchValue({ title: `Mesa ${number}` });
      });
    }
  }
  beforeUpload = (file: NzUploadFile): boolean => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.message.error('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = file.size! / 1024 / 1024 < 2;
    if (!isLt2M) {
      this.message.error('Image must smaller than 2MB!');
      return false;
    }
    this.uploadFile(file);
    return false;
  };

  uploadFile(file: NzUploadFile): void {
    this.loading = true;
    const filePath = `pool/tables/${Date.now()}_${file.name}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(url => {
            this.loading = false;
            this.imageUrl = url;
            this.tableForm.patchValue({ tablePhoto: url });
          });
        })
      )
      .subscribe();
  }

  submitForm(): void {
    if (this.tableForm.valid) {
      const formData = this.tableForm.value;

      console.log(
        { editing: this.isEditing, id: this.resourceId },
        'en el formmm'
      );
      if (this.isEditing && this.resourceId) {
        this.poolService
          .updateResource(this.resourceId, formData)
          .then(() => {
            this.message.success('Mesa actualizada con éxito.');
          })
          .catch(error => {
            this.message.error('Error al actualizar la mesa');
          });
      } else {
        this.poolService
          .createResource(formData)
          .then(docRef => {
            this.message.success('Mesa creada con éxito.');
            this.resetForm();
          })
          .catch(error => {
            this.message.error('Error al crear la mesa');
          });
      }

      Object.values(this.tableForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  resetForm(): void {
    this.tableForm.reset();
    this.imageUrl = undefined;
    this.isEditing = false;
    this.resourceId = null;
    this.initForm();
  }

  deleteTable() {
    this.poolService.deleteTable(this.resourceId);
    this.drawerRef.close();
  }
}
