<ng-container
  *ngIf="{
    saving: saving$ | async
  } as state">
  <form [formGroup]="form" class="p-4">
    <nz-tabset [nzTabPosition]="'left'">
      <nz-tab nzTitle="General">
        <!-- Nombre -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired>Nombre</nz-form-label>
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Por favor ingrese un nombre válido">
            <input
              nz-input
              formControlName="name"
              placeholder="Nombre de la inscripción" />
          </nz-form-control>
        </nz-form-item>
      </nz-tab>
      <nz-tab nzTitle="Costo">
        <!-- Precio -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired>Precio</nz-form-label>
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Por favor ingrese un precio válido">
            <nz-input-number
              class="w-full"
              formControlName="price"
              [nzMin]="0"
              [nzStep]="1">
            </nz-input-number>
          </nz-form-control>
        </nz-form-item>
        <!-- Requiere Captura -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired
            >Requiere Captura</nz-form-label
          >
          <nz-form-control [nzSpan]="24">
            <nz-switch
              formControlName="requireScreenshot"
              [nzCheckedChildren]="'Si'"
              [nzUnCheckedChildren]="'No'">
            </nz-switch>
          </nz-form-control>
        </nz-form-item>
      </nz-tab>
      <nz-tab nzTitle="Disciplinas" [nzDisabled]="true">
        <nz-alert
          nzType="info"
          nzShowIcon
          nzMessage="
            Cree las disciplinas que desea incluir en la inscripción. estas seran las que se mostraran en el formulario de inscripción. cada disciplina se puede seleccionar una sola vez.
        ">
        </nz-alert>

        <!-- Disciplinas -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired>
            Disciplinas
            <button nz-button nzType="link" (click)="addDiscipline()">
              <span nz-icon nzType="plus"></span>
              Agregar
            </button>
          </nz-form-label>
          <nz-form-control [nzSpan]="24">
            <div formArrayName="disciplines">
              <div
                *ngFor="let discipline of disciplines.controls; let i = index"
                class="mb-2">
                <div class="flex gap-2">
                  <input
                    nz-input
                    [formControlName]="i"
                    placeholder="Nombre de la disciplina" />
                  <button
                    nz-button
                    nzType="text"
                    nzDanger
                    (click)="removeDiscipline(i)"
                    [disabled]="disciplines.length === 1">
                    <span nz-icon nzType="delete"></span>
                  </button>
                </div>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>
      </nz-tab>
      <nz-tab nzTitle="Capacidad">
        <nz-alert
          nzType="info"
          nzShowIcon
          nzMessage="
          La capacidad máxima de inscripciones de forma global para esta inscripción. una vez
          que se completen los cupos no se aceptaran más inscripciones. sin importar la disciplina.
        ">
        </nz-alert>
        <!-- Capacidad -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired>Capacidad</nz-form-label>
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Por favor ingrese una capacidad válida">
            <nz-input-number
              class="w-full"
              formControlName="capacity"
              [nzMin]="1"
              [nzStep]="1">
            </nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </nz-tab>
      <nz-tab nzTitle="Fechas">
        <nz-alert
          nzType="info"
          nzShowIcon
          nzMessage="
          La fecha de publicación es la fecha en la que se publicara la inscripción en el sistema y sera visible para los usuarios desde la aplicación.
          La fecha limite es la fecha en la que se cerrara la inscripción y no sera visible para los usuarios desde la aplicación y no se aceptaran más inscripciones, sin embargo se podran realizar incripciones manuales segun la capacidad de la inscripción.
        ">
        </nz-alert>
        <!-- Fecha Límite -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired>Fecha Límite</nz-form-label>
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Por favor seleccione una fecha límite">
            <nz-date-picker
              class="w-full"
              formControlName="deadlineDate"
              [nzFormat]="'dd/MM/yyyy'">
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>

        <!-- Fecha de Publicación -->
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired
            >Fecha de Publicación</nz-form-label
          >
          <nz-form-control
            [nzSpan]="24"
            nzErrorTip="Por favor seleccione una fecha de publicación">
            <nz-date-picker
              class="w-full"
              formControlName="publishDate"
              [nzFormat]="'dd/MM/yyyy'">
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </nz-tab>
      <nz-tab nzTitle="Imagen">
        <nz-alert
          nzType="info"
          nzShowIcon
          nzMessage="
          Imagen sera mostrada a los usuarios en la aplicación para que puedan identificar la inscripción.
      ">
        </nz-alert>
        <!-- Foto -->
        <nz-form-item>
          <nz-form-label>Imagen</nz-form-label>
          <nz-form-control>
            <nz-upload
              [nzAccept]="'image/*'"
              [nzCustomRequest]="customUploadReq"
              [nzBeforeUpload]="beforeUpload"
              (nzChange)="handleChange($event)"
              [nzShowUploadList]="false"
              [nzMultiple]="false"
              [nzDisabled]="uploadLoading">
              <button nz-button type="button" [nzLoading]="uploadLoading">
                <span nz-icon nzType="upload"></span>
                Subir Imagen
              </button>
            </nz-upload>
            <img *ngIf="imageUrl" [src]="imageUrl" class="img-preview mt-2" />
          </nz-form-control>
        </nz-form-item>
      </nz-tab>
    </nz-tabset>

    <!-- Botones -->
    <div class="flex justify-end gap-2 mt-4">
      <button nz-button type="button" (click)="onCancel()">Cancelar</button>
      <button
        nz-button
        (click)="onSubmit()"
        nzType="primary"
        [nzLoading]="state.saving"
        [disabled]="form.invalid">
        {{
          data.option === 'create'
            ? state.saving
              ? 'Creando...'
              : 'Crear'
            : state.saving
              ? 'Actualizando...'
              : 'Actualizar'
        }}
      </button>
    </div>
  </form>
</ng-container>
