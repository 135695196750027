<ng-container
  *ngIf="{
    config: config$ | async,
    disciplines: disciplines$ | async
  } as state">
  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="p-4"
    autocomplete="off">
    <!-- Tipo de Inscripción -->

    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired
        >Tipo de Inscripción</nz-form-label
      >
      <nz-form-control [nzSpan]="24">
        <nz-select formControlName="participantType" class="w-full">
          <nz-option nzValue="socio" nzLabel="Socio"></nz-option>
          <nz-option nzValue="invitado" nzLabel="Invitado"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Formulario para Socios -->
    <ng-container *ngIf="form.get('participantType')?.value === 'socio'">
      <nz-form-item *ngIf="!selectedShareHolder">
        <nz-form-label [nzSpan]="24" nzRequired
          >Seleccionar Socio</nz-form-label
        >
        <nz-form-control [nzSpan]="24">
          <app-share-holder-picker
            (selected)="onShareHolderSelected($event)"
            [config]="{
              showFilter: false,
              showSolvencyBanner: false,
              showOther: false,
              fullUserObject: true,
              buttonText: 'Seleccionar',
              bannerText: [
                'No se encontraron resultados',
                'No se encontraron resultados'
              ]
            }"></app-share-holder-picker>
        </nz-form-control>
      </nz-form-item>

      <!-- Tarjeta de información del socio -->
      <ng-container *ngIf="selectedShareHolder">
        <nz-card
          class="mb-4"
          [nzTitle]="userCardName"
          [nzExtra]="userCardActions">
          <div nz-row nzGutter="16">
            <div nz-col [nzSpan]="8">
              <img
                [src]="
                  selectedShareHolder.profilePhoto || 'assets/img/cdi-logo.png'
                "
                alt="Foto de perfil"
                class="size-20 rounded-full mx-auto" />
            </div>
            <div nz-col [nzSpan]="16">
              <div class="flex justify-between">
                <span class="font-medium">Email:</span>
                <span>{{ selectedShareHolder.email }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-medium">Teléfono:</span>
                <span>{{
                  selectedShareHolder?.phoneNumber?.nationalNumber ||
                    'No registrado'
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-medium">Cédula:</span>
                <span>{{ selectedShareHolder.cid || 'No registrado' }}</span>
              </div>
            </div>
          </div>
        </nz-card>

        <ng-template #userCardName>
          <span
            >{{ selectedShareHolder.fname }}
            {{ selectedShareHolder.lname }}</span
          >
        </ng-template>
        <ng-template #userCardActions>
          <div>
            <button
              nz-button
              nzType="link"
              (click)="selectedShareHolder = null">
              <i nz-icon nzType="delete" nzTheme="outline"></i>
            </button>
          </div>
        </ng-template>

        <!-- Campo de teléfono si no existe -->
        <ng-container *ngIf="!selectedShareHolder.phoneNumber">
          <nz-form-item class="mb-2">
            <nz-form-label>Teléfono</nz-form-label>
            <nz-form-control>
              <ngx-intl-tel-input
                nz-re
                cssClass="ant-input w-full"
                [preferredCountries]="preferredCountries"
                [searchCountryPlaceholder]="'Busca por pais'"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [separateDialCode]="true"
                [searchCountryField]="[
                  SearchCountryField.Iso2,
                  SearchCountryField.Name
                ]"
                [selectFirstCountry]="false"
                [selectedCountryISO]="CountryISO.Venezuela"
                [maxLength]="15"
                [phoneValidation]="true"
                [numberFormat]="PhoneNumberFormat.International"
                formControlName="phoneNumber">
              </ngx-intl-tel-input>
            </nz-form-control>
          </nz-form-item>
        </ng-container>

        <!-- Campo de cédula si no existe -->
        <ng-container *ngIf="!selectedShareHolder.cid">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzRequired>Cédula</nz-form-label>
            <nz-form-control
              [nzSpan]="24"
              nzErrorTip="Por favor ingrese un número de cédula válido">
              <input
                imask
                mask="00000000"
                nz-input
                class="w-full"
                formControlName="cid"
                placeholder="Número de cédula" />
            </nz-form-control>
          </nz-form-item>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Formulario para Invitados -->
    <ng-container *ngIf="form.get('participantType')?.value === 'invitado'">
      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Nombre Completo</nz-form-label>
        <nz-form-control
          [nzSpan]="24"
          nzErrorTip="Por favor ingrese el nombre completo">
          <input
            nz-input
            formControlName="fullName"
            placeholder="Nombre completo del invitado" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Email</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Por favor ingrese el email">
          <input
            nz-input
            formControlName="email"
            placeholder="Email del invitado" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Cédula</nz-form-label>
        <nz-form-control
          [nzSpan]="24"
          nzErrorTip="Por favor ingrese un número de cédula válido">
          <input
            imask
            mask="00000000"
            nz-input
            class="w-full"
            formControlName="cid"
            placeholder="Número de cédula" />
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-2">
        <nz-form-label>Teléfono</nz-form-label> <br />
        <nz-form-control>
          <ngx-intl-tel-input
            cssClass="ant-input w-full"
            [preferredCountries]="preferredCountries"
            [searchCountryPlaceholder]="'Busca por pais'"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [searchCountryFlag]="true"
            [separateDialCode]="true"
            [searchCountryField]="[
              SearchCountryField.Iso2,
              SearchCountryField.Name
            ]"
            [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Venezuela"
            [maxLength]="15"
            [phoneValidation]="true"
            [numberFormat]="PhoneNumberFormat.International"
            formControlName="phoneNumber">
          </ngx-intl-tel-input>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="24" nzRequired>Acción</nz-form-label>
        <nz-form-control [nzSpan]="24" nzErrorTip="Por favor ingrese la acción">
          <nz-input-group [nzAddOnBefore]="'CDC-'">
            <input
              nz-input
              formControlName="shareNumber"
              placeholder="Número de acción" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </ng-container>

    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Edad</nz-form-label>
      <nz-form-control [nzSpan]="24">
        <input
          imask
          mask="00"
          nz-input
          formControlName="age"
          placeholder="Edad" />
      </nz-form-control>
    </nz-form-item>
    <!-- Disciplinas (común para ambos tipos) -->
    <nz-form-item>
      <nz-form-label [nzSpan]="24" nzRequired>Disciplinas</nz-form-label>
      <nz-form-control
        [nzSpan]="24"
        nzErrorTip="Por favor seleccione al menos una disciplina">
        <nz-select
          formControlName="disciplines"
          [nzMode]="'multiple'"
          [nzPlaceHolder]="'Seleccione las disciplinas'"
          class="w-full">
          <nz-option
            *ngFor="let discipline of state.disciplines"
            [nzValue]="discipline.id"
            [nzLabel]="discipline.name | uppercase">
          </nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <!-- Botones -->
    <div class="flex justify-end gap-2 mt-4">
      <button nz-button (click)="onCancel()">Limpiar Formulario</button>
      <button nz-button nzType="primary" (click)="modal.close()">Cerrar</button>
      <button
        nz-button
        nzType="primary"
        type="submit"
        [nzLoading]="saving()"
        [disabled]="form.invalid || !isValidForm()">
        Inscribir
      </button>
    </div>
  </form>
</ng-container>
