<!-- table-form.component.html -->
<form
  nz-form
  [formGroup]="tableForm"
  (ngSubmit)="submitForm()"
  nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzRequired>Numero de Mesa:</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Please input the table number!">
      <input nz-input formControlName="title" readonly />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Tipo de Mesa</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Seleccione el tipo de Mesa">
      <nz-select formControlName="tableType">
        <nz-option
          *ngFor="let type of tableTypes"
          [nzValue]="type"
          [nzLabel]="type"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Capacidad de la Mesa</nz-form-label>
    <nz-form-control
      [nzSpan]="24"
      nzErrorTip="ingrese capacidad Máxima de la mesa!">
      <nz-input-number
        class="w-full"
        formControlName="tableCapacity"
        [nzMin]="1"></nz-input-number>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Foto de la Mesa</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Debe subir una foto de la Mesa">
      <nz-upload [nzBeforeUpload]="beforeUpload" [nzShowUploadList]="false">
        <button nz-button>
          <i nz-icon nzType="upload"></i>
          Subir aquí
        </button>
      </nz-upload>
      <img
        *ngIf="imageUrl"
        [src]="imageUrl"
        style="max-width: 200px; margin-top: 10px" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Ubicación de la Mesa</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Describa la Ubicaciónde la Mesa">
      <input nz-input formControlName="tableLocation" />
    </nz-form-control>
  </nz-form-item>

  <div class="flex justify-end">
    <button nz-button nzType="primary" [disabled]="!tableForm.valid || loading">
      {{ isEditing ? 'Actualizar Mesa' : 'Crear Mesa' }}
    </button>
    <button
      *ngIf="isEditing"
      nz-button
      nzDanger
      nzType="primary"
      (click)="deleteTable()"
      [disabled]="loading">
      borrar
    </button>
  </div>
</form>
