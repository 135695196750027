<div class="upload-modal">
  <div class="upload-header">
    <h3 class="upload-title">Agregar Fotos</h3>
    <button nz-button nzType="text" class="close-button" (click)="closeModal()">
      <span nz-icon nzType="close"></span>
    </button>
  </div>

  <div class="upload-container">
    <!-- Upload Area -->
    <nz-upload
      [(nzFileList)]="fileList"
      [nzBeforeUpload]="beforeUpload"
      [nzMultiple]="true"
      [nzShowUploadList]="false"
      nzType="drag"
      [nzDisabled]="uploading">
      <ng-container *ngIf="fileList.length === 0">
        <p class="ant-upload-drag-icon">
          <span nz-icon nzType="inbox"></span>
        </p>
        <p class="ant-upload-text">
          Haz clic o arrastra imágenes aquí para subirlas
        </p>
        <p class="ant-upload-hint">Soporta subida múltiple de imágenes</p>
      </ng-container>
      <ng-container *ngIf="fileList.length > 0 && !uploading">
        <p class="ant-upload-text">
          <span nz-icon nzType="plus"></span> Agregar más imágenes
        </p>
      </ng-container>
    </nz-upload>

    <!-- Preview Area -->
    <div class="preview-container" *ngIf="fileList.length > 0">
      <h4 class="preview-title">
        Vista previa ({{ fileList.length }} imágenes)
      </h4>

      <!-- Global Progress -->
      <div class="global-progress" *ngIf="uploading">
        <nz-progress
          [nzPercent]="globalProgress"
          nzStatus="active"
          [nzFormat]="progressFormat">
        </nz-progress>
        <p class="progress-text">
          Subiendo {{ pendingUploads }} de {{ fileList.length }} imágenes
        </p>
      </div>

      <!-- Image Grid -->
      <div class="image-grid">
        <div class="image-item" *ngFor="let file of fileList; let i = index">
          <div class="image-preview">
            <img
              [src]="file.thumbUrl || getImagePreview(file)"
              [alt]="file.name"
              class="preview-img"
              [class.uploading]="file.status === 'uploading'"
              [class.error]="file.status === 'error'"
              [class.done]="file.status === 'done'" />

            <!-- Individual Progress -->
            <nz-progress
              *ngIf="file.status === 'uploading'"
              [nzPercent]="file.percent || 0"
              nzStatus="active"
              class="item-progress">
            </nz-progress>

            <!-- Status Icons -->
            <span class="status-icon" *ngIf="file.status === 'done'">
              <span
                nz-icon
                nzType="check-circle"
                nzTheme="fill"
                class="success-icon"></span>
            </span>
            <span class="status-icon" *ngIf="file.status === 'error'">
              <span
                nz-icon
                nzType="close-circle"
                nzTheme="fill"
                class="error-icon"></span>
            </span>
          </div>

          <div class="image-info">
            <span class="image-name" nz-tooltip [nzTooltipTitle]="file.name">{{
              file.name | truncate: 15
            }}</span>
            <button
              nz-button
              nzType="text"
              nzDanger
              class="delete-button"
              (click)="removeFile(i)"
              [disabled]="uploading">
              <span nz-icon nzType="delete"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Actions -->
    <div class="upload-actions" *ngIf="fileList.length > 0">
      <button
        nz-button
        nzType="default"
        (click)="closeModal()"
        [disabled]="uploading">
        Cancelar
      </button>
      <button
        nz-button
        nzType="primary"
        [nzLoading]="uploading"
        (click)="handleUpload()"
        [disabled]="fileList.length === 0 || uploading">
        {{ uploading ? 'Subiendo...' : 'Iniciar Subida' }}
      </button>
    </div>
  </div>
</div>

<style>
  .upload-modal {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .upload-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
  }

  .upload-title {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
  }

  .upload-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }

  .preview-container {
    margin-top: 24px;
  }

  .preview-title {
    margin-bottom: 16px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }

  .global-progress {
    margin-bottom: 24px;
  }

  .progress-text {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.45);
    text-align: center;
  }

  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    gap: 16px;
    margin-bottom: 24px;
  }

  .image-item {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #f0f0f0;
  }

  .image-preview {
    position: relative;
    height: 120px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .preview-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s;
  }

  .preview-img.uploading {
    opacity: 0.6;
  }

  .preview-img.error {
    opacity: 0.5;
    filter: grayscale(100%);
  }

  .item-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 4px;
  }

  .status-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
  }

  .success-icon {
    color: #52c41a;
  }

  .error-icon {
    color: #f5222d;
  }

  .image-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    background-color: #fff;
  }

  .image-name {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
  }

  .delete-button {
    padding: 0 4px;
    font-size: 14px;
  }

  .upload-actions {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
  }

  :host ::ng-deep .ant-upload.ant-upload-drag {
    height: 200px;
  }

  :host ::ng-deep .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    margin-bottom: 16px;
  }

  :host ::ng-deep .ant-upload.ant-upload-drag .anticon {
    font-size: 48px;
    color: #1890ff;
  }

  :host ::ng-deep nz-progress .ant-progress-text {
    font-size: 12px;
  }
</style>
