import { Component, Input, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { catchError, finalize, forkJoin, Observable, of } from 'rxjs';
import { GalleryService, Photo } from 'src/app/@core/services/gallery.service';
import { UploadService } from 'src/app/@core/services/upload.service';

@Component({
  selector: 'app-album-photos-upload',
  templateUrl: './album-photos-upload.component.html',
  styleUrls: ['./album-photos-upload.component.css'],
})
export class AlbumPhotosUploadComponent implements OnInit {
  @Input() albumId: string;

  fileList: NzUploadFile[] = [];
  uploading = false;
  uploadedPhotos: Photo[] = [];
  pendingUploads = 0;
  globalProgress = 0;
  completedUploads = 0;

  constructor(
    private uploadService: UploadService,
    private nzModalRef: NzModalRef,
    private message: NzMessageService,
    private galleryService: GalleryService
  ) {}

  ngOnInit() {
    if (!this.albumId) {
      this.message.error('No se proporcionó un ID de álbum válido');
      this.closeModal();
    }
  }

  /**
   * Validates file before upload and adds it to the fileList
   */
  beforeUpload = (file: NzUploadFile): boolean => {
    if (!file.type?.startsWith('image/')) {
      this.message.error(`${file.name} no es un archivo de imagen válido`);
      return false;
    }

    // Generate thumbnail preview
    this.getBase64(file as any, (url: string) => {
      file.thumbUrl = url;
    });

    this.fileList = this.fileList.concat(file);
    return false;
  };

  /**
   * Removes a file from the fileList
   */
  removeFile(index: number): void {
    this.fileList = this.fileList.filter((_, i) => i !== index);
  }

  /**
   * Closes the modal
   */
  closeModal(): void {
    this.nzModalRef.close(
      this.uploadedPhotos.length > 0 ? this.uploadedPhotos : null
    );
  }

  /**
   * Format function for the progress percentage
   */
  progressFormat = (percent: number): string => {
    return `${percent}%`;
  };

  /**
   * Gets image preview URL
   */
  getImagePreview(file: NzUploadFile): string {
    return file.thumbUrl || '';
  }

  /**
   * Converts file to base64 for preview
   */
  private getBase64(file: File, callback: (url: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(file);
  }

  /**
   * Handles the upload process for all files
   */
  handleUpload(): void {
    if (this.fileList.length === 0) {
      this.message.error('Por favor selecciona al menos una imagen');
      return;
    }

    this.uploading = true;
    this.pendingUploads = this.fileList.length;
    this.completedUploads = 0;
    this.globalProgress = 0;
    this.uploadedPhotos = [];

    // Create an array of observables for each upload
    const uploads = this.fileList.map((file: NzUploadFile) => {
      file.status = 'uploading';
      file.percent = 0;

      return this.uploadWithProgress(file);
    });

    // Use forkJoin to handle all uploads
    forkJoin(uploads)
      .pipe(
        finalize(() => {
          this.uploading = false;

          // If all uploads were successful, close the modal after a short delay
          if (this.uploadedPhotos.length === this.fileList.length) {
            setTimeout(() => {
              this.message.success(
                `${this.uploadedPhotos.length} imágenes subidas con éxito`
              );
              this.closeModal();
            }, 1000);
          }
        })
      )
      .subscribe({
        next: results => {
          // Process results and add photos to album
          if (this.uploadedPhotos.length > 0) {
            this.galleryService
              .addPhotosToAlbum(this.albumId, this.uploadedPhotos)
              .subscribe({
                next: () => {
                  // Success is handled in finalize
                },
                error: err => {
                  this.message.error('Error al guardar las fotos en el álbum');
                  console.error('Error saving photos to album:', err);
                },
              });
          }
        },
        error: err => {
          this.message.error('Hubo un error al subir las imágenes');
          console.error('Upload error:', err);
        },
      });
  }

  /**
   * Uploads a single file with progress tracking
   */
  private uploadWithProgress(file: NzUploadFile): Observable<string | null> {
    return new Observable(observer => {
      this.uploadService
        .uploadImage(
          file as any,
          `albums/${this.albumId}`,
          (progress: number) => {
            // Update individual file progress
            file.percent = progress;

            // Update global progress
            this.updateGlobalProgress();
          }
        )
        .pipe(
          catchError(error => {
            file.status = 'error';
            this.message.error(`${file.name} falló al subir.`);
            this.pendingUploads--;
            this.updateGlobalProgress();
            console.error(`Error uploading ${file.name}:`, error);
            return of(null);
          })
        )
        .subscribe({
          next: url => {
            if (url) {
              file.status = 'done';
              file.percent = 100;
              this.completedUploads++;
              this.pendingUploads--;

              this.uploadedPhotos.push({
                url: url,
                title: file.name,
                votes: 0,
                createdAt: new Date(),
                likes: 0,
                description: '',
                approved: true,
              });
            }

            this.updateGlobalProgress();
            observer.next(url);
            observer.complete();
          },
          error: err => {
            observer.error(err);
          },
        });
    });
  }

  /**
   * Updates the global progress percentage
   */
  private updateGlobalProgress(): void {
    if (this.fileList.length === 0) return;

    const totalProgress = this.fileList.reduce(
      (sum, file) => sum + (file.percent || 0),
      0
    );
    this.globalProgress = Math.round(totalProgress / this.fileList.length);
  }
}
