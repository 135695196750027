import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Firestore, collectionData, docData } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { FormGroup } from '@angular/forms';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Observable, map } from 'rxjs';
import { User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private firestore: Firestore,
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private firebaseStorage: Storage,
    private angularAuth: AngularFireAuth
  ) {}

  getUsers() {
    const colectionRef = collection(this.firestore, 'users');
    const q = query(colectionRef, where('isAsigned', '==', false));
    return collectionData(q, { idField: 'id' }) as Observable<User[]>;
  }

  getUserById(userID: any) {
    return this.afs
      .collection<User>('users', ref => ref.where('userID', '==', userID))
      .valueChanges()
      .pipe(map(users => users[0]));
  }

  getAuthorizedUsers(ids: any) {
    if (ids.length === 0) return [null];
    return this.afs
      .collection('users', ref => ref.where('userID', 'in', ids))
      .valueChanges();
  }

  //!deprecado
  async createUser(data: FormGroup) {
    // const usersRef = collection(db,'users') // collectionRef
    // const userRef = doc(usersRef) // docRef
    // const id = userRef.id // a docRef has an id property
    // const userData = {id, ...} // insert the id among the data
    // await setDoc(userRef,userData) // create the document

    const colectionRef = collection(this.firestore, 'users');
    const docRef = doc(colectionRef);
    const id = docRef.id;
    let dataToCreate = { userID: id, ...data.value };
    const profilePic = data.get('profilePhoto')?.value;
    const companyPic = data.get('company.companyLogo')?.value;

    if (profilePic) {
      const storageRef = ref(
        this.firebaseStorage,
        `membersFiles/${id}/profilePhoto-${id}`
      );
      const uploadTask = await uploadBytes(storageRef, profilePic);
      const downloadURL = await getDownloadURL(uploadTask.ref);
      dataToCreate = { ...dataToCreate, profilePhoto: downloadURL };
    }
    if (companyPic) {
      const storageRef = ref(
        this.firebaseStorage,
        `membersFiles/${id}/companyLogo-${id}`
      );
      const uploadTask = await uploadBytes(storageRef, companyPic);
      const downloadURL = await getDownloadURL(uploadTask.ref);
      dataToCreate = {
        ...dataToCreate,
        dob: new Date(data.get('dob')?.value),
        company: {
          ...dataToCreate.company,
          companyLogo: downloadURL,
        },
      };
    }
    return await setDoc(docRef, dataToCreate);
  }

  async createUserV2(userData, share) {
    return this.aff
      .httpsCallable('createUser')({ userData, share })
      .toPromise();
  }

  async updateUser(data: FormGroup, userID: string) {
    const profilePic = data.get('profilePhoto')?.value;
    const companyPic = data.get('company.companyLogo')?.value;
    const docRef = doc(this.firestore, `users/${userID}`);
    if (typeof profilePic !== 'string') {
      const storageRef = ref(
        this.firebaseStorage,
        `membersFiles/${userID}/profilePhoto-${userID}`
      );
      const uploadTask = await uploadBytes(
        storageRef,
        data.get('profilePhoto')?.value
      );
      const downloadURL = await getDownloadURL(uploadTask.ref);
      data.get('profilePhoto')?.setValue(downloadURL);
    }
    if (typeof companyPic !== 'string') {
      const storageRef = ref(
        this.firebaseStorage,
        `membersFiles/${userID}/companyLogo-${userID}`
      );
      const uploadTask = await uploadBytes(
        storageRef,
        data.get('company.companyLogo')?.value
      );
      const downloadURL = await getDownloadURL(uploadTask.ref);
      data.get('company.companyLogo')?.setValue(downloadURL);
    }
    return await updateDoc(docRef, {
      dob: new Date(data.get('dob')?.value),
      ...data.value,
    });
  }

  async uploadMemberFiles(files: any, userID: string) {
    const filesURL = [];
    const docRef = doc(this.firestore, `users/${userID}`);
    for (let f = 0; f < files.length; f++) {
      const storageRef = ref(
        this.firebaseStorage,
        `membersFiles/${userID}/files/${files.item(f)!.name}`
      );
      const uploadTask = await uploadBytes(storageRef, files.item(f)!);
      const downloadURL = await getDownloadURL(uploadTask.ref);
      filesURL.push({ url: downloadURL, fileName: files.item(f)!.name });
    }
    return await updateDoc(docRef, { files: arrayUnion(...filesURL) });
  }

  async deleteMemberFile(item: any, userID: string) {
    const docRef = doc(this.firestore, 'users', userID);
    await updateDoc(docRef, { files: arrayRemove(item) });
  }

  getFilesFromUserByID(userID: string) {
    const docRef = doc(this.firestore, 'users', userID);
    return docData(docRef).pipe(map((user: any) => user.files));
  }

  getUnasigneUsers(): Observable<User[]> {
    return this.afs
      .collection<User>('users', ref => ref.where('isAsigned', '==', false))
      .valueChanges();
  }
  getUserByCid(cid: string) {
    return this.afs
      .collection<User>('users', ref => ref.where('cid', '==', cid))
      .valueChanges();
  }

  async uploadPhoto(file, userID) {
    const storageRef = ref(
      this.firebaseStorage,
      `membersFiles/${userID}/files/${new Date().getMilliseconds()}`
    );
    const uploadTask = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(uploadTask.ref);

    const spaceColRef = this.afs.collection('users');

    await spaceColRef.doc(userID).update({ profilePhoto: downloadURL });
    return downloadURL;
  }

  async resetNotShow(user) {
    return await this.aff
      .httpsCallable('clearNotShowCounter')({ user })
      .toPromise();
  }

  deleteUser(userID) {
    return this.aff.httpsCallable('deleteUser')({ userID }).toPromise();
  }

  getUserByEmail(email) {
    return this.afs
      .collection('users', ref => ref.where('email', '==', email))
      .valueChanges()
      .pipe(map(users => users[0]));
  }

  generateRecoversPasswordLink(email) {
    return this.aff
      .httpsCallable('generateRecoveryPasswordLink')({ email })
      .toPromise();
  }

  getPenalizedUsers(condition) {
    return this.afs
      .collection('users', ref => ref.where('notShowCounter', '>=', condition))
      .valueChanges() as Observable<User[]>;
  }

  getMultipleUsersByID(usersID) {
    if (!usersID && !usersID.length) return;
    return this.afs
      .collection('users', ref => ref.where('userID', 'in', usersID))
      .valueChanges();
  }
}
