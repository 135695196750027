
<form [formGroup]="adsForm" (ngSubmit)="onSubmit()">
  <!-- Layout Selection -->
  <div class="mb-4">
    <nz-form-item>
      <nz-form-label>Seleccionar Layout</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="layoutType" class="w-100">
          <nz-option nzValue="A" nzLabel="Layout A (Dos secciones)"></nz-option>
          <nz-option nzValue="B" nzLabel="Layout B (Una sección)"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </div>

  <!-- Layout A Form -->
  <div *ngIf="adsForm.get('layoutType')?.value === 'A'" class="row">
    <!-- Left Section -->
    <div class="col-md-6">
      <nz-card class="mb-3">
        <h3>Sección Izquierda</h3>
        <div formGroupName="leftSection">
          <!-- Image Upload -->
          <nz-form-item>
            <nz-form-label>Imagen</nz-form-label>
            <nz-form-control>
              <nz-upload
                [nzAccept]="'image/*'"
                [nzCustomRequest]="customUploadReq"
                [nzBeforeUpload]="beforeUpload"
                (nzChange)="handleChange($event, 'left')"
                [nzShowUploadList]="false"
                [nzMultiple]="false"
                [nzDisabled]="uploading">
                <button nz-button type="button" [nzLoading]="uploading">
                  <span nz-icon nzType="upload"></span>
                  Subir Imagen
                </button>
              </nz-upload>
              <img *ngIf="leftImagePreview" [src]="leftImagePreview" class="img-preview mt-2">
            </nz-form-control>
          </nz-form-item>

          <!-- External Link -->
          <nz-form-item>
            <nz-form-label>Enlace Externo</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="externalLink" placeholder="https://...">
            </nz-form-control>
          </nz-form-item>

          
          <nz-form-item>
            <nz-form-label>Nombre de la Empresa</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="companyName" placeholder="Nombre de la Empresa">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Número de Accion</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="shareNumber" placeholder="Número de Compartidos">
            </nz-form-control>
          </nz-form-item>
          <!-- Expiration Date -->
          <nz-form-item>
            <nz-form-label>Fecha de Vencimiento</nz-form-label>
            <nz-form-control>
              <nz-date-picker formControlName="expirationDate" class="w-100"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </nz-card>
    </div>

    <!-- Right Section -->
    <div class="col-md-6">
      <nz-card class="mb-3">
        <h3>Sección Derecha</h3>
        <div formGroupName="rightSection">
          <!-- Image Upload -->
          <nz-form-item>
            <nz-form-label>Imagen</nz-form-label>
            <nz-form-control>
              <nz-upload
                [nzAccept]="'image/*'"
                [nzCustomRequest]="customUploadReq"
                [nzBeforeUpload]="beforeUpload"
                (nzChange)="handleChange($event, 'right')"
                [nzShowUploadList]="false"
                [nzMultiple]="false"
                [nzDisabled]="uploading">
                <button nz-button type="button" [nzLoading]="uploading">
                  <span nz-icon nzType="upload"></span>
                  Subir Imagen
                </button>
              </nz-upload>
              <img *ngIf="rightImagePreview" [src]="rightImagePreview" class="img-preview mt-2">
            </nz-form-control>
          </nz-form-item>

          <!-- External Link -->
          <nz-form-item>
            <nz-form-label>Enlace Externo</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="externalLink" placeholder="https://...">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Nombre de la Empresa</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="companyName" placeholder="Nombre de la Empresa">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Número de Accion</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="shareNumber" placeholder="Número de Compartidos">
            </nz-form-control>
          </nz-form-item>

          <!-- Expiration Date -->
          <nz-form-item>
            <nz-form-label>Fecha de Vencimiento</nz-form-label>
            <nz-form-control>
              <nz-date-picker formControlName="expirationDate" class="w-100"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </nz-card>
    </div>
  </div>

  <!-- Layout B Form -->
  <div *ngIf="adsForm.get('layoutType')?.value === 'B'" class="row justify-content-center">
    <div class="col-md-8">
      <nz-card class="mb-3">
        <div formGroupName="centerSection">
          <!-- Image Upload -->
          <nz-form-item>
            <nz-form-label>Imagen</nz-form-label>
            <nz-form-control>
              <nz-upload
                [nzAccept]="'image/*'"
                [nzCustomRequest]="customUploadReq"
                [nzBeforeUpload]="beforeUpload"
                (nzChange)="handleChange($event, 'center')"
                [nzShowUploadList]="false"
                [nzMultiple]="false"
                [nzDisabled]="uploading">
                <button nz-button type="button"  [nzLoading]="uploading">
                  <span nz-icon nzType="upload"></span>
                  Subir Imagen
                </button>
              </nz-upload>
              <img *ngIf="centerImagePreview" [src]="centerImagePreview" class="img-preview mt-2">
            </nz-form-control>
          </nz-form-item>

          <!-- External Link -->
          <nz-form-item>
            <nz-form-label>Enlace Externo</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="externalLink" placeholder="https://...">
            </nz-form-control>
          </nz-form-item>

          
          <nz-form-item>
            <nz-form-label>Nombre de la Empresa</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="companyName" placeholder="Nombre de la Empresa">
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label>Número de Accion</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="shareNumber" placeholder="Número de Compartidos">
            </nz-form-control>
          </nz-form-item>
          <!-- Expiration Date -->
          <nz-form-item>
            <nz-form-label>Fecha de Vencimiento</nz-form-label>
            <nz-form-control>
              <nz-date-picker formControlName="expirationDate" class="w-100"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </nz-card>
    </div>
  </div>



  <!-- Submit Button -->
  <div class="d-flex justify-content-end mt-3">
    <button nz-button nzType="primary" type="submit" [disabled]="adsForm.invalid || uploading">
      Guardar
    </button>
  </div>
</form>

