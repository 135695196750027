<ng-container *ngIf="{ albums: albums$ | async } as state">
  <div class="bg-white px-4 py-8 min-h-full flex flex-col">
    <!-- Header Section -->
    <div class="mb-8 w-full">
      <h1 class="text-2xl font-bold text-gray-800">
        Galería de Álbumes ({{ state.albums?.length || 0 }})
      </h1>
      <p class="text-gray-600">Gestiona tus álbumes y fotografías</p>
    </div>

    <!-- Albums Grid -->
    <div class="w-full">
      <div
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 flex-1">
        <!-- New Album Card -->
        <div
          class="group relative album-card cursor-pointer"
          (click)="onCreateAlbum()">
          <div class="relative">
            <!-- Stacked Images Effect -->
            <div
              class="absolute -right-2 -bottom-2 w-full h-full bg-gray-100 rounded-lg"></div>
            <div
              class="absolute -right-1 -bottom-1 w-full h-full bg-gray-50 rounded-lg"></div>

            <!-- Main Container -->
            <div
              class="relative bg-gray-200 aspect-square rounded-lg overflow-hidden flex items-center justify-center group-hover:bg-gray-300 transition-colors">
              <div
                class="flex flex-col items-center gap-2 text-gray-400 group-hover:text-gray-600 transition-colors">
                <span
                  nz-icon
                  nzType="plus-circle"
                  nzTheme="outline"
                  class="text-3xl"></span>
                <span class="text-xs font-medium">Crear Nuevo Álbum</span>
              </div>
            </div>
          </div>

          <!-- Album Title -->
          <div class="mt-3">
            <h3
              class="font-medium text-gray-400 group-hover:text-gray-600 transition-colors text-base">
              Nuevo Álbum
            </h3>
            <p class="text-xs text-gray-400">Crea un nuevo álbum de fotos</p>
          </div>
        </div>

        <!-- Existing Albums -->
        @defer (when state.albums) {
          @for (album of state.albums; track album.id) {
            <app-album-card [album]="album"></app-album-card>
          }
        }
      </div>
    </div>
  </div>
</ng-container>
