import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { TopTenModalComponent } from './top-ten-modal.component';

@NgModule({
  declarations: [TopTenModalComponent],
  imports: [CommonModule, NzIconModule, NzModalModule],
  exports: [TopTenModalComponent],
})
export class TopTenModalModule {}
