import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { BehaviorSubject, finalize } from 'rxjs';
import {
  Album,
  GalleryService,
  Photo,
} from 'src/app/@core/services/gallery.service';

@Component({
  selector: 'app-image-detail-modal',
  templateUrl: './image-detail-modal.component.html',
  styleUrls: ['./image-detail-modal.component.css'],
})
export class ImageDetailModalComponent implements OnInit {
  isSaving$ = new BehaviorSubject<boolean>(false);
  photoForm: FormGroup = new FormGroup({
    title: new FormControl(''),
    description: new FormControl(''),
    approved: new FormControl(false),
    votes: new FormControl(0),
    submittedBy: new FormControl(null),
  });

  constructor(
    @Inject(NZ_MODAL_DATA) public data: { photo: Photo; albumData: Album },
    private modal: NzModalRef,
    private message: NzMessageService,
    private galleryService: GalleryService
  ) {}

  ngOnInit() {
    // Initialize form with photo data
    if (this.data.photo) {
      this.photoForm.patchValue({
        title: this.data.photo.title?.split('.')[0] || '',
        description: this.data.photo.description || '',
        approved: this.data.photo.approved || false,
        votes: this.data.photo.votes || 0,
        submittedBy: this.data.photo.submittedBy || null,
      });
    }
  }

  onShareholderSelected(user: any) {
    if (user) {
      this.photoForm.patchValue({ submittedBy: user });
    }
  }

  onCancel() {
    this.modal.close();
  }

  onSave() {
    if (this.photoForm.valid) {
      this.isSaving$.next(true);
      const updatedPhoto = {
        ...this.data.photo,
        ...this.photoForm.value,
      };

      this.galleryService
        .updatePhoto(this.data.albumData.id, this.data.photo.id, updatedPhoto)
        .pipe(finalize(() => this.isSaving$.next(false)))
        .subscribe(() => {
          this.modal.close(updatedPhoto);
        });
    }
  }
}
