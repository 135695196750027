<div class="bg-white rounded-lg shadow-lg overflow-hidden">
  <form [formGroup]="photoForm" class="w-full">
    <div class="grid grid-cols-1 md:grid-cols-3 min-h-[600px]">
      <!-- Left Column: Image Preview -->
      <div class="bg-gray-50 p-6 flex flex-col gap-6 md:col-span-1">
        <div
          class="w-full h-80 rounded-lg overflow-hidden shadow-md bg-white flex items-center justify-center">
          <img
            [src]="data.photo.url"
            [alt]="data.photo.title"
            class="max-h-full max-w-full object-contain" />
        </div>
        <!-- Quick Info -->
        <div class="bg-white p-4 rounded-lg shadow-sm space-y-3">
          <h4 class="font-medium text-gray-700 mb-2 border-b pb-2">
            Información rápida
          </h4>
          <div class="flex items-center gap-2 text-gray-600">
            <span
              nz-icon
              nzType="calendar"
              nzTheme="outline"
              class="text-blue-500"></span>
            <span>{{
              data.photo.createdAt?.seconds * 1000 | date: 'mediumDate'
            }}</span>
          </div>
          <div class="flex items-center gap-2 text-gray-600">
            <span
              nz-icon
              nzType="picture"
              nzTheme="outline"
              class="text-blue-500"></span>
            <span class="truncate">{{ data.photo.title }}</span>
          </div>
          <div
            class="flex items-center gap-2 text-gray-600"
            *ngIf="data.photo.votes">
            <span
              nz-icon
              nzType="heart"
              nzTheme="fill"
              class="text-red-500"></span>
            <span>{{ data.photo.votes }} votos</span>
          </div>
        </div>
      </div>

      <!-- Right Column: Form Fields -->
      <div class="p-6 md:col-span-2 bg-white">
        <h3 class="text-xl font-semibold text-gray-800 mb-6 pb-2 border-b">
          Detalles de la imagen
        </h3>

        <div class="space-y-6">
          <div class="space-y-2">
            <label class="block text-sm font-medium text-gray-700"
              >Título</label
            >
            <input
              nz-input
              formControlName="title"
              placeholder="Título de la imagen"
              class="w-full rounded-md" />
          </div>

          <div class="space-y-2">
            <label class="block text-sm font-medium text-gray-700"
              >Descripción</label
            >
            <textarea
              nz-input
              formControlName="description"
              placeholder="Descripción de la imagen"
              [nzAutosize]="{ minRows: 3, maxRows: 5 }"
              class="w-full rounded-md">
            </textarea>
          </div>

          <!-- Shareholder Picker -->
          <div
            class="space-y-2"
            *ngIf="data.albumData.allowSubmit && data.photo.submittedBy">
            <label class="block text-sm font-medium text-gray-700"
              >Accionista</label
            >
            <div class="bg-gray-50 rounded-lg p-4 shadow-sm">
              <div class="flex items-start gap-4">
                <div class="flex-shrink-0">
                  <img
                    width="60"
                    height="60"
                    [src]="
                      data.photo.submittedBy?.profilePhoto ||
                      'assets/images/default-avatar.png'
                    "
                    [alt]="
                      data.photo.submittedBy?.fname +
                      ' ' +
                      data.photo.submittedBy?.lname
                    "
                    class="rounded-full object-cover border-2 border-blue-100" />
                </div>
                <div class="flex-1">
                  <h4 class="text-base font-medium text-gray-800">
                    {{
                      data.photo.submittedBy?.fname +
                        ' ' +
                        data.photo.submittedBy?.lname
                    }}
                  </h4>
                  <p class="text-sm text-gray-500">
                    {{ data.photo.submittedBy?.email || 'Sin correo' }}
                  </p>
                  <p class="text-sm text-gray-500">
                    {{
                      data.photo.submittedBy?.phoneNumber?.nationalNumber ||
                        'Sin teléfono'
                    }}
                  </p>
                  <div class="mt-3 grid grid-cols-1 md:grid-cols-2 gap-2">
                    <div
                      class="flex items-center gap-2 text-sm text-gray-600"
                      *ngIf="data.photo.submittedBy?.phoneNumber">
                      <span
                        nz-icon
                        nzType="phone"
                        nzTheme="outline"
                        class="text-blue-500"></span>
                      <span>{{ data.photo.submittedBy?.phoneNumber }}</span>
                    </div>
                    <div
                      class="flex items-center text-sm text-gray-600"
                      *ngIf="data.photo.submittedBy?.shareNumber">
                      <span
                        nz-icon
                        nzType="id-card"
                        nzTheme="outline"
                        class="text-blue-500"></span>
                      <span
                        >Acción #{{ data.photo.submittedBy?.shareNumber }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 rounded-lg p-4 shadow-sm mt-4">
            <h4 class="font-medium text-gray-700 mb-4 pb-2 border-b">
              Link al Drive
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <!-- Link a la izquierda -->
              <div class="flex items-center gap-2">
                <span
                  nz-icon
                  nzType="link"
                  nzTheme="outline"
                  class="text-blue-500"></span>
                <a
                  [href]="data.photo.link"
                  target="_blank"
                  class="text-blue-600 hover:text-blue-800 hover:underline break-all">
                  {{ data.photo.link }}
                </a>
              </div>

              <!-- Categoría a la derecha -->
              <div
                *ngIf="data.photo.category && data.photo.category.length > 0">
                <span class="text-sm font-medium text-gray-700"
                  >Categoría:</span
                >
                <div class="flex flex-wrap gap-2 mt-1">
                  <span
                    class="px-2 py-1 text-xs bg-blue-100 text-blue-800 rounded-full">
                    {{ data.photo.category }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Status Section -->
          <div class="bg-gray-50 rounded-lg p-4 shadow-sm">
            <h4 class="font-medium text-gray-700 mb-4 pb-2 border-b">
              Estado y Métricas
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div class="flex items-center">
                <label
                  nz-checkbox
                  formControlName="approved"
                  class="flex items-center gap-2">
                  <span class="flex items-center gap-2">
                    <span
                      nz-icon
                      [nzType]="
                        photoForm.get('approved').value
                          ? 'check-circle'
                          : 'clock-circle'
                      "
                      [class]="
                        photoForm.get('approved').value
                          ? 'text-green-500'
                          : 'text-amber-500'
                      ">
                    </span>
                    <span class="text-sm font-medium">
                      {{
                        photoForm.get('approved').value
                          ? 'Aprobada'
                          : 'Pendiente de aprobación'
                      }}
                    </span>
                  </span>
                </label>
              </div>

              <div class="space-y-2">
                <label class="block text-sm font-medium text-gray-700"
                  >Likes recibidos</label
                >
                {{ data.photo.likes }}
              </div>
            </div>
          </div>
        </div>

        <!-- Form Actions -->
        <div class="flex justify-end gap-3 mt-8 pt-4 border-t">
          <button nz-button nzType="default" (click)="onCancel()" class="px-5">
            Cancelar
          </button>
          <button
            nz-button
            nzType="primary"
            [nzLoading]="isSaving$ | async"
            (click)="onSave()"
            [disabled]="!photoForm.valid"
            class="px-5">
            <span nz-icon nzType="save" nzTheme="outline"></span>
            {{ (isSaving$ | async) ? 'Guardando...' : 'Guardar cambios' }}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
