import { Component, Inject, OnInit, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { BehaviorSubject, switchMap } from 'rxjs';
import { InscriptionsService } from 'src/app/@core/services/inscriptions.service';

@Component({
  selector: 'app-manual-inscription',
  templateUrl: './manual-inscription.component.html',
  styleUrls: ['./manual-inscription.component.scss'],
})
export class ManualInscriptionComponent implements OnInit {
  config$ = new BehaviorSubject<any>(null);

  saving = signal(false);
  disciplines$ = this.config$.pipe(
    switchMap(config => {
      return this.inscriptionService.getDisciplinesByInscription(
        config.inscriptionID
      );
    })
  );
  form: FormGroup;
  selectedShareHolder: any | null = null;

  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.Venezuela,
    CountryISO.UnitedStates,
    CountryISO.Italy,
  ];
  PhoneNumberFormat = PhoneNumberFormat;

  constructor(
    private fb: FormBuilder,
    private inscriptionService: InscriptionsService,
    private message: NzMessageService,
    public modal: NzModalRef,
    @Inject(NZ_MODAL_DATA)
    private config: {
      inscriptionID: string;
      disciplineID: string;
    }
  ) {
    this.config$.next(this.config);

    this.form = this.fb.group({
      participantType: ['socio', Validators.required],
      disciplines: [
        [this.config.disciplineID ?? ''],
        [Validators.required, Validators.minLength(1)],
      ],
      // Campos para socios
      shareHolderId: [''],
      age: [null],
      cid: [null],
      phoneNumber: [null],
      // Campos para invitados
      fullName: [''],
      shareNumber: [''],
      email: [''],
    });
  }

  ngOnInit(): void {
    this.config$.next(this.config);

    // Suscribirse a cambios en el tipo de participante
    this.form.get('participantType')?.valueChanges.subscribe(type => {
      this.setupValidators(type);
    });
  }

  private setupValidators(type: 'socio' | 'invitado'): void {
    const controls = this.form.controls;

    // Limpiar todos los validadores primero
    Object.keys(controls).forEach(key => {
      controls[key].clearValidators();
    });

    // Configurar validadores según el tipo
    if (type === 'socio') {
      controls['shareHolderId'].setValidators(Validators.required);

      if (this.selectedShareHolder) {
        if (!this.selectedShareHolder.phoneNumber) {
          controls['phoneNumber'].setValidators([Validators.required]);
        }
        if (!this.selectedShareHolder.cid) {
          controls['cid'].setValidators(Validators.required);
        }
      }
    } else {
      controls['fullName'].setValidators(Validators.required);
      controls['phoneNumber'].setValidators([Validators.required]);
      controls['shareNumber'].setValidators(Validators.required);
      controls['age'].setValidators(Validators.required);
    }

    // Siempre requeridas
    controls['disciplines'].setValidators([
      Validators.required,
      Validators.minLength(1),
    ]);

    // Actualizar estado de los controles
    Object.keys(controls).forEach(key => {
      controls[key].updateValueAndValidity({ emitEvent: false });
    });
  }

  onShareHolderSelected(shareHolder: any): void {
    this.selectedShareHolder = shareHolder;

    // Resetear los campos relacionados
    this.form.patchValue(
      {
        fullName: `${shareHolder.fname} ${shareHolder.lname}`,
        shareHolderId: shareHolder.userID,
        cid: shareHolder.cid || null,
        phoneNumber: shareHolder.phoneNumber || null,
        accion: shareHolder.shareNumber || null,
      },
      { emitEvent: false }
    );

    // Actualizar validadores sin emitir eventos
    this.setupValidators('socio');
  }

  isValidForm(): boolean {
    const type = this.form.get('participantType')?.value;
    if (type === 'Socio') {
      return !!this.selectedShareHolder;
    }
    return true;
  }

  onSubmit() {
    if (this.form.invalid || !this.isValidForm()) {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }

    this.saving.update(() => true);
    try {
      const formValue = this.form.value;
      const participant = {
        type: formValue.participantType,
        disciplines: formValue.disciplines,
        age: formValue.age,
        fromApp: false,
        ...(formValue.participantType === 'socio'
          ? {
              shareHolderId: formValue.shareHolderId,
              cid: +this.selectedShareHolder.cid || +formValue.cid,
              phoneNumber:
                this.selectedShareHolder.phoneNumber || formValue.phoneNumber,
              fullName:
                `${this.selectedShareHolder.fname} ${this.selectedShareHolder.lname}`.trim(),
              email: this.selectedShareHolder.email,
              shareNumber: this.selectedShareHolder.shareNumber,
            }
          : {
              fullName: formValue.fullName.trim(),
              phoneNumber: formValue.phoneNumber,
              shareNumber: `CDC-${formValue.shareNumber}`,
              email: formValue.email,
              cid: +formValue.cid,
            }),
      };

      console.log({ participant, config: this.config }, 'participant');

      this.inscriptionService
        .addParticipant(participant, {
          inscriptionID: this.config.inscriptionID,
          disciplineID: this.config.disciplineID,
        })
        .subscribe({
          next: () => {
            this.saving.update(() => false);

            this.modal.close();
          },
          error: () => {
            this.saving.update(() => false);
          },
        });
    } catch (error) {
      console.error('Error al realizar la inscripción:', error);
      this.message.error('Error al realizar la inscripción');
    }
  }

  onCancel(): void {
    this.form.reset({
      participantType: 'Socio',
      disciplines: [this.config.disciplineID ?? ''],
    });
    this.selectedShareHolder = null;
  }
}
