import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';
import { AlbumPhotosUploadComponent } from './album-photos-upload.component';

@NgModule({
  declarations: [AlbumPhotosUploadComponent],
  imports: [
    CommonModule,
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    NzMessageModule,
    NzProgressModule,
    NzToolTipModule,
    TruncatePipe,
  ],
  exports: [AlbumPhotosUploadComponent],
})
export class AlbumPhotosUploadModule {}
