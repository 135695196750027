<form
  nz-form
  [formGroup]="popupForm"
  (ngSubmit)="submitForm()"
  nzLayout="vertical">
  <nz-form-item>
    <nz-form-label nzRequired>Nombre del Popup</nz-form-label>
    <nz-form-control
      [nzSpan]="24"
      nzErrorTip="Por favor ingrese el nombre del popup">
      <input nz-input formControlName="popUpName" />
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Imagen del Popup (solo JPG y un tamaño fijo de 400px de ancho por 600px de alto)</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Por favor seleccione una imagen">
      <input type="file" (change)="onFileSelected($event)" accept="image/jpg" />

      <div class="flex justify-center items-center">
        <nz-card>
          <ng-container *ngIf="isUploading; else imagePreview">
            <div class="flex justify-center items-center h-[200px]">
              <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
            </div>
          </ng-container>
          <ng-template #imagePreview>
            <img
              *ngIf="imageUrl"
              [src]="imageUrl"
              style="max-width: 100%; margin-top: 10px" />
          </ng-template>
        </nz-card>
      </div>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Target</nz-form-label>
    <nz-form-control [nzSpan]="24" nzErrorTip="Por favor seleccione un target">
      <nz-select formControlName="target">
        <nz-option nzValue="solvent" nzLabel="Solventes"></nz-option>
        <nz-option nzValue="unsolvent" nzLabel="Insolventes"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzRequired>Link del Popup</nz-form-label>
    <nz-form-control
      [nzSpan]="24"
      nzErrorTip="Por favor ingrese una URL válida">
      <input nz-input formControlName="popUpLink" />
    </nz-form-control>
  </nz-form-item>

  <div nz-row class="flex justify-center">
    <div nz-col nzSpan="8">
      <nz-form-item>
        <nz-form-label nzRequired>Fecha de Lanzamiento</nz-form-label>
        <nz-form-control
          [nzSpan]="24"
          nzErrorTip="Por favor seleccione una fecha">
          <nz-date-picker
            formControlName="launchDate"
            nzFormat="dd/MM/yyy"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>
    </div>

    <div nz-col nzSpan="8">
      <nz-form-item>
        <nz-form-label nzRequired>Límite</nz-form-label>
        <nz-form-control
          [nzSpan]="24"
          nzErrorTip="Por favor ingrese un número entre 1 y 10">
          <nz-input-number
            formControlName="limit"
            [nzMin]="1"
            [nzMax]="10"></nz-input-number>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSpan="8">
      <nz-form-item>
        <nz-form-label>Visible</nz-form-label>
        <nz-form-control [nzSpan]="24">
          <nz-switch formControlName="isVisible"></nz-switch>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>

  <div class="flex justify-end">
    <button nz-button nzType="primary" [disabled]="!popupForm.valid">
      {{ isEditing ? 'Actualizar' : 'Crear' }} Pop-Up
    </button>
  </div>
</form>
