import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { GymService } from 'src/app/@core/services/gym.service';
import { BehaviorSubject } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-class-form',
  templateUrl: './class-form.component.html',
  styleUrls: ['./class-form.component.scss'],
})
export class ClassFormComponent implements OnInit {
  @Input() classData: any;
  @Input() from: string = '';
  classForm: FormGroup;
  statusOptions = ['Confirmado', 'Cancelado', 'Nuevo horario'];
  isLoading$ = new BehaviorSubject<boolean>(false);

  durations = [
    { value: '00:30', label: '30 minutos' },
    { value: '01:00', label: '1 hora' },
    { value: '01:30', label: '1 hora 30 minutos' },
    { value: '02:00', label: '2 horas' },
  ];

  colors = [
    { value: '#ff9f89', label: 'Rojo' },
    { value: '#7bdcb5', label: 'Verde' },
    { value: '#8ed1fc', label: 'Azul' },
    { value: '#ffe066', label: 'Amarillo' },
    { value: '#d4a5ff', label: 'Violeta' },
    { value: '#ff99cc', label: 'Rosa' },
    { value: '#ff9966', label: 'Naranja' },
    { value: '#99ccff', label: 'Celeste' },
    { value: '#c2ff99', label: 'Verde Lima' },
    { value: '#ffb366', label: 'Durazno' },
    { value: '#a6a6a6', label: 'Gris' },
    { value: '#80ffff', label: 'Turquesa' },
  ];

  constructor(
    private fb: FormBuilder,
    private drawerRef: NzDrawerRef,
    private gymService: GymService,
    private modalService: NzModalService
  ) {}

  ngOnInit(): void {
    console.log(this.classData);
    this.classForm = this.fb.group({
      title: ['', Validators.required],
      color: ['', Validators.required],
      capacity: ['', Validators.required],
      status: ['Confirmado', Validators.required],
      duration: ['', Validators.required],
      instructor: ['', Validators.required],
    });

    if (this.classData) {
      this.classForm.patchValue({
        ...this.classData,
        ...this.classData.extendedProps,
      });
    }

    if (this.from === 'calendar') {
      this.classForm.get('color')?.disable();
    }
  }

  async onSubmit() {
    this.isLoading$.next(true);
    const formData = this.classForm.value;

    if (this.from === 'calendar') {
      console.log('guardar calendario');
      return await this.gymService
        .updateActiveClass(this.classData.id, formData)
        .then(() => {
          this.isLoading$.next(false);
          this.drawerRef.close(true);
        })
        .catch(error => {
          console.error('Error updating class:', error);
          this.isLoading$.next(false);
        });
    }

    if (this.from === 'gym-classes') {
      return await this.gymService
        .updateAvailableClass(this.classData.id, formData)
        .then(() => {
          this.isLoading$.next(false);
          this.drawerRef.close(true);
        })
        .catch(error => {
          console.error('Error updating class:', error);
          this.isLoading$.next(false);
        });
    }

    return await this.gymService
      .createAvailableClass(formData)
      .then(() => {
        this.isLoading$.next(false);
        this.drawerRef.close(true);
      })
      .catch(error => {
        console.error('Error adding class:', error);
        this.isLoading$.next(false);
      });
  }

  deleteClass(): void {
    this.modalService.confirm({
      nzTitle: '¿Estás seguro de que quieres eliminar esta clase?',
      nzContent: 'Esta acción no se puede deshacer.',
      nzOkText: 'Sí',
      nzOkType: 'primary', // Cambiado de 'danger' a 'primary'
      nzOkDanger: true, // Añadido para dar estilo de peligro al botón
      nzOnOk: () => {
        if (this.from === 'calendar') {
          return this.gymService
            .deleteActiveClass(this.classData.id)
            .then(() => {
              this.isLoading$.next(false);
              this.drawerRef.close(true);
            });
        }

        this.isLoading$.next(true);
        this.gymService
          .deleteClass(this.classData)
          .then(() => {
            this.isLoading$.next(false);
            this.drawerRef.close(true);
          })
          .catch(error => {
            console.error('Error deleting class:', error);
            this.isLoading$.next(false);
          });
      },
      nzCancelText: 'No',
    });
  }
}
