<ng-container *ngIf="{ allSpaces: allSpaces$ | async } as state">
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
    <ng-container *ngFor="let space of state.allSpaces; trackBy: identifySpace">
      <nz-card class="w-full h-full flex flex-col" 
               [nzActions]="space.hasAds ? [actionAds, actionClear] : [actionAds]" 
               [nzCover]="coverTemplate">
        <!-- Card Content -->
        <div class="flex flex-col flex-grow">
          <nz-card-meta
            [nzTitle]="space.spaceName"
            [nzDescription]="descriptionTemplate">
          </nz-card-meta>
        </div>

        <!-- Cover Template -->
        <ng-template #coverTemplate>
          <div class="relative h-48 overflow-hidden">
            <img
              [src]="space.spaceImage"
              class="w-full h-full object-cover"
              [alt]="space.spaceName"/>
            <div *ngIf="space.hasAds" 
                 class="absolute top-2 right-2 px-3 py-1 bg-blue-500 text-white rounded-full text-sm">
              Layout {{space.adsLayout}}
            </div>
          </div>
        </ng-template>

        <!-- Description Template -->
        <ng-template #descriptionTemplate>
          <div class="h-full flex-grow" [class.flex]="!space.hasAds">
            <div class="mt-4 flex-grow" *ngIf="space.hasAds">
              <!-- Grid para Layout A -->
              <div *ngIf="space.adsLayout === 'A'" class="grid grid-cols-2 gap-4">
                <ng-container *ngFor="let ad of space.ads; let i = index">
                  <div *ngIf="ad.imgUrl || ad.externalLink" 
                       class="border rounded-lg p-3 bg-gray-50">
                    <div class="text-sm font-medium mb-2">
                      Anuncio {{i + 1}}
                    </div>
                    <div class="space-y-2">
                      <div *ngIf="ad.imgUrl" class="relative h-24 overflow-hidden rounded-md">
                        <img [src]="ad.imgUrl" class="w-full h-full object-contain"/>
                      </div>
                      <div *ngIf="ad.externalLink" class="text-xs text-gray-500 truncate">
                        <a [href]="ad.externalLink" target="_blank" class="text-blue-500 hover:underline">
                          {{ad.externalLink}}
                        </a>
                      </div>
                      <div class="text-xs text-gray-500">
                        Clicks: {{ad.stats?.clicks || 0}}
                      </div>
                      <div  class="text-xs text-gray-500">
                        Vence: {{ ad.expirationDate?.seconds ? (ad.expirationDate.seconds * 1000 | date:'shortDate') : 'No vence'}}
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>

              <!-- Contenedor centrado para Layout B -->
              <div *ngIf="space.adsLayout === 'B'" class="flex justify-center">
                <div *ngIf="space.ads[0]?.imgUrl || space.ads[0]?.externalLink" 
                     class="border rounded-lg p-3 bg-gray-50 w-2/3">
                  <div class="text-sm font-medium mb-2">
                    Anuncio
                  </div>
                  <div class="space-y-2">
                    <div *ngIf="space.ads[0].imgUrl" class="relative h-24 overflow-hidden rounded-md">
                      <img [src]="space.ads[0].imgUrl" class="w-full h-full object-contain"/>
                    </div>
                    <div *ngIf="space.ads[0].externalLink" class="text-xs text-gray-500 truncate">
                      <a [href]="space.ads[0].externalLink" target="_blank" class="text-blue-500 hover:underline">
                        {{space.ads[0].externalLink}}
                      </a>
                    </div>
                    <div class="text-xs text-gray-500">
                      Clicks: {{space.ads[0].stats?.clicks || 0}}
                    </div>
                    <div *ngIf="space.ads[0].expirationDate" class="text-xs text-gray-500">
                      Vence: {{space.ads[0].expirationDate.seconds * 1000 | date:'shortDate'}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!space.hasAds" class="flex-grow flex items-center justify-center text-gray-500">
              No hay publicidad configurada
            </div>
          </div>
        </ng-template>

        <!-- Action Templates -->
        <ng-template #actionAds>
          <button nz-button nzType="link" (click)="handleAds(space)">
            <span nz-icon nzType="setting"></span>
            Configurar Publicidad
          </button>
        </ng-template>

        <ng-template #actionClear>
          <button nz-button nzType="link" nzDanger (click)="handleClearAds(space)">
            <span nz-icon nzType="delete"></span>
            Limpiar
          </button>
        </ng-template>

      </nz-card>
    </ng-container>
  </div>
</ng-container>

<style>
  :host ::ng-deep {
    .ant-card {
      height: 100%;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;

      .ant-card-body {
        flex: 1;
        display: flex;
        flex-direction: column;
      }

      .ant-card-actions {
        background: #fafafa;
        margin-top: auto;
      }

      .ant-card-actions > li {
        margin: 0;
      }
    }

    .ant-card:hover {
      box-shadow: 0 4px 15px rgba(0,0,0,0.1);
      transform: translateY(-2px);
    }
  }
</style>
