import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';
import { Discipline } from 'src/app/@core/models/inscription.interface';
import { InscriptionsService } from 'src/app/@core/services/inscriptions.service';

@Component({
  selector: 'app-discipline-form',
  templateUrl: './discipline-form.component.html',
  styleUrls: ['./discipline-form.component.scss'],
})
export class DisciplineFormComponent implements OnInit {
  form: FormGroup;
  saving$ = new BehaviorSubject<boolean>(false);

  genderOptions = [
    { label: 'Masculino', value: 'M' },
    { label: 'Femenino', value: 'F' },
    { label: 'Mixto', value: 'MF' },
  ];

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private modalRef: NzModalRef,
    private inscriptionsService: InscriptionsService,
    @Inject(NZ_MODAL_DATA)
    public data: {
      inscriptionID: string;
      discipline?: Discipline;
      option: 'create' | 'edit';
    }
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      gender: ['', [Validators.required]],
      coach: ['', [Validators.required]],
      capacity: [50, [Validators.required]],
      minAge: [0, [Validators.required]],
      maxAge: [100, [Validators.required]],
      coordinator: ['', [Validators.required]],
    });
  }

  ngOnInit() {
    if (this.data?.discipline) {
      this.form.patchValue(this.data.discipline);
    }
  }

  async onSubmit() {
    this.saving$.next(true);

    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
        }
      });
      return;
    }
    const discipline: Discipline = {
      ...this.form.value,
      inscriptionID:
        this.data?.option === 'create'
          ? this.data.inscriptionID
          : this.data.discipline.inscriptionID,
      ...(this.data?.discipline?.id ? { id: this.data.discipline.id } : {}),
    };
    if (this.data?.option === 'create') {
      this.inscriptionsService.createDiscipline(discipline).subscribe({
        next: () => {
          this.saving$.next(false);
          this.modalRef.close(discipline);
          this.message.success(
            `Disciplina ${this.data?.option === 'create' ? 'creada' : 'actualizada'} exitosamente`
          );
        },
        error: () => {
          this.message.error('Error al guardar la disciplina');
          this.saving$.next(false);
        },
        complete: () => {
          this.saving$.next(false);
        },
      });
    } else {
      this.inscriptionsService
        .updateDiscipline(this.data.discipline.id, discipline)
        .subscribe({
          next: () => {
            this.saving$.next(false);
            this.modalRef.close(discipline);
            this.message.success(
              `Disciplina ${this.data?.option === 'create' ? 'creada' : 'actualizada'} exitosamente`
            );
          },
          error: () => {
            this.message.error('Error al guardar la disciplina');
            this.saving$.next(false);
          },
          complete: () => {
            this.saving$.next(false);
          },
        });
    }
  }

  onCancel() {
    this.modalRef.close();
  }
}
