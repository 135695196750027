import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { PopUp } from 'src/app/@core/models/pop-up.model';
import { PopUpService } from 'src/app/@core/services/pop-up.service';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';

@Component({
  selector: 'app-create-pop-up',
  templateUrl: './create-pop-up.component.html',
  styleUrls: ['./create-pop-up.component.css'],
})
export class CreatePopUpComponent implements OnInit {
  @Input() popUp: PopUp | null = null;
  popupForm: FormGroup;
  imageUrl: string | ArrayBuffer | null = null;
  isEditing = false;
  isUploading = false;

  constructor(
    private fb: FormBuilder,
    private message: NzMessageService,
    private popupService: PopUpService,
    private drawerRef: NzDrawerRef
  ) {}

  ngOnInit(): void {
    this.isEditing = !!this.popUp;
    this.initForm();
    
    if (this.popUp) {
      this.imageUrl = this.popUp.popUpImage;
      this.popupForm.patchValue({
        ...this.popUp,
        launchDate: this.parseDateString(this.popUp.launchDate)
      });
    }
  }

  private parseDateString(dateStr: string): Date {
    const [day, month, year] = dateStr.split('/');
    return new Date(Number(year), Number(month) - 1, Number(day));
  }

  private initForm(): void {
    this.popupForm = this.fb.group({
      popUpName: ['', [Validators.required]],
      popUpImage: ['', [Validators.required]],
      target: ['', [Validators.required]],
      popUpLink: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
          ),
        ],
      ],
      launchDate: [null, [Validators.required]],
      isVisible: [true],
      limit: [1, [Validators.required, Validators.min(1), Validators.max(10)]],
    });
  }

  onFileSelected(event: Event): void {
    const file = (event.target as HTMLInputElement).files?.[0];
    console.log(file);
    if (file) {
      if (file.size > 500 * 1024) {
        this.message.error('La imagen no debe pesar más de 500KB');
        return;
      }

      const img = new Image();
      img.onload = () => {
        if (img.width !== 400 || img.height !== 600) {
          this.message.error(
            'La imagen debe tener las siguientes medida: 400px de ancho y 600px de alto.'
          );
          return;
        }
        this.uploadImage(file);
      };
      img.src = URL.createObjectURL(file);
    }
  }

  uploadImage(file: File): void {
    this.isUploading = true;
    
    this.popupService.uploadImage(file).subscribe(
      url => {
        this.imageUrl = url;
        this.popupForm.patchValue({ popUpImage: url });
        this.isUploading = false;
      },
      error => {
        this.message.error('Error al subir la imagen');
        this.isUploading = false;
      }
    );
  }

  submitForm(): void {
    if (this.popupForm.valid) {
      const popupData: PopUp = this.popupForm.value;

      if (this.isEditing && this.popUp?.popUpID) {
        this.popupService.updatePopup(this.popUp.popUpID, popupData).subscribe(
          () => {
            this.message.success('Popup actualizado exitosamente');
            this.drawerRef.close(true);
          },
          error => {
            this.message.error('Error al actualizar el popup: ' + error.message);
          }
        );
      } else {
        this.popupService.savePopup(popupData).subscribe(
          savedPopup => {
            this.message.success(
              `Popup guardado exitosamente con ID: ${savedPopup.popUpID}`
            );
            this.drawerRef.close(true);
          },
          error => {
            this.message.error('Error al guardar el popup: ' + error.message);
          }
        );
      }
    } else {
      Object.values(this.popupForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
