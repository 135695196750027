<div class="inscription-form" id="print">
  <div class="header mx-auto">
    <img
      src="assets/img/cdi-logo.png"
      class="mx-auto"
      width="100"
      alt="CDI Logo" />
    <h1>COMPROBANTE DE INSCRIPCIÓN</h1>
    <h2>CENTRO DEPORTIVO ITALIANO</h2>
  </div>

  <div class="inscription-details">
    <div class="info-row">
      <span class="label">Fecha de Inscripción:</span>
      <span class="value">{{ formattedDate }}</span>
    </div>
    <div class="info-row">
      <span class="label">Evento:</span>
      <span class="value">{{ data?.inscription?.name }}</span>
    </div>
  </div>

  <div class="participant-details">
    <h3>DATOS DEL PARTICIPANTE</h3>

    <div class="info-row">
      <span class="label">Nombre y Apellido:</span>
      <span class="value">{{ data?.fullName }}</span>
    </div>
    <div class="info-row">
      <span class="label">Acción Nº:</span>
      <span class="value">{{ data?.shareNumber }}</span>
    </div>
    <div class="info-row">
      <span class="label">Cédula:</span>
      <span class="value">{{ data?.cid }}</span>
    </div>

    <div class="info-row">
      <span class="label">Email:</span>
      <span class="value">{{ data?.email }}</span>
    </div>

    <div class="info-row">
      <span class="label">Teléfono:</span>
      <span class="value">{{ data?.phoneNumber?.nationalNumber }}</span>
    </div>
  </div>

  <div class="discipline-details">
    <h3>DISCIPLINA INSCRITA</h3>
    <div class="info-row">
      <span class="label">Nº de Inscripción:</span>
      <span class="value">{{ data?.id.slice(-4) | uppercase }}</span>
    </div>
    <div class="info-row">
      <span class="label">Disciplina:</span>
      <span class="value">{{ disciplineName }}</span>
    </div>

    <div class="info-row">
      <span class="label">Categoría:</span>
      <span class="value">{{
        data?.discipline?.gender === 'M' ? 'MASCULINO' : 'FEMENINO'
      }}</span>
    </div>

    <div class="info-row">
      <span class="label">Estado:</span>
      <div class="flex flex-col">
        <span class="value status" [class.pending]="data?.status === 'pending'">
          {{ data?.status === 'pending' ? 'PENDIENTE' : 'APROBADO' }}
        </span>
        <span *ngIf="data?.status === 'pending'" class="text-xs text-gray-500">
          {{
            'por favor, dirigirse a la oficina de deportes para completar el proceso de inscripción'
          }}
        </span>
      </div>
    </div>
  </div>

  <div class="footer">
    <div class="signature-line">
      <div class="line"></div>
      <span>Firma del Participante</span>
    </div>

    <div class="signature-line">
      <div class="line"></div>
      <span>Firma del Representante CDI</span>
    </div>
  </div>

  <div
    class="absolute bottom-0 left-1/2 transform -translate-x-1/2 opacity-25 w-full text-center flex justify-center">
    <p class="flex">
      <span class="mt-2">Powered by</span>
      <span class=""
        ><img src="/assets/img/clausaas.svg" width="100" class="mt-2" srcset=""
      /></span>
    </p>
  </div>
</div>
