import { Injectable } from '@angular/core';
import { Storage, ref, uploadBytesResumable, getDownloadURL } from '@angular/fire/storage';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private storage: Storage) { }

  uploadFile(file: File, path: string): Observable<string> {
    const storageRef = ref(this.storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Observable(subscriber => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          subscriber.error(error);
        },
        () => {
          from(getDownloadURL(uploadTask.snapshot.ref)).subscribe(
            downloadURL => {
              subscriber.next(downloadURL);
              subscriber.complete();
            },
            error => subscriber.error(error)
          );
        }
      );
    });
  }

  uploadImage(file: File, folder: string = 'ads'): Observable<string> {
    const timestamp = new Date().getTime();
    const fileName = `${folder}/${timestamp}_${file.name}`;
    return this.uploadFile(file, fileName);
  }
} 