import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Album, GalleryService } from 'src/app/@core/services/gallery.service';
import { AlbumContentComponent } from '../album-content/album-content.component';
import { AlbumFormComponent } from '../album-form/album-form.component';
@Component({
  selector: 'app-album-card',
  templateUrl: './album-card.component.html',
  styleUrls: ['./album-card.component.css'],
})
export class AlbumCardComponent implements OnInit {
  @Input() album: Album;
  constructor(
    private nzDrawerService: NzDrawerService,
    private modalService: NzModalService,
    private galleryService: GalleryService
  ) {}

  ngOnInit() {}

  onViewAlbum(album: Album): void {
    this.nzDrawerService.create<AlbumContentComponent, { data: Album }>({
      nzTitle: album.title,
      nzContent: AlbumContentComponent,
      nzWidth: '100%',
      nzContentParams: {
        data: album,
      },
    });
  }

  onEditAlbum(album: Album): void {
    this.modalService.create({
      nzTitle: 'Editar álbum',
      nzContent: AlbumFormComponent,
      nzFooter: null,
      nzData: {
        album: album,
        isEditMode: true,
      },
    });
  }

  onDeleteAlbum(album: Album): void {
    this.modalService.confirm({
      nzTitle: 'Eliminar álbum',
      nzContent: AlbumFormComponent,
      nzOkText: 'Eliminar',
      nzCancelText: 'Cancelar',
      nzOnOk: () => {
        this.galleryService.deleteAlbum(album.id).subscribe();
      },
    });
  }
}
