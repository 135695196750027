import { Component, Inject } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Album, Photo } from 'src/app/@core/services/gallery.service';
import { ImageDetailModalComponent } from '../image-detail-modal/image-detail-modal.component';

@Component({
  selector: 'app-top-ten-modal',
  templateUrl: './top-ten-modal.component.html',
  styleUrls: ['./top-ten-modal.component.css'],
})
export class TopTenModalComponent {
  constructor(
    @Inject(NZ_MODAL_DATA) public data: { photos: Photo[]; albumData: Album },
    private modal: NzModalRef,
    private modalService: NzModalService
  ) {}

  showPhotoDetail(photo: Photo): void {
    this.modalService.create({
      nzTitle: 'Detalle de la foto',
      nzContent: ImageDetailModalComponent,
      nzWidth: '80%',
      nzFooter: null,
      nzData: {
        photo: photo,
        albumData: this.data.albumData,
      },
    });
  }
}
