import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  finalize,
  takeUntil,
} from 'rxjs';
import { GalleryService } from 'src/app/@core/services/gallery.service';
import { UploadService } from 'src/app/@core/services/upload.service';

interface Album {
  id?: string;
  title: string;
  description: string;
  coverImage: string;
  allowSubmit: boolean;
  allowVoting: boolean;
  categories?: string[];
  displayOwner?: boolean;
}

@Component({
  selector: 'app-album-form',
  templateUrl: './album-form.component.html',
  styleUrls: ['./album-form.component.css'],
})
export class AlbumFormComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput') fileInput!: ElementRef;
  @Input() album?: Album;

  albumForm: FormGroup;
  previewImage: string | null = null;
  isEditMode = false;

  // Suggested categories for the dropdown
  suggestedCategories: string[] = [];

  isLoading$ = new BehaviorSubject<boolean>(false);

  // For debouncing category input
  private categoryInput$ = new Subject<string>();
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private galleryService: GalleryService,
    private uploadService: UploadService,
    @Inject(NZ_MODAL_DATA) public data: { album: Album; isEditMode: boolean },
    public modalRef: NzModalRef
  ) {
    if (!!this.data.album) {
      this.album = this.data.album;
      this.isEditMode = this.data.isEditMode;
    }

    this.albumForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      coverImage: ['', Validators.required],
      allowSubmit: [false],
      allowVoting: [false],
      terms: [''],
      categories: [[]],
      displayOwner: [false],
    });
  }

  ngOnInit() {
    console.log(this.data);
    if (this.album || this.data?.album) {
      this.isEditMode = true;
      this.previewImage = this.album.coverImage;
      this.albumForm.patchValue(this.album || this.data.album);
    }

    this.albumForm.get('allowSubmit')?.valueChanges.subscribe(value => {
      if (value) {
        this.albumForm.get('terms')?.setValidators([Validators.required]);
      } else {
        this.albumForm.get('terms')?.clearValidators();
      }
    });

    // Setup debounce for category input
    this.categoryInput$
      .pipe(
        debounceTime(600), // Wait for 500ms of inactivity
        takeUntil(this.destroy$)
      )
      .subscribe(value => {
        if (
          value &&
          this.suggestedCategories.indexOf(value) === -1 &&
          value.length > 3
        ) {
          this.suggestedCategories = [...this.suggestedCategories, value];
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        this.previewImage = reader.result as string;
        this.albumForm.patchValue({
          coverImage: file,
        });
      };
      reader.readAsDataURL(file);
    }
  }

  removeImage(event: Event): void {
    event.stopPropagation();
    this.previewImage = null;
    this.albumForm.patchValue({
      coverImage: '',
    });
    this.fileInput.nativeElement.value = '';
  }

  // Add a new category that's not in the suggested list
  addCategory(input: string): void {
    const value = input.trim();
    if (value) {
      this.categoryInput$.next(value);
    }
  }

  onSubmit(): void {
    if (this.albumForm.valid) {
      this.isLoading$.next(true);
      const coverImage = this.albumForm.get('coverImage')?.value;

      if (coverImage instanceof File) {
        // Upload only if coverImage is a File
        this.uploadService
          .uploadImage(coverImage, 'albums')
          .subscribe(coverImageUrl => {
            const albumData = {
              ...this.albumForm.value,
              coverImage: coverImageUrl,
            };

            if (this.isEditMode) {
              console.log('Updating album...');
              this.galleryService
                .updateAlbum(this.album.id, albumData)
                .pipe(
                  finalize(() => {
                    this.isLoading$.next(false);
                    this.onCancel();
                  })
                )
                .subscribe();
            } else {
              console.log('Creating new album...');
              this.galleryService
                .createAlbum(albumData)
                .pipe(
                  finalize(() => {
                    this.isLoading$.next(false);
                    this.onCancel();
                  })
                )
                .subscribe();
            }
          });
      } else {
        // Use existing coverImage URL
        if (this.isEditMode) {
          console.log('Updating album...');
          this.galleryService
            .updateAlbum(this.album.id, this.albumForm.value)
            .pipe(
              finalize(() => {
                this.isLoading$.next(false);
                this.onCancel();
              })
            )
            .subscribe();
        } else {
          console.log('Creating new album...');
          this.galleryService
            .createAlbum(this.albumForm.value)
            .pipe(
              finalize(() => {
                this.isLoading$.next(false);
                this.onCancel();
              })
            )
            .subscribe();
        }
      }
    }
  }

  onCancel(): void {
    this.modalRef.close();
  }
}
