import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  CalendarOutline,
  ClockCircleOutline,
  DeleteFill,
  DownloadOutline,
  EditOutline,
  FileExcelOutline,
  FileImageOutline,
  FileSearchOutline,
  FullscreenOutline,
  HeartFill,
  HeartOutline,
  HomeOutline,
  IdcardOutline,
  InfoCircleOutline,
  MailOutline,
  MenuFoldOutline,
  MenuUnfoldOutline,
  MoreOutline,
  OrderedListOutline,
  SettingFill,
  SolutionOutline,
  TrophyFill,
  TrophyOutline,
  UserOutline,
} from '@ant-design/icons-angular/icons';

const icons = [
  SolutionOutline,
  MenuFoldOutline,
  FullscreenOutline,
  IdcardOutline,
  UserOutline,
  ClockCircleOutline,
  SettingFill,
  FileSearchOutline,
  MoreOutline,
  OrderedListOutline,
  FileExcelOutline,
  CalendarOutline,
  HomeOutline,
  InfoCircleOutline,
  MenuUnfoldOutline,
  TrophyFill,
  TrophyOutline,
  DownloadOutline,
  FileExcelOutline,
  FileImageOutline,
  DeleteFill,
  EditOutline,
  MailOutline,
  HeartFill,
  HeartOutline,
  TrophyFill,
  TrophyOutline,
  DownloadOutline,
  FileExcelOutline,
  FileImageOutline,
  DeleteFill,
  EditOutline,
  MailOutline,
  HeartFill,
  HeartOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {}
